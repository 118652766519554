import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  ngOnInit(): void {}

  subject: Subject<boolean>;

  constructor(public activeModal: NgbActiveModal) {}

  action(value: boolean) {
    this.activeModal.close(value);
    this.subject.next(value);
    this.subject.complete();
  }
}
