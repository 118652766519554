<div class="sb-nav-fixed">
  <app-header [showExpandedView]="showExpandedView"></app-header>

  <div id="layoutSidenav">
    <app-side-nav-container
      (sendCollapsedStatus)="checkCollapseStatus($event)"
    ></app-side-nav-container>
    <div
    class="edit-project"
  id="layoutSidenav_content"
  [ngClass]="{
    'shrinked-view': !showExpandedView,
    'expanded-view': showExpandedView
  }" 
>
  <!--Edit project tabs-->
  <ul ngbNav
    #nav="ngbNav"
    [ngClass]="{
      'shrink-margin': !showExpandedView,
      'expanded-margin': showExpandedView
    }"
    class="nav nav-tabs"
    [destroyOnHide]="true"
    [activeId]="'general'"
  >
    <li class="nav-item" [ngbNavItem]="'general'">
      <a ngbNavLink class="nav-link" (click)="setActiveTab('general')">
        <i class="placeholders">General</i>
      </a>
      <ng-template ngbNavContent>
        <div *ngIf="apiLoading" class="d-flex justify-content-center">
          <div class="loader-create" role="status">
            <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
          </div>
        </div>
        <app-general
          (activeId)="setActive($event)"
          [pro]="project"
          (updated)="valid($event)"
          *ngIf="!apiLoading"
        >
        </app-general>
      </ng-template>
    </li>

    <li class="nav-item" [ngbNavItem]="'configuration'">
      <a ngbNavLink class="nav-link" (click)="setActiveTab('configuration')">
        <i class="placeholders">Configuration</i>
      </a>
      <ng-template ngbNavContent>
        <div *ngIf="apiLoading" class="d-flex justify-content-center configuration_min">
          <div class="loader-create" role="status">
            <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
          </div>
        </div>
        <app-env-tabs
          (updated)="valid($event)"
          *ngIf="!apiLoading"
        ></app-env-tabs>
      </ng-template>
    </li>

    <li class="nav-item" [ngbNavItem]="'code-management'">
      <a ngbNavLink class="nav-link" (click)="setActiveTab('code-management')">
        <i class="placeholders">Code Management</i>
      </a>
      <ng-template ngbNavContent>
        <div *ngIf="apiLoading" class="d-flex justify-content-center">
          <div class="loader-create" role="status">
            <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
          </div>
        </div>
        <app-code-management
          [pro]="project"
          (updated)="valid($event)"
          *ngIf="!apiLoading"
        ></app-code-management>
      </ng-template>
    </li>

    <!-- <li [ngbNavItem]="'pipelines'">
      <a ngbNavLink (click)="setActiveTab('pipelines')">
        <i class="placeholders">Pipelines</i>
      </a>
      <ng-template ngbNavContent>
        <div *ngIf="apiLoading" class="d-flex justify-content-center">
          <div class="loader-create" role="status">
            <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
          </div>
        </div>
        <app-pipeline
          [pro]="project"
          (updated)="valid($event)"
          *ngIf="!apiLoading"
        ></app-pipeline>
      </ng-template>
    </li>

    <li [ngbNavItem]="'monitoring'">
      <a ngbNavLink (click)="setActiveTab('monitoring')">
        <i class="placeholders">Monitoring</i>
      </a>
      <ng-template ngbNavContent>
        <div *ngIf="apiLoading" class="d-flex justify-content-center">
          <div class="loader-create" role="status">
            <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
          </div>
        </div>
        <app-monitoring
          [pro]="project"
          (updated)="valid($event)"
          *ngIf="!apiLoading"
        ></app-monitoring>
      </ng-template>
    </li> -->
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>

  </div>
</div>
