import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { RequestPasswordComponent } from './auth/request-password/request-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AuthGuardServiceGuard } from './shared-services/auth-guard-service.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectComponent } from './project/project.component';
import { WikiComponent } from './wiki/wiki.component';
import { TemplateComponent } from './template/template.component';
import { UnsavedChangesGuard } from './shared-services/unsaved-changes.guard';
import { EnvironmentComponent } from './project/environment/environment.component';
import { ProjectConfigComponent } from './project/project-config/project-config.component';
import { VcsComponent } from './project/vcs/vcs.component';
import { CiCdComponent } from './project/ci-cd/ci-cd.component';
import { ProjectDiaComponent } from './project/project-dia/project-dia.component';
import { SummaryComponent } from './project/summary/summary.component';
import { RedirectionComponent } from './redirection/redirection.component';
import { EditProjectComponent } from './project/edit-project/edit-project.component';
import { CodeManagementComponent } from './project/edit-project/code-management/code-management.component';
import { EditConfigComponent } from './project/edit-project/edit-config/edit-config.component';
import { MonitoringComponent } from './project/edit-project/monitoring/monitoring.component';
import { PipelineComponent } from './project/edit-project/pipeline/pipeline.component';
import { Chart1Component } from './project/edit-project/monitoring/chart1/chart1.component';

import { ProjectDashboardComponent } from './dashboard/project-dashboard/project-dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: 'portal/dashboard', pathMatch: 'full' },
  {
    path: 'portal/dashboard',
    canActivate: [AuthGuardServiceGuard],
    component: DashboardComponent,
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'projects',
    canActivate: [AuthGuardServiceGuard],
    component: ProjectDashboardComponent,
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'projects/create',
    canActivate: [AuthGuardServiceGuard],
    component: ProjectComponent,
    loadChildren: () =>
      import('./project/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'projects/create/basic',
    canActivate: [AuthGuardServiceGuard],
    component: EnvironmentComponent,
    loadChildren: () =>
      import('./project/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'projects/create/artifacts',
    canActivate: [AuthGuardServiceGuard],
    component: ProjectDiaComponent,
    loadChildren: () =>
      import('./project/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'projects/create/:id/config',
    canActivate: [AuthGuardServiceGuard],
    component: ProjectConfigComponent,
    loadChildren: () =>
      import('./project/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'projects/create/:id/source',
    canActivate: [AuthGuardServiceGuard],
    component: VcsComponent,
    loadChildren: () =>
      import('./project/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'projects/create/:id/cicd',
    canActivate: [AuthGuardServiceGuard],
    component: CiCdComponent,
    loadChildren: () =>
      import('./project/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'projects/create/:id/summary',
    canActivate: [AuthGuardServiceGuard],
    component: SummaryComponent,
    loadChildren: () =>
      import('./project/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'portal/template',
    component: TemplateComponent,
    canActivate: [AuthGuardServiceGuard],
    canDeactivate: [UnsavedChangesGuard],
    loadChildren: () =>
      import('./template/template.module').then((m) => m.TemplateModule),
  },
  // {
  //   path: 'projects/edit/config',
  //   component: EditConfigComponent,
  // },
  {
    path: 'projects/edit/general',
    component: EditProjectComponent,
  },
  // {
  //   path: 'projects/edit/chart1',
  //   component: Chart1Component,
  // },
  // {
  //   path: 'projects/edit/code-management',
  //   component: CodeManagementComponent,
  // },
  // {
  //   path: 'projects/edit/monitoring',
  //   component: MonitoringComponent,
  // },
  // {
  //   path: 'projects/edit/pipeline',
  //   component: PipelineComponent,
  // },
  {
    path: 'portal/help-center',
    component: WikiComponent,
    canActivate: [AuthGuardServiceGuard],
    loadChildren: () => import('./wiki/wiki.module').then((m) => m.WikiModule),
  },
  // {
  //   path: 'portal/assessmentbot',
  //   component: AssessmentBotComponent,
  //   canActivate: [AuthGuardServiceGuard],
  // },
  // {
  //   path: 'portal/survey',
  //   component: SurveyComponent,
  //   canActivate: [AuthGuardServiceGuard],
  // },
  // {
  //   path: 'portal/assessmentreports',
  //   component: AssessmentReportsComponent,
  //   canActivate: [AuthGuardServiceGuard],
  // },
  {
    path: 'portal/redirection',
    component: RedirectionComponent,
    canActivate: [AuthGuardServiceGuard],
  },
  {
    path: 'auth',
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'request-password', component: RequestPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
    ],
  },

  { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
