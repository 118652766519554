import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ICollapseStatus } from './side-nav-container.utils';

@Component({
  selector: 'app-side-nav-container',
  templateUrl: './side-nav-container.component.html',
  styleUrls: ['./side-nav-container.component.scss'],
})
export class SideNavContainerComponent implements OnInit {
  isCollapsed = false;
  @Output() sendCollapsedStatus: EventEmitter<ICollapseStatus> =
    new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  toggleSideNav(): void {
    if (this.isCollapsed === true) {
      this.isCollapsed = false;
    } else {
      this.isCollapsed = true;
    }
    this.sendCollapsedStatus.emit({ isCollapsed: this.isCollapsed });
  }
}
