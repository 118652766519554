import { Component, OnInit } from '@angular/core';
import { ICollapseStatus } from 'src/app/portal/side-nav-container/side-nav-container.utils';
import { ProjectService } from '../project-services/project.service';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
})
export class EditProjectComponent implements OnInit {
  showExpandedView: boolean;
  apiLoading: boolean;
  project: any;
  activeString: string = 'general';
  updated: boolean;
  constructor(private proService: ProjectService) {}

  async ngOnInit(): Promise<void> {
    this.initialize();
  }
  valid(currentstate: boolean) {
    this.updated = currentstate;
    if (this.updated) {
      this.initialize();
    }
  }

  async initialize() {
    this.apiLoading = true;
    await this.proService
      .fetchPro(localStorage.getItem('editId'))
      .subscribe((data: any) => {
        this.project = data.project;
        this.apiLoading = false;
      });
  }
  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }

  setActive(id: any) {
    this.activeString = id;
  }

  setActiveTab(id: string) {
    this.activeString = id;
  }
}
