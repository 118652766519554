<div class="container-fluid">
  <div class="row no-gutter">
    <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
    <div class="col-md-8 col-lg-6">
      <div class="login d-flex align-items-center py-3">
        <div class="container">
          <div class="row">
            <div class="col-md-9 col-lg-8 mx-auto">
              <h3 class="login-heading">
                {{ "auth.register.heading" | translate }}
              </h3>
              <form #registerForm="ngForm" (ngSubmit)="registerSubmit()">
                <div class="form-label-group label-group">
                  <label class="bold_labels" for="inputFirstName">{{
                    "auth.register.fullName" | translate
                  }}</label>
                  <!-- pattern="^[A-Za-z ']+$"
                  pattern2="\s*\S+.*"
                (?!\s+) -->
                  <input
                    type="text"
                    oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
                    [(ngModel)]="fullName"
                    pattern="^[A-Za-z ']+$"
                    name="fullName"
                    #nameField="ngModel"
                    id="inputFirstName"
                    class="form-control placeholders"
                    [ngClass]="{
                      'is-invalid': nameField.dirty && nameField.invalid
                    }"
                    placeholder="{{
                      'common.message.enter_full_name' | translate
                    }}"
                    required
                    maxlength="50"
                    minlength="3"
                    autofocus
                  />

                  <div
                    *ngIf="nameField.dirty && nameField.invalid"
                    class="error-text"
                  >
                    <span *ngIf="nameField.errors.required">
                      {{
                        "common.validation_message.full_name_required"
                          | translate
                      }}
                    </span>
                    <span *ngIf="nameField.errors.pattern">
                      {{
                        "common.validation_message.fullname_incorrect"
                          | translate
                      }}
                    </span>
                    <span *ngIf="nameField.errors.minlength">
                      {{
                        "common.validation_message.name_minlength" | translate
                      }}
                    </span>
                  </div>
                </div>
                <!-- pattern="[a-z0-9._]+@[a-z0-9.-]+\.[a-z]{2,4}$" -->

                <div class="form-label-group label-group">
                  <label class="bold_labels" for="inputEmail">{{
                    "auth.loginPage.email" | translate
                  }}</label>
                  <input
                    type="email"
                    [(ngModel)]="email"
                    pattern="[a-z0-9._]+@(?:impetus.com|impetus.co.in)$"
                    #emailField="ngModel"
                    name="email"
                    class="form-control placeholders"
                    [ngClass]="{
                      'is-invalid': emailField.dirty && emailField.invalid
                    }"
                    placeholder="{{ 'common.message.enter_email' | translate }}"
                    id="inputEmail"
                    email
                    required
                    autofocus
                  />

                  <div
                    *ngIf="emailField.dirty && emailField.invalid"
                    class="error-text"
                  >
                    <span *ngIf="emailField.errors.required">
                      {{
                        "common.validation_message.email_required" | translate
                      }}
                    </span>
                    <span *ngIf="emailField.errors.pattern">
                      {{
                        "common.validation_message.email_incorrect" | translate
                      }}
                    </span>
                  </div>
                </div>

                <div class="form-label-group label-group">
                  <label class="bold_labels" for="inputPassword">{{
                    "auth.loginPage.password" | translate
                  }}</label>
                  <input
                    type="password"
                    [(ngModel)]="password"
                    pattern="^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$"
                    required
                    #passwordField="ngModel"
                    name="password"
                    class="form-control placeholders"
                    placeholder="Enter Password"
                    id="inputPassword"
                    maxlength="16"
                    minlength="6"
                    [ngClass]="{
                      'is-invalid': passwordField.dirty && passwordField.invalid
                    }"
                    placeholder="{{
                      'common.message.enter_password' | translate
                    }}"
                    required
                  />
                  <div
                    *ngIf="passwordField.dirty && passwordField.invalid"
                    class="error-text"
                  >
                    <span *ngIf="passwordField.errors.required">
                      {{
                        "common.validation_message.password_required"
                          | translate
                      }}
                    </span>
                    <span *ngIf="passwordField.errors.pattern">
                      {{
                        "common.validation_message.password_formate_error"
                          | translate
                      }}
                    </span>
                  </div>
                </div>
                <div class="form-label-group label-group">
                  <label class="bold_labels" for="inputConfirmPassword">{{
                    "auth.register.confirm_password" | translate
                  }}</label>
                  <input
                    type="password"
                    [(ngModel)]="confirmPassword"
                    #confirmPasswordField="ngModel"
                    (ngModelChange)="checkConfirmPassword($event)"
                    name="confirmPassword"
                    id="inputConfirmPassword"
                    class="form-control placeholders"
                    maxlength="16"
                    minlength="6"
                    [ngClass]="{
                      'is-invalid':
                        (confirmPasswordField.dirty &&
                          confirmPasswordField.invalid) ||
                        (confirmPasswordField.dirty &&
                          passwordField.value !== confirmPasswordField.value)
                    }"
                    placeholder="{{
                      'common.message.enter_confirm_password' | translate
                    }}"
                    required
                  />

                  <div
                    *ngIf="
                      confirmPasswordField.dirty && confirmPasswordField.invalid
                    "
                    class="error-text"
                  >
                    <span *ngIf="confirmPasswordField.errors.required">
                      {{
                        "common.validation_message.confirm_password_required"
                          | translate
                      }}
                    </span>
                  </div>
                  <div *ngIf="!confirmPasswordField.errors">
                    <span
                      *ngIf="
                        confirmPasswordField.dirty &&
                        passwordField.value !== confirmPasswordField.value
                      "
                      class="error-text"
                    >
                      {{ "Password not matching!" }}
                    </span>
                  </div>
                </div>

                <!-- <div *ngIf="showRegSubError" class="row">
                    <div class="col-md-12 text-center">
                        <div class="alert alert-danger">
                            {{regSubError}}
                        </div>
                    </div>
                </div> -->

                <div class="form-check">
                  <label class="form-check-label links">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      (change)="agreedWithTerms($event)"
                    />

                    {{ "common.message.agree_TC" | translate }}
                  </label>
                  <button
                    [disabled]="
                      !registerForm.form.valid ||
                      !agreeWithTerms ||
                      isRegistring
                    "
                    class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold login-size"
                    type="submit"
                  >
                    {{ "common.button.register" | translate }}
                    <span
                      *ngIf="submittingForm"
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                  <div
                    class="register-link custom-control custom-checkbox mb-4"
                  >
                    <a class="large" routerLink="/auth/login"
                      >{{ "common.message.login_link" | translate }}
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
