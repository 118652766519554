import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], obj: any): any[] {
    let search = obj.search?.trim().toLowerCase();
    let searchfilter = obj.searchfilter?.toLowerCase();
    let searchArr;
    let isArr = false;
    if (search.includes(' ') || search.includes(',')) {
      searchArr = search.replace(',', ' ').split(' ');
      isArr = true;
    }
    if (!isArr) {
      if (!searchfilter && !search) {
        return items;
      }
      if (searchfilter && search) {
        if (searchfilter.toLowerCase() == 'all') {
          return items.filter((it) => {
            return (
              it.label.toLowerCase().includes(search) ||
              it.platform.toLowerCase().includes(search) ||
              it.description.toLowerCase().includes(search) ||
              it.technology.toLowerCase().includes(search)
            );
          });
        } else if (searchfilter.toLowerCase() == 'multi-cloud') {
          let temp = ['aws', 'gcp', 'azure'];
          let item = items.filter((it) => {
            return !temp.includes(it.platform.toLowerCase());
          });
          return item.filter((it) => {
            return (
              it.label.toLowerCase().includes(search) ||
              it.platform.toLowerCase().includes(search) ||
              it.description.toLowerCase().includes(search) ||
              it.technology.toLowerCase().includes(search)
            );
          });
        } else {
          let item = items.filter((it) => {
            return it.platform.toLowerCase() == searchfilter.toLowerCase();
          });
          return item.filter((it) => {
            return (
              it.label.toLowerCase().includes(search) ||
              it.platform.toLowerCase().includes(search) ||
              it.description.toLowerCase().includes(search) ||
              it.technology.toLowerCase().includes(search)
            );
          });
        }
      }
      if (search || searchfilter) {
        if (searchfilter) {
          if (searchfilter.toLowerCase() == 'all') {
            return items;
          } else if (searchfilter.toLowerCase() == 'multi-cloud') {
            let temp = ['aws', 'gcp', 'azure'];
            return items.filter((it) => {
              return !temp.includes(it.platform.toLowerCase());
            });
          } else {
            return items.filter((it) => {
              return it.platform.toLowerCase() == searchfilter.toLowerCase();
            });
          }
        }
        if (search) {
          if (search == '') {
            return items;
          }
          search = search.toLowerCase();
          return items.filter((it) => {
            return (
              it.label.toLowerCase().includes(search) ||
              it.platform.toLowerCase().includes(search) ||
              it.description.toLowerCase().includes(search) ||
              it.technology.toLowerCase().includes(search)
            );
          });
        }
      }
      return items;
    } else {
      if (searchfilter) {
        if (searchfilter.toLowerCase() == 'all') {
          return items.filter((it) => {
            return searchArr.every((element) => {
              return (
                it.label.toLowerCase().includes(element) ||
                it.platform.toLowerCase().includes(element) ||
                it.description.toLowerCase().includes(element) ||
                it.technology.toLowerCase().includes(element)
              );
            });
          });
        } else if (searchfilter.toLowerCase() == 'multi-cloud') {
          let temp = ['aws', 'gcp', 'azure'];
          let item = items.filter((it) => {
            return !temp.includes(it.platform.toLowerCase());
          });
          return item.filter((it) => {
            return searchArr.every((element) => {
              return (
                it.label.toLowerCase().includes(element) ||
                it.platform.toLowerCase().includes(element) ||
                it.description.toLowerCase().includes(element) ||
                it.technology.toLowerCase().includes(element)
              );
            });
          });
        } else {
          let item = items.filter((it) => {
            return it.platform.toLowerCase() == searchfilter.toLowerCase();
          });
          return item.filter((it) => {
            return searchArr.every((element) => {
              return (
                it.label.toLowerCase().includes(element) ||
                it.platform.toLowerCase().includes(element) ||
                it.description.toLowerCase().includes(element) ||
                it.technology.toLowerCase().includes(element)
              );
            });
          });
        }
      } else
        return items.filter((it) => {
          return searchArr.every((element) => {
            return (
              it.label.toLowerCase().includes(element) ||
              it.platform.toLowerCase().includes(element) ||
              it.description.toLowerCase().includes(element) ||
              it.technology.toLowerCase().includes(element)
            );
          });
        });
    }
    return items;
  }
}
