import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-scratch-card',
  templateUrl: './scratch-card.component.html',
  styleUrls: ['./scratch-card.component.scss'],
})
export class ScratchCardComponent implements OnInit {
  loggedInUserName: string;
  constructor(private router: Router, public cookieService: CookieService) {}

  ngOnInit(): void {}
}
