import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICollapseStatus } from '../../portal/side-nav-container/side-nav-container.utils';
import { ProjectDataService } from '../project-services/project-data.service';
import { ProjectService } from '../project-services/project.service';
import { AnalyticsService } from '../../shared-services/analytics.service';

@Component({
  selector: 'app-ci-cd',
  templateUrl: './ci-cd.component.html',
  styleUrls: ['./ci-cd.component.scss'],
})
export class CiCdComponent implements OnInit {
  showExpandedView: boolean;
  active: string = 'general';
  proid: string;

  constructor(
    private proService: ProjectService,
    private proData: ProjectDataService,
    private router: Router,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.proid = localStorage.getItem('proId');
  }

  github() {
    this.proService
      .updatecicd(
        JSON.parse(localStorage.getItem('loggedInUser')).id,
        localStorage.getItem('proId'),
        JSON.parse(localStorage.getItem('projectComponent')),
        'GITHUB_ACTION'
      )
      .subscribe((data) => {
        this.router.navigate([`/projects/create/${this.proid}/config`]);
      });
  }
  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }

  next() {
    this.router.navigate([`/projects/create/${this.proid}/config`]);
  }

  show(name) {
    this.active = name;
  }
}
