<div class="card-container">
  <form name="form" (ngSubmit)="onSubmit(ddlCategory)" #form="ngForm">
    <div class="form-group">
      <label for="DDCategory">Select Components</label>
      <!-- DropDownList -->
      <select
        class="custom-select"
        name="DDCategory"
        [(ngModel)]="ddlCategory"
        #DDCategory="ngModel"
        [disabled]="form.submitted"
        required
      >
        <option value="" class="text-primary font-weight-bolder">
          --- Select Component ---
        </option>
        <option *ngFor="let Category of Categories" [value]="Category.id">
          {{ Category.label }}
        </option>
      </select>

      <!-- Required Validation Message -->
      <div *ngIf="form.dirty && DDCategory.invalid" class="text-danger">
        <h6 *ngIf="DDCategory.errors.required">Please Select Component</h6>
      </div>
    </div>
    <button
      type="submit"
      [disabled]="form.invalid || form.submitted"
      class="btn btn-primary btn-lg submitBtn"
    >
      <!-- <span class="glyphicon glyphicon-ok my-style"> </span> -->
      <i class="fa fa-check my-style"></i>
    </button>
  </form>
</div>
