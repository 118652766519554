import { Injectable } from '@angular/core';
import { NotifierToastService } from '../shared-services/notifier-toast.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../shared-services/user/user.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class TemplateInfoService {
  errorMessage: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private notifierToast: NotifierToastService
  ) {}

  temp: any = {};
  comp: any;
  tempName: string;
  slug = '';
  desc: string;
  logo: string;
  //items=[];
  arch: string;
  deploy: string;
  addTempInfo(
    tempName: string,
    slug: string,
    Description: string,
    Logo: string,
    technology: string,
    platform: string
  ): Observable<any> {
    let temp = {
      name: tempName,
      slug: slug,
      description: Description,
      logo: Logo,
      userId: this.userService.loggedInUser.id,
      technology: technology,
      platform: platform,
    };
    return this.http.put(environment.TEMPLATE_URL, temp);

    //  this.tempName=tempName;
    // this.slug=slug;
    // this.desc=Description;
    // this.logo=Logo;
    //this.tempMap.push(cValue);
    // this.tempMap.push('Slug',slug);
    // this.tempMap.push('Description',Description);
    // this.tempMap.push('LogoURL',Logo);
  }

  addDiagrams(architecture: string, deployment: string) {
    this.arch = architecture;
    this.deploy = deployment;

    //  this.data[""]=cVal;
    // Object.assign(this.data,cVal);
    // console.log(cValue);
    // console.log("Data for API put request "+ this.data);
  }
  addComponents(data: any) {
    return this.http.put(environment.NEW_COMPONENT_URL, data);
  }

  createBulkComponentRule(data: any) {
    return this.http.put(environment.BULK_CREATE_COMPONENT_RULE, data);
  }

  // Service function to call update template API
  updateTemplate(data: {
    id: string;
    componentMap?: string;
    currentState?: string;
    architectureDiagram?: string;
    deploymentDiagram?: string;
  }) {
    return this.http.put(environment.UPDATE_TEMPLATE, data);
  }

  showSuccessNotification(data: string) {
    this.notifierToast.showNotification('success', data);
  }

  showErrorNotification(data: string) {
    this.notifierToast.showNotification('error', data);
  }
  tempObject() {
    let cVal = {
      name: this.tempName,
      // slug:this.slug,
      // Description:this.desc,
      logo: this.logo,
      componentMap: this.temp,
      architectureDiagram: this.arch,
      deploymentDiagram: this.deploy,
      userId: this.userService.loggedInUser.id,
    };
    this.http.put(environment.CREATE_TEMPLATE, cVal).subscribe(
      (data: any) => {
        this.comp = data;
        this.showSuccessNotification('Template Created Successfully');
        this.router.navigate(['/portal/dashboard']);
      },
      (err: Error) => {
        this.showErrorNotification(err.message);
      }
    );
  }
  getComponents() {
    return this.temp;
  }
}
