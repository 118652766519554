import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Amplify } from '@aws-amplify/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { RequestPasswordComponent } from './auth/request-password/request-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { SharedModule } from './shared-module/shared-module/shared-module.module';
import { ConfirmationDialogComponent } from './shared-component/confirmation-dialog/confirmation-dialog.component';
import { UnsavedChangesGuard } from './shared-services/unsaved-changes.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared-services/auth/auth-interceptor';
import { AuthService } from './shared-services/auth/auth.service';
import { RedirectionComponent } from './redirection/redirection.component';
import { CommonModule } from '@angular/common';

Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-1:782351ab-d57e-4fbe-b3fe-cb43718f603a',
    region: 'us-east-1',
  },
  Interactions: {
    bots: {
      Tipster: {
        name: 'Tipster',
        alias: 'Tipsterdev',
        region: 'us-east-1',
        userId: '12345',
      },
    },
  },
});

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    ConfirmationDialogComponent,
    RedirectionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    SharedModule.forRoot(),
  ],
  providers: [
    UnsavedChangesGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => {
        return () => authService.setCognitoCredentials();
      },
      deps: [AuthService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  //entryComponents: [ConfirmationDialogComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
