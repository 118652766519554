import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ICollapseStatus } from '../../portal/side-nav-container/side-nav-container.utils';
import { AnalyticsService } from '../../shared-services/analytics.service';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-vcs',
  templateUrl: './vcs.component.html',
  styleUrls: ['./vcs.component.scss'],
})
export class VcsComponent implements OnInit {
  showExpandedView: boolean;
  github: boolean = true;
  active: string = 'general';
  proid: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    public cookieService: CookieService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    if (!localStorage.getItem('proId')) {
      localStorage.setItem('proId', this.cookieService.get('projectId'));
      this.proid = localStorage.getItem('proId');
    }
    this.proid = localStorage.getItem('proId');
  }

  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }

  async githubFunc() {
    console.log('git');
    // let queryParams = new HttpParams();
    // queryParams = queryParams.append("scope","repo");
    // queryParams = queryParams.append("client_id","Iv1.4466af9d3907c017");
    // queryParams = queryParams.append("redirect_uri","https://predev.kloudjet.com/portal/redirection");
    // queryParams = queryParams.append("state","kjtest12345");

    //   let headers= new HttpHeaders({
    //     'Access-Control-Allow-Origin': '*',
    //     'Accept': 'text/html'
    //   })
    // this.http
    //   .get('https://n2ye8qds27.us-east-1.awsapprunner.com/https://github.com/login/oauth/authorize',{headers:headers,params:queryParams})
    //   .subscribe((data) =>
    //     console.log(data);
    //   });
    window.open(environment.REDIRECT_LINK, '_self');
  }

  show(name) {
    this.active = name;
  }

  next() {
    this.router.navigate([`/projects/create/${this.proid}/config`]);
  }
}
