<div class="accordion" id="accordionExample">
  <div *ngFor="let tab of tabs; let index = index" [ngModel]="tab.name" name="Component{{ index + 1 }}" id="cid{{ index + 1 }}" ngDefaultControl class="accordion-item">
    <h2 class="accordion-header" id="headingOne{{ index + 1 }}">
      <!-- <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne{{ index + 1 }}" aria-expanded="true" aria-controls="collapseOne{{ index + 1 }}">
        Component {{ index + 1 }}
      </button> -->
      <div id="'collapseOne' + (index + 1)" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingOne' + (index + 1)" data-bs-parent="#accordionExample">
        Component {{ index + 1 }}
        </div>
    </h2>

    <div id="collapseOne{{ index + 1 }}" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingOne' + (index + 1)" data-bs-parent="#accordionExample">
      <div class="accordion-body accordionHeight">
        <nav ngbNav [destroyOnHide]="false">
          <ul>
            <li [ngbNavItem]="1">
              <a ngbNavLink>New Component</a>
              <ng-template ngbNavContent>
                <form
                #componentPost="ngForm"
                (ngSubmit)="
                  addNewComponent(
                    componentPost,
                    Componentname,
                    description,
                    branch,
                    folderName,
                    Platform,
                    uploadedFileUrl
                  )
                "
                novalidate
              >
                <div class="container-fluid">
                  <div class="form-group col-lg-12">
                    <div class="row mb-3">
                      <div class="col">
                        <label class="mr-sm-4"
                          >{{ "common.component_name" | translate }}
                          <span class="error-text">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          [disabled]="componentPost.submitted"
                          class="form-control mr-sm-3 placeholders"
                          placeholder="{{
                            'common.component_name' | translate
                          }}"
                          [ngClass]="{
                            'is-invalid':
                              (comp.dirty && comp.invalid) ||
                              (componentPost.submitted && comp.invalid)
                          }"
                          [(ngModel)]="Componentname"
                          name="comp"
                          #comp="ngModel"
                        />
                        <div
                          *ngIf="comp.dirty && comp.invalid"
                          class="error-text"
                        >
                          <span *ngIf="comp.errors.required">{{
                            "common.validation_message.component_required"
                              | translate
                          }}</span>
                        </div>
                      </div>
                      <div class="col">
                        <label for="desc" class="mr-sm-2"
                          >{{ "common.description" | translate }}
                          <span class="error-text">*</span>
                        </label>
                        <textarea
                          id="desc"
                          required
                          [disabled]="componentPost.submitted"
                          class="form-control mr-sm-3 placeholders"
                          [ngClass]="{
                            'is-invalid':
                              (desc.dirty && desc.invalid) ||
                              (componentPost.submitted && desc.invalid)
                          }"
                          [(ngModel)]="description"
                          #desc="ngModel"
                          name="desc"
                          placeholder="{{ 'common.description' | translate }}"
                          rows="3"
                          cols="40"
                        ></textarea>
                        <div
                          *ngIf="desc.dirty && desc.invalid"
                          class="error-text"
                        >
                          <span *ngIf="desc.errors.required">{{
                            "common.validation_message.description_required"
                              | translate
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <label class="mr-sm-4"
                          >{{ "common.branch" | translate }}
                          <span class="error-text">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          [disabled]="componentPost.submitted"
                          class="form-control mr-sm-3 placeholders"
                          placeholder="{{ 'common.branch_name' | translate }}"
                          [ngClass]="{
                            'is-invalid':
                              (br.dirty && br.invalid) ||
                              (componentPost.submitted && br.invalid)
                          }"
                          [(ngModel)]="branch"
                          #br="ngModel"
                          name="br"
                        />
                        <div *ngIf="br.dirty && br.invalid" class="error-text">
                          <span *ngIf="br.errors.required">{{
                            "common.validation_message.branch_required"
                              | translate
                          }}</span>
                        </div>
                      </div>
                      <div class="col">
                        <label class="mr-sm-4"
                          >{{ "common.folder_name" | translate }}
                          <span class="error-text">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          [disabled]="componentPost.submitted"
                          class="form-control mr-sm-3 placeholders"
                          placeholder="{{ 'common.folder_name' | translate }}"
                          [(ngModel)]="folderName"
                          #folder="ngModel"
                          name="folder"
                          [ngClass]="{
                            'is-invalid':
                              (folder.dirty && folder.invalid) ||
                              (componentPost.submitted && folder.invalid)
                          }"
                        />
                        <div
                          *ngIf="folder.dirty && folder.invalid"
                          class="error-text"
                        >
                          <span *ngIf="folder.errors.required">{{
                            "common.validation_message.folder_required"
                              | translate
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row mb-3">
                      <div class="col">
                        <label for="technology " class="mr-sm-2"
                          >{{ "common.technology_stack" | translate }}
                          <span class="error-text">*</span></label
                        >
                        <input
                          class="form-control ml-10 placeholders"
                          type="text"
                          id="technologySet"
                          [disabled]="componentPost.submitted"
                          placeholder="Java, Angular, Python..."
                          name="technologyRef"
                          [(ngModel)]="technology"
                          #technologyRef="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              (technologyRef.dirty && technologyRef.invalid) ||
                              (componentPost.submitted && technologyRef.invalid)
                          }"
                          required
                        />
                        <div
                          *ngIf="technologyRef.dirty && technologyRef.invalid"
                          class="error-text"
                        >
                          <span *ngIf="technologyRef.errors.required">{{
                            "common.validation_message.technology_stack_required"
                              | translate
                          }}</span>
                        </div>
                        <!-- (keydown.enter)="onSkillsSetKeydown(); (false)" -->

                        <!-- <app-chip (onpick)="addTechnology($event)"></app-chip> -->
                      </div>
                      <div class="col code">
                        <label class="mr-sm-2"
                          >{{ "common.code" | translate }}
                          <sup class="supStyle"
                            >( {{ "common.zip_file" | translate }} )</sup
                          >
                        </label>
                        <!-- <span class="error-text">*</span> -->
                        <div class="inputFile">
                          <label for="code" class="zeroMargin">
                            <div class="customInputFile">Choose File</div>
                          </label>
                          <input
                            type="file"
                            [disabled]="componentPost.submitted"
                            accept=".zip"
                            id="code"
                            class="displayNone placeholders"
                            (change)="uploadFiles($event)"
                          />
                          <div>
                            <span *ngIf="isUploaded">{{ inputFileName }}</span>
                            <span *ngIf="!isUploaded && !isUploading"
                              >No File Chosen</span
                            >
                            &nbsp;
                            <span
                              *ngIf="isUploading"
                              class="spinner-border-sm text-primary"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </div>
                        </div>
                        <div *ngIf="isMismatch" class="error-text">
                          <span>
                            {{ "common.zip_format_error" | translate }}
                          </span>
                        </div>
                        <div *ngIf="isTooBig" class="error-text">
                          <span>
                            {{ "common.zip_file_size_exceed" | translate }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <label class="mr-sm-4"
                          >{{ "common.platform" | translate }}
                          <span class="error-text">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="AWS, Azure, GCP"
                          required
                          class="form-control mr-sm-3 placeholders"
                          [disabled]="componentPost.submitted"
                          [(ngModel)]="Platform"
                          #plat="ngModel"
                          name="plat"
                          [ngClass]="{
                            'is-invalid':
                              (plat.dirty && plat.invalid) ||
                              (componentPost.submitted && plat.invalid)
                          }"
                        />
                        <div
                          *ngIf="plat.dirty && plat.invalid"
                          class="error-text"
                        >
                          <span *ngIf="plat.errors.required">{{
                            "common.validation_message.platform_required"
                              | translate
                          }}</span>
                        </div>
                      </div>
                      <div class="col mr-sm-4"></div>
                    </div>
                    <div class="row mb-2">
                      <div class="col">
                        <button
                          type="submit"
                          class="btn btn-primary btn-lg submitBtn"
                          [disabled]="
                            newSelect ||
                            componentPost.invalid ||
                            componentPost.submitted
                          "
                        >
                          <i class="fa fa-check okColor"></i>
                        </button>

                        <button
                          type="button"
                          class="btn btn-default btn-lg deleteTier"
                          (click)="deleteTier(this.cid)"
                        >
                          <i class="fa fa-trash-o"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
               </form>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" [disabled]="newSelect">
              <a ngbNavLink>Existing Component</a>
              <ng-template ngbNavContent>
                <br />
                <div class="container-fluid">
                  <app-template-list (childEvent)="receiveHandler($event)"></app-template-list>
                </div>
              </ng-template>
            </li>
          </ul>
        </nav>
        <div class="row mb-2">
          <div class="col">
            <button type="submit" class="btn btn-primary btn-lg submitBtn" [disabled]="newSelect || componentPost.invalid || componentPost.submitted">
              <i class="fa fa-check okColor"></i>
            </button>
            <button type="button" class="btn btn-default btn-lg deleteTier" (click)="deleteTier(cid)">
              <i class="fa fa-trash-o"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div (click)="addTier()" class="btn btn-link addTier">
  <i class="icon-color fa fa-plus-circle"></i>
</div>