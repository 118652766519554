<div class="bs-stepper-header">
  <!-- <div class="step" data-target="#test-l-1" routerLink="/projects/create"> -->
  <!-- <button class="step-trigger step_trigger" routerLink="/projects/create"> -->
  <div class="step" data-target="#test-l-1">
    <button class="step-trigger step_trigger">
      <span
        class="bs-stepper-circle"
        [ngClass]="active == 'create' ? 'active-color-circle' : ''"
        >1</span
      >
      <span
        class="bs-stepper-label"
        [ngClass]="active == 'create' ? 'active-color' : ''"
        >{{ "common.template" | translate }}</span
      >
    </button>
  </div>
  <div class="line"></div>
  <!-- <div class="step" data-target="#test-l-2" routerLink="/projects/create/basic"> -->
  <!-- <button
      class="step-trigger step_trigger"
      routerLink="/projects/create/basic"
    > -->
  <div class="step" data-target="#test-l-2">
    <button class="step-trigger step_trigger">
      <span
        class="bs-stepper-circle"
        [ngClass]="active == 'basic' ? 'active-color-circle' : ''"
        >2</span
      >
      <span
        class="bs-stepper-label"
        [ngClass]="active == 'basic' ? 'active-color' : ''"
        >{{ "common.project_name" | translate }}</span
      >
    </button>
  </div>

  <div class="line"></div>
  <!-- <div
    class="step"
    data-target="#test-l-3"
    routerLink="/projects/create/{{id}}/artifacts"
  >
    <button
      routerLink="/projects/create/{{id}}/artifacts"
      class="step-trigger step_trigger"
    > -->
  <div class="step" data-target="#test-l-3">
    <button class="step-trigger step_trigger">
      <span
        class="bs-stepper-circle"
        [ngClass]="active == 'artifacts' ? 'active-color-circle' : ''"
        >3</span
      >
      <span
        class="bs-stepper-label"
        [ngClass]="active == 'artifacts' ? 'active-color' : ''"
        >{{ "common.artifacts" | translate }}</span
      >
    </button>
  </div>

  <div class="line"></div>
  <!-- <div
    class="step"
    data-target="#test-l-4"
    routerLink="/projects/create/{{id}}/source"
  >
    <button
      class="step-trigger step_trigger"
      routerLink="/projects/create/{{id}}/source"
    > -->
  <div class="step" data-target="#test-l-4">
    <button class="step-trigger step_trigger">
      <span
        class="bs-stepper-circle"
        [ngClass]="active == 'source' ? 'active-color-circle' : ''"
        >4</span
      >
      <span
        class="bs-stepper-label"
        [ngClass]="active == 'source' ? 'active-color' : ''"
      >
        {{ "common.vcs" | translate }}
      </span>
    </button>
  </div>

  <div class="line"></div>
  <!-- <div class="step" data-target="#test-l-5" routerLink="/projects/create/{{id}}/cicd">
    <button
      class="step-trigger step_trigger"
      routerLink="/projects/create/{{id}}/cicd"
    > -->
  <div class="step" data-target="#test-l-5">
    <button class="step-trigger step_trigger">
      <span
        class="bs-stepper-circle"
        [ngClass]="active == 'cicd' ? 'active-color-circle' : ''"
        >5</span
      >
      <span
        class="bs-stepper-label"
        [ngClass]="active == 'cicd' ? 'active-color' : ''"
      >
        {{ "common.ci_cd" | translate }}
      </span>
    </button>
  </div>

  <div class="line"></div>
  <!-- <div
    class="step"
    data-target="#test-l-6"
    routerLink="/projects/create/{{id}}/config"
  >
    <button
      class="step-trigger step_trigger"
      routerLink="/projects/create/{{id}}/config"
    > -->
  <div class="step" data-target="#test-l-6">
    <button class="step-trigger step_trigger">
      <span
        class="bs-stepper-circle"
        [ngClass]="active == 'config' ? 'active-color-circle' : ''"
        >6</span
      >
      <span
        class="bs-stepper-label"
        [ngClass]="active == 'config' ? 'active-color' : ''"
        >{{ "common.project_config" | translate }}</span
      >
    </button>
  </div>

  <div class="line"></div>
  <!-- <div
    class="step"
    data-target="#test-l-7"
    routerLink="/projects/create/{{id}}/summary"
  >
    <button
      class="step-trigger step_trigger"
      routerLink="/projects/create/{{id}}/summary"
    > -->
  <div class="step" data-target="#test-l-7">
    <button class="step-trigger step_trigger">
      <span
        class="bs-stepper-circle"
        [ngClass]="active == 'summary' ? 'active-color-circle' : ''"
        >7</span
      >
      <span
        class="bs-stepper-label"
        [ngClass]="active == 'summary' ? 'active-color' : ''"
      >
        {{ "common.summary" | translate }}
      </span>
    </button>
  </div>
</div>
