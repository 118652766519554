<tag-input
  class="placeholders"
  [(ngModel)]="outboundItem"
  [identifyBy]="'id'"
  [displayBy]="'label'"
  (onAdd)="onSelected($event)"
  [removable]="false"
>
  <tag-input-dropdown
    [appendToBody]="true"
    [identifyBy]="'id'"
    [showDropdownIfEmpty]="true"
    [autocompleteItems]="tagItems"
    [displayBy]="'label'"
  >
  </tag-input-dropdown>
</tag-input>
