<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Unsaved Changes</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click'); action(false)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      You have unsaved changes. <br />
      Are you sure, you still want to leave?
    </p>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      data-dismiss="modal"
      (click)="action(false)"
    >
      Stay
    </button>
    <button type="button" class="btn btn-leave" (click)="action(true)">
      Leave
    </button>
  </div>
</div>
