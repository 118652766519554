import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import {
  CognitoCallback,
  CognitoUtil,
  LoggedInCallback,
} from './cognito.service';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as STS from 'aws-sdk/clients/sts';
import { Observable, Observer } from 'rxjs';
import { AuthService } from '../../shared-services/auth/auth.service';
import { CookieService } from 'ngx-cookie';
@Injectable({
  providedIn: 'root',
})
export class UserLoginService {
  public loginType: string;

  constructor(
    private cognitoUtil: CognitoUtil,
    private awsAuthService: AuthService,
    private cookieService: CookieService
  ) {
    this.loginType = 'COGNITO';
  }

  authenticate(username: string, password: string): Observable<any> {
    return new Observable((observer) => {
      // console.log('UserLoginService: starting the authentication');
      let authenticationData = {
        Username: username,
        Password: password,
      };
      let authenticationDetails = new AuthenticationDetails(authenticationData);

      let userData = {
        Username: username,
        Pool: this.cognitoUtil.getUserPool(),
      };

      // console.log('UserLoginService: Params set...Authenticating the user');
      let cognitoUser = new CognitoUser(userData);

      cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
      // console.log('UserLoginService: config is ' + AWS.config);
      cognitoUser.authenticateUser(authenticationDetails, {
        newPasswordRequired: (userAttributes, requiredAttributes) =>
          observer.error(`User needs to set password.`),
        onSuccess: (result: any) => {
          this.onLoginSuccess(result, observer);
        },
        onFailure: (err) => {
          observer.error(err);
        },
      });
    });
  }

  onLoginSuccess = (session: CognitoUserSession, observer: Observer<any>) => {
    AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(
      session.getIdToken().getJwtToken()
    );
    this.cookieService.put('idToken', session.getIdToken().getJwtToken(), {
      domain: environment.COOKIE_DOMAIN,
    });
    this.cookieService.put(
      'accessToken',
      session.getAccessToken().getJwtToken(),
      {
        domain: environment.COOKIE_DOMAIN,
      }
    );
    this.cookieService.put(
      'refreshToken',
      session.getRefreshToken().getToken(),
      {
        domain: environment.COOKIE_DOMAIN,
      }
    );
    this.awsAuthService
      .setCognitoCredentials(session.getIdToken().getJwtToken())
      .then((credentials) => {
        credentials.username = session.getIdToken().payload['cognito:username'];
        observer.next(credentials);
        observer.complete();
      })
      .catch((err) => {
        observer.error(err);
        observer.complete();
      });
  };

  forgotPassword(username: string): Observable<any> {
    return new Observable((observer) => {
      let userData = {
        Username: username,
        Pool: this.cognitoUtil.getUserPool(),
      };

      let cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          observer.next(result);
          observer.complete();
        },
        onFailure: function (err) {
          observer.error(err);
          observer.complete();
        },
      });
    });
  }

  confirmNewPassword(
    email: string,
    verificationCode: string,
    newPassword: string
  ) {
    return new Observable((observer) => {
      const userData = {
        Username: email,
        Pool: this.cognitoUtil.getUserPool(),
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          return observer.next();
        },
        onFailure: (err) => {
          return observer.error(err);
        },
      });
    });
  }
}
