<div class="container-fluid">
  <div class="row no-gutter">
    <div class="loginDiv d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
    <div class="loginDiv col-md-8 col-lg-6">
      <div class="login d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-md-9 col-lg-8 mx-auto">
              <h3 class="login-heading">
                {{ "auth.loginPage.heading" | translate }}
              </h3>
              <form #loginForm="ngForm" (ngSubmit)="loginSubmit()">
                <div class="form-label-group label-group">
                  <label class="bold_labels" for="inputEmail">{{
                    "auth.loginPage.email" | translate
                  }}</label>
                  <input
                    type="text"
                    [(ngModel)]="email"
                    pattern="^\S*[@]\S*[.]\S*$"
                    required
                    #emailField="ngModel"
                    name="email"
                    id="inputEmail"
                    class="form-control placeholders"
                    [ngClass]="{
                      'is-invalid': emailField.dirty && emailField.invalid
                    }"
                    placeholder="{{ 'common.message.enter_email' | translate }}"
                  />

                  <div
                    *ngIf="emailField.invalid && emailField.dirty"
                    class="error-text"
                  >
                    <div *ngIf="emailField.errors.required">
                      {{
                        "common.validation_message.email_required" | translate
                      }}
                    </div>
                    <div *ngIf="emailField.errors.pattern">
                      {{
                        "common.validation_message.email_incorrect" | translate
                      }}
                    </div>
                  </div>
                </div>

                <div class="form-label-group label-group">
                  <label class="bold_labels" for="inputPassword">{{
                    "auth.loginPage.password" | translate
                  }}</label>
                  <input
                    type="password"
                    [(ngModel)]="password"
                    name="password"
                    #passwordField="ngModel"
                    id="inputPassword"
                    class="form-control placeholders"
                    [ngClass]="{
                      'is-invalid': passwordField.dirty && passwordField.invalid
                    }"
                    placeholder="{{
                      'common.message.enter_password' | translate
                    }}"
                    required
                  />
                  <div
                    *ngIf="passwordField.invalid && passwordField.dirty"
                    class="error-text"
                  >
                    <div *ngIf="passwordField.errors.required">
                      {{
                        "common.validation_message.password_required"
                          | translate
                      }}
                    </div>
                  </div>
                </div>
                <div class="link-margin">
                  <a class="small links" routerLink="/auth/request-password">{{
                    "auth.loginPage.forgot_password" | translate
                  }}</a>
                </div>
                <!-- <div *ngIf="showLoginSubError" class="row login-submit-error">
                    <div class="col-md-12 text-center">
                        <div class="alert alert-danger">
                            {{loginSubError}}
                        </div>
                    </div>
                </div> -->
                <button
                  [disabled]="!loginForm.form.valid || isLoggingIn"
                  class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold login-size"
                  type="submit"
                >
                  {{ "common.button.signIn" | translate }}
                  <span
                    *ngIf="submittingForm"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
                <div class="text-center link-margin">
                  <a class="small links" routerLink="/auth/register">{{
                    "common.message.singUp" | translate
                  }}</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
