import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICollapseStatus } from 'src/app/portal/side-nav-container/side-nav-container.utils';
import { ProjectService } from '../project-services/project.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotifierToastService } from 'src/app/shared-services/notifier-toast.service';
import { analyticsConst } from '../../shared-services/analytics-constants';
import { AnalyticsService } from '../../shared-services/analytics.service';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  selectedtemp: any;
  selectedenvs: any;
  showExpandedView: boolean;
  selectedArchDiagram: string;
  deploymentDiagram: string;
  projectName: string;
  selectedTemplateLabel: string;
  project: any;
  apiLoading: boolean;
  proid: string;
  submitting: boolean;
  configData = {};
  config: any;
  env: any;
  formData = {};
  activeAccordion: number = -1;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private proService: ProjectService,
    private notifierToast: NotifierToastService,
    private analytics: AnalyticsService,
    private cookieService: CookieService
  ) {}

  async ngOnInit() {
    this.proid = localStorage.getItem('proId');
    this.apiLoading = true;
    await this.proService
      .getProjectConfig(this.proid)
      .subscribe((data: any) => {
        this.project = data.project;
        data.project.allProjectComponents.forEach((element) => {
          this.configData[element.componentId] = {};
          this.formData[element.componentId] = {};
          let keys = Object.keys(element.configDetails);
          for (const key in element.configDetails[keys[0]]) {
            if (
              Object.prototype.hasOwnProperty.call(
                element.configDetails[keys[0]],
                key
              )
            ) {
              this.formData[element.componentId][key] =
                element.configDetails[keys[0]][key];
              this.configData[element.componentId][key] =
                element.configDetails[keys[0]][key];
            }
          }
          this.formData[element.componentId]['repo_name'] = element.repoName;
          this.formData[element.componentId]['usage'] = element.displayName;
          delete this.configData[element.componentId]['repo_name'];
          delete this.configData[element.componentId]['usage'];
        });
        this.env = JSON.parse(this.project.allProjectComponents[0].env);
        this.config = data.configData.reduce((acc, obj) => {
          const key = obj['componentId'];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key][obj['configName']] = obj['displayName'];
          return acc;
        }, {});
        this.apiLoading = false;
      });
  }

  toggleAccordion(index: number): void {
    this.activeAccordion = this.activeAccordion === index ? null : index;
  }
  
  async saveProjectName() {
    this.submitting = true;
    await this.httpClient
      .post(environment.SAVE_PROJECT, { id: this.proid })
      .subscribe((data) => {
        this.notifierToast.showNotification(
          'success',
          'Project Saved Successfully'
        );

        let param = {};
        param[analyticsConst.PARAMS.TIME] = new Date();
        param[analyticsConst.PARAMS.SUBMIT_PROJECT_ID] = this.proid;
        this.analytics.logEvent(
          analyticsConst.EVENTS.ADMIN_CREATE_PROJECT_STEP2,
          param
        );
        this.analytics.logEvent(
          analyticsConst.EVENTS.ADMIN_CREATE_PROJECT_STEP3,
          param
        );
        this.analytics.logEvent(
          analyticsConst.EVENTS.ADMIN_CREATE_PROJECT_STEP4,
          param
        );
        if (
          this.project.allProjectComponents[0].sourceProvider != null ||
          this.project.allProjectComponents[0].sourceProvider != ''
        ) {
          this.analytics.logEvent(
            analyticsConst.EVENTS.ADMIN_SELECT_GITHUB_VCS,
            param
          ); //if only vcs is provided
        } else {
          this.analytics.logEvent(analyticsConst.EVENTS.ADMIN_VCS_SKIP, param);
        }
        if (
          this.project.allProjectComponents[0].cicdProvider != null ||
          this.project.allProjectComponents[0].cicdProvider != ''
        ) {
          this.analytics.logEvent(
            analyticsConst.EVENTS.ADMIN_SELECT_GITACTION_CICD,
            param
          );
        } else {
          this.analytics.logEvent(analyticsConst.EVENTS.ADMIN_CICD_SKIP, param);
        }
        this.analytics.logEvent(
          analyticsConst.EVENTS.ADMIN_PROJECT_SUBMIT,
          param
        );

        localStorage.removeItem('formData');
        localStorage.removeItem('proId');
        localStorage.removeItem('projectComponent');
        this.cookieService.remove('projectId', {
          domain: environment.COOKIE_DOMAIN,
        });
        this.router.navigate([`/portal/dashboard`]);
        this.submitting = false;
      });
  }

  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }

  onSubmit() {}
  prev() {
    // if (
    //   this.project.allProjectComponents[0].sourceProvider == null ||
    //   this.project.allProjectComponents[0].sourceProvider == ''
    // ) {
    //   this.router.navigate([`/projects/create/${this.proid}/source`]);
    // } else {
    //   this.router.navigate([`/projects/create/${this.proid}/cicd`]);
    // }
    // this.router.navigate([`/projects/create/${this.proid}/config`]);
  }
  editProject() {
    localStorage.setItem('formData', JSON.stringify(this.formData));
    this.router.navigate([`/projects/create/${this.proid}/config`]);
  }
}
