import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TagInputModule } from 'ngx-chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { CookieModule } from 'ngx-cookie';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { AuthService } from 'src/app/shared-services/auth/auth.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FilterPipe } from 'src/app/auth/services/filter.pipe';
import { JsonParsePipe } from 'src/app/shared-services/jsonParse.pipe';
import { HeaderComponent } from 'src/app/shared-component/header/header.component';
import { FooterComponent } from 'src/app/auth/footer/footer.component';
import { SideNavContainerComponent } from 'src/app/portal/side-nav-container/side-nav-container.component';
import { SideNavAdminComponent } from 'src/app/portal/side-nav-admin/side-nav-admin.component';
import { ScratchCardComponent } from 'src/app/shared-component/scratch-card/scratch-card.component';
import { CardComponent } from 'src/app/shared-component/card/card.component';
import { RouterModule } from '@angular/router';
import { OrderByPipe } from 'src/app/order-by.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { TypeofPipe } from 'src/app/shared-services/typeOf.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/**
 * Custom angular notifier options
 */
const notifierDefaultOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 50,
    },
    vertical: {
      position: 'top',
      distance: 30,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [
    FilterPipe,
    JsonParsePipe,
    OrderByPipe,
    TypeofPipe,
    HeaderComponent,
    FooterComponent,
    SideNavContainerComponent,
    SideNavAdminComponent,
    ScratchCardComponent,
    CardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbNavModule,
    TooltipModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgCircleProgressModule.forRoot(),
    TagInputModule,
    MatTooltipModule,
    NgbModule,
    NgxPaginationModule,
    NotifierModule.withConfig(notifierDefaultOptions),
    CookieModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
    })
  ],
  exports: [
    FilterPipe,
    JsonParsePipe,
    HeaderComponent,
    FooterComponent,
    SideNavContainerComponent,
    SideNavAdminComponent,
    ScratchCardComponent,
    CardComponent,
    TranslateModule,
    NgbNavModule,
    TooltipModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgCircleProgressModule,
    TagInputModule,
    MatTooltipModule,
    NotifierModule,
    OrderByPipe,
    TypeofPipe,
    CookieModule,
    NgxPaginationModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [AuthService, TranslateService],
    };
  }
}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    '../assets/i18n/', // or whatever path you're using
    '.json'
  );
}
