<div class="outer-div" *ngIf="!apiLoading">
  <ul ngbNav
    #nav="ngbNav"
    class="env-ul nav nav-tabs"
    (navChange)="onTabChange($event)"
    [ngClass]="{
      'shrink-margin': !showExpandedView,
      'expanded-margin': showExpandedView
    }"
    [destroyOnHide]="true"
    [activeId]="allEnv[0]"
  >
    <li *ngFor="let env of allEnv" [ngbNavItem]="env">
      <a ngbNavLink class="nav-link">{{ env }}</a>
      <ng-template ngbNavContent>
        <app-edit-config
          [pro]="project"
          [currentEnv]="env"
          (updated)="valid($event)"
        ></app-edit-config>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>