import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-code-management',
  templateUrl: './code-management.component.html',
  styleUrls: ['./code-management.component.scss'],
})
export class CodeManagementComponent implements OnInit {
  @Input() pro: any;
  apiLoading: boolean;
  branches = [];
  envData: any;
  env = [];
  activeAccordion: number = -1;
  constructor() {}

  ngOnInit(): void {
    this.apiLoading = true;
    this.envData = JSON.parse(this.pro.allProjectComponents[0].env);
    this.envData.forEach((data) => {
      this.branches.push(data.source_branch);
      this.env.push(data.env);
      this.apiLoading = false;
    });
  }
  toggleAccordion(index: number): void {
    this.activeAccordion = this.activeAccordion === index ? null : index;
  }
}
