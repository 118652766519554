import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss'],
})
export class TagsInputComponent implements OnInit {
  @Output() egress = new EventEmitter();
  tagItems: any = [];
  @Input() inboundRuleData: any = [];

  constructor(private http: HttpClient) {
    //  this.GetTemplateList();
  }
  inboundItem: any = [];

  comp: any = [];
  componentInboundRule = [];
  @Input() data: any;
  ngOnInit(): void {
    this.comp = this.data;
    this.http.get(environment.TEMPLATE_LIST).subscribe((data) => {
      this.tagItems = data['body'];

      if (this.inboundRuleData && this.inboundRuleData[0]) {
        this.inboundRuleData.forEach((element) => {
          if (element.componentId == this.comp.id) {
            this.tagItems.forEach((ele) => {
              if (ele.id == element.allowedComponentId) {
                this.inboundItem.push(ele);
                return;
              }
            });
          }
        });
      }
    });
  }
  GetTemplateList() {
    this.http.get(environment.TEMPLATE_LIST).subscribe((data) => {
      this.tagItems = data['body'];
    });
  }
  onSelected($event: any) {
    // console.log(this.inboundItem);
    // console.log('Printing inbound array....', this.inboundItem);
    // console.log('Inboundcomponent selected is:', this.comp);

    this.inboundItem.forEach((element) => {
      // console.log('Printing element', element);
      let isRuleExist = this.componentInboundRule.find(
        (o) =>
          o.componentId == this.comp.id && o.allowedComponentId == element.id
      );
      if (!isRuleExist && !this.comp.outgres?.includes(element.id)) {
        this.componentInboundRule.push({
          componentId: this.comp.id,
          componentName: this.comp.label,
          ruleType: 'Inbound',
          allowedComponentId: element.id,
          allowedComponentName: element.label,
        });
      }
    });
    // console.log('Printing componentInboundRule', this.componentInboundRule);
    this.egress.emit(this.componentInboundRule);
  }
}
