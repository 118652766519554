<div class="sb-nav-fixed">
  <app-header [showExpandedView]="showExpandedView"></app-header>

  <div id="layoutSidenav">
    <app-side-nav-container
      (sendCollapsedStatus)="checkCollapseStatus($event)"
    ></app-side-nav-container>
    <div
      id="layoutSidenav_content"
      [ngClass]="{
        'shrinked-view': !showExpandedView,
        'expanded-view': showExpandedView
      }"
    >
      <main>
        <div class="container-fluid">
          <div class="row project-button">
            <div class="mr-auto p-2">
              <h2 class="font30">{{ "common.create_project" | translate }}</h2>
            </div>
          </div>

          <div id="stepper1" class="bs-stepper">
            <app-stepper></app-stepper>
            <!-- <div *ngIf="apiLoading" class="d-flex justify-content-center">
                            <div class="loader-create" role="status">
                               <img src="../../assets/icons/new-loader.svg" alt="Loading..."/>
                            </div>
                        </div> -->
            <div class="bs-stepper-content">
              <div>
                <div>
                  <p>Select a Git provider to continue with</p>
                </div>
                <div id="test-l-4" class="git-providers">
                  <div class="git-buttons">
                    <button
                      (mouseover)="show('github')"
                      (mouseleave)="show('general')"
                      (click)="githubFunc()"
                      class="effect github"
                    >
                      <img
                        class="git-svgs"
                        src="../../../assets/images/github-svgrepo-com.svg"
                      />
                      {{ "common.providers.github" | translate }}
                    </button>
                    <button
                      (mouseover)="show('gitlab')"
                      (mouseleave)="show('general')"
                      class="effect gitlab disabled"
                    >
                      <img
                        class="git-svgs lab-svg grey-svg"
                        src="../../../assets/images/gitlab-svgrepo-com.svg"
                      />
                      {{ "common.providers.gitlab" | translate }}
                    </button>
                    <button
                      (mouseover)="show('bitbucket')"
                      (mouseleave)="show('general')"
                      class="effect bitbucket disabled"
                    >
                      <img
                        class="git-svgs lab-svg grey-svg"
                        src="../../../assets/images/bitbucket-svgrepo-com.svg"
                      />
                      {{ "common.providers.bitbucket" | translate }}
                    </button>
                    <button
                      [disabled]
                      (mouseover)="show('codecommit')"
                      (mouseleave)="show('general')"
                      class="effect github disabled"
                    >
                      <img
                        class="code-git-svgs grey-svg"
                        src="../../../assets/images/codecommit.png"
                      />
                      {{ "common.providers.codecommit" | translate }}
                    </button>
                    <button
                      [disabled]
                      (mouseover)="show('azure')"
                      (mouseleave)="show('general')"
                      class="effect github disabled"
                    >
                      <img
                        class="azure-svg grey-svg"
                        src="../../../assets/images/azure_repos.png"
                      />
                      {{ "common.providers.azure_repo" | translate }}
                    </button>
                    <button
                      [disabled]
                      (mouseover)="show('google')"
                      (mouseleave)="show('general')"
                      class="effect github disabled"
                    >
                      <img
                        class="google-git-svgs grey-svg"
                        src="../../../assets/images/google_source.png"
                      />
                      {{ "common.providers.google_repo" | translate }}
                    </button>
                  </div>
                  <div class="show-hide">
                    <div class="details">
                      <div *ngIf="active == 'general'" class="general-details">
                        Please select a git provider
                        <br />
                        <p class="note">
                          Note: We do not store Git credentials
                        </p>
                      </div>
                      <p *ngIf="active == 'github'" class="github-details">
                        {{ "common.continue_auth" | translate }} GitHub
                      </p>
                      <p *ngIf="active == 'gitlab'" class="gitlab-details">
                        {{ "common.support_soon" | translate }}
                      </p>
                      <p
                        *ngIf="active == 'bitbucket'"
                        class="bitbucket-details"
                      >
                        {{ "common.support_soon" | translate }}
                      </p>
                      <p
                        *ngIf="active == 'codecommit'"
                        class="code-commit-details"
                      >
                        {{ "common.support_soon" | translate }}
                      </p>
                      <p *ngIf="active == 'azure'" class="azure-details">
                        {{ "common.support_soon" | translate }}
                      </p>
                      <p *ngIf="active == 'google'" class="google-details">
                        {{ "common.support_soon" | translate }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- <button
                type="submit"
                (click)="next()"
                class="btn btn-primary mt-5"
              >
                Next
              </button> -->
                <!-- <button
                  type="submit"
                  (click)="prev()"
                  class="btn btn-primary mt-5"
                >
                  {{ "common.button.previous" | translate }}
                </button> -->
                <!-- <div class="skip-div"> -->
                <a
                  class="skip-text"
                  (click)="next()"
                  routerLinkActive="router-link-active"
                  >{{ "common.button.skip" | translate
                  }}<img
                    src="../../../assets/icons/right-arrow.svg"
                    class="skip-arrow"
                /></a>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
