<div class="sb-nav-fixed">
  <app-header [showExpandedView]="showExpandedView"></app-header>

  <div id="layoutSidenav">
    <app-side-nav-container
      (sendCollapsedStatus)="checkCollapseStatus($event)"
    ></app-side-nav-container>
    <div
      id="layoutSidenav_content"
      [ngClass]="{
        'shrinked-view': !showExpandedView,
        'expanded-view': showExpandedView
      }"
    >
      <main>
        <div class="container-fluid">
          <div class="row project-button">
            <div class="mr-auto p-2">
              <h2 class="font30">{{ "common.create_project" | translate }}</h2>
            </div>
          </div>

          <div id="stepper1" class="bs-stepper">
            <app-stepper></app-stepper>
            <!-- <div *ngIf="apiLoading" class="d-flex justify-content-center">
                            <div class="loader-create" role="status">
                               <img src="../../assets/icons/new-loader.svg" alt="Loading..."/>
                            </div>
                        </div> -->
            <div class="bs-stepper-content ci-cd-outer">
              <p>Select a Provider to continue with</p>
              <div id="test-l-5" class="ci-cd-stepper ci-cd-outer">
                <div class="git-buttons">
                  <button
                    (mouseover)="show('github')"
                    (mouseleave)="show('general')"
                    class="effect github"
                    (click)="github()"
                  >
                    <img
                      class="git-svgs"
                      src="../../../assets/images/github-svgrepo-com.svg"
                    />
                    GitHub Actions
                  </button>
                  <button
                    [disabled]
                    (mouseover)="show('gitlab')"
                    (mouseleave)="show('general')"
                    class="effect gitlab disabled"
                  >
                    <img
                      class="git-svgs lab-svg grey-svg"
                      src="../../../assets/images/gitlab-svgrepo-com.svg"
                    />
                    GitLab CI
                  </button>
                  <button
                    [disabled]
                    (mouseover)="show('bitbucket')"
                    (mouseleave)="show('general')"
                    class="effect bitbucket disabled"
                  >
                    <img
                      class="git-svgs lab-svg grey-svg"
                      src="../../../assets/images/bitbucket-svgrepo-com.svg"
                    />
                    BitBucket pipelines
                  </button>
                  <button
                    [disabled]
                    (mouseover)="show('travisci')"
                    (mouseleave)="show('general')"
                    class="effect travisci disabled grey-svg"
                  >
                    <img
                      class="git-svgs lab-svg grey-svg"
                      src="../../../assets/images/travis-svgrepo-com.svg"
                    />
                    TravisCI
                  </button>
                  <button
                    [disabled]
                    (mouseover)="show('circleci')"
                    (mouseleave)="show('general')"
                    class="effect circleci disabled"
                  >
                    <img
                      class="git-svgs lab-svg grey-svg"
                      src="../../../assets/images/circleci-svgrepo-com.svg"
                    />
                    CircleCI
                  </button>
                  <button
                    [disabled]
                    (mouseover)="show('codecommit')"
                    (mouseleave)="show('general')"
                    class="effect github disabled"
                  >
                    <img
                      class="code-git-svgs grey-svg"
                      src="../../../assets/images/codecommit.png"
                    />
                    CodePipeline
                  </button>
                  <button
                    [disabled]
                    (mouseover)="show('azure')"
                    (mouseleave)="show('general')"
                    class="effect github disabled"
                  >
                    <img
                      class="azure-svg grey-svg"
                      src="../../../assets/images/azure_repos.png"
                    />
                    Azure DevOps
                  </button>
                  <button
                    [disabled]
                    (mouseover)="show('google')"
                    (mouseleave)="show('general')"
                    class="effect github disabled"
                  >
                    <img
                      class="google-git-svgs grey-svg"
                      src="../../../assets/images/google_source.png"
                    />
                    Google Cloud Build
                  </button>
                </div>
                <div class="show-hide">
                  <div class="details">
                    <div *ngIf="active == 'general'" class="general-details">
                      Please select a git provider of your choosing
                      <br />
                      <p class="note">Note: We do not store Git credentials</p>
                    </div>
                    <p *ngIf="active == 'github'" class="github-details">
                      Continue and authenticate using GitHub Actions
                    </p>
                    <p *ngIf="active == 'gitlab'" class="gitlab-details">
                      {{ "common.support_soon" | translate }}
                    </p>
                    <p *ngIf="active == 'bitbucket'" class="bitbucket-details">
                      {{ "common.support_soon" | translate }}
                    </p>
                    <p *ngIf="active == 'travisci'" class="bitbucket-details">
                      {{ "common.support_soon" | translate }}
                    </p>
                    <p *ngIf="active == 'circleci'" class="bitbucket-details">
                      {{ "common.support_soon" | translate }}
                    </p>
                    <p
                      *ngIf="active == 'codecommit'"
                      class="code-commit-details"
                    >
                      {{ "common.support_soon" | translate }}
                    </p>
                    <p *ngIf="active == 'azure'" class="azure-details">
                      {{ "common.support_soon" | translate }}
                    </p>
                    <p *ngIf="active == 'google'" class="google-details">
                      {{ "common.support_soon" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <button
                  type="submit"
                  (click)="next()"
                  class="btn btn-primary mt-5"
                >
                  Next
                </button> -->
            <!-- <button type="submit" (click)="prev()" class="btn btn-primary mt-5">
              Previous
            </button> -->
            <!-- <div class="skip-div"> -->
            <a
              class="skip-text"
              (click)="next()"
              routerLinkActive="router-link-active"
              >Skip<img
                src="../../../assets/icons/right-arrow.svg"
                class="skip-arrow"
            /></a>
            <!-- </div> -->
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
