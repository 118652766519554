<!-- <meta name="viewport" content="width=device-width, initial-scale=1" />
<link
  rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
/>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script> -->

<div class="sb-nav-fixed fontAll">
  <app-header [showExpandedView]="showExpandedView"></app-header>
  <div id="layoutSidenav">
    <app-side-nav-container
      (sendCollapsedStatus)="checkCollapseStatus($event)"
    ></app-side-nav-container>
    <div
      id="layoutSidenav_content"
      [ngClass]="{
        'shrinked-view': !showExpandedView,
        'expanded-view': showExpandedView
      }"
    >
      <main>
        <div class="container-fluid">
          <div class="mr-auto p-2">
            <h2 class="font30">{{ "common.create_template" | translate }}</h2>
          </div>
          <div id="stepper2" class="bs-stepper">
            <div class="bs-stepper-header">
              <div class="step" data-target="#test-l-1">
                <button class="step-trigger step_trigger">
                  <span class="bs-stepper-circle">1</span>
                  <span class="bs-stepper-label">{{
                    "common.template_info" | translate
                  }}</span>
                </button>
              </div>
              <div class="line"></div>
              <div class="step" data-target="#test-l-2">
                <button
                  [disabled]="!firstStepCompleted"
                  class="step-trigger step_trigger"
                >
                  <span class="bs-stepper-circle">2</span>
                  <span class="bs-stepper-label">{{
                    "common.add_components" | translate
                  }}</span>
                </button>
              </div>
              <div class="line"></div>
              <div class="step" data-target="#test-l-3">
                <button
                  [disabled]="!secondStepCompleted"
                  class="step-trigger step_trigger"
                >
                  <span class="bs-stepper-circle">3</span>
                  <span class="bs-stepper-label">{{
                    "common.component_rules" | translate
                  }}</span>
                </button>
              </div>
              <div class="line"></div>
              <div class="step" data-target="#test-l-4">
                <button
                  [disabled]="!thirdStepCompleted"
                  class="step-trigger step_trigger"
                >
                  <span class="bs-stepper-circle">4</span>
                  <span class="bs-stepper-label">{{
                    "common.template_diagrams" | translate
                  }}</span>
                </button>
              </div>
              <div class="line"></div>
              <div class="step" data-target="#test-l-5">
                <button
                  [disabled]="!fourthStepCompleted"
                  class="step-trigger step_trigger"
                >
                  <span class="bs-stepper-circle">5</span>
                  <span class="bs-stepper-label">{{
                    "common.template_summary" | translate
                  }}</span>
                </button>
              </div>
            </div>

            <div *ngIf="apiLoading" class="d-flex justify-content-center">
              <div class="loader-create" role="status">
                <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
              </div>
            </div>
            <div class="bs-stepper-content">
              <form novalidate>
                <div id="test-l-1" class="content">
                  <div *ngIf="!apiLoading" class="form-group col-lg-12">
                    <form
                      name="form"
                      novalidate
                      (ngSubmit)="f.valid && addTempInfo(f)"
                      (change)="checkFormStatus()"
                      #f="ngForm"
                    >
                      <!-- ngNativeValidate -->
                      <div class="form-group">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label for="name" class="mr-sm-4 bold_labels"
                                >{{ "common.name" | translate
                                }}<span class="error-text">* </span></label
                              >
                              <input
                                type="text"
                                #temp="ngModel"
                                #tempInput
                                [(ngModel)]="tempName"
                                class="form-control mr-sm-3 placeholders"
                                id="name"
                                (keyup)="generateSlug($event)"
                                placeholder="{{
                                  'common.template_name' | translate
                                }}"
                                name="temp"
                                [ngClass]="{
                                  'is-invalid':
                                    (temp.dirty && temp.invalid) ||
                                    (f.submitted && temp.invalid)
                                }"
                                required
                              />
                              <!--   [ngModelOptions]="{ standalone: true }"
                                Required Validation Message -->
                              <div
                                *ngIf="
                                  (temp.dirty && temp.invalid) ||
                                  (f.submitted && temp.invalid)
                                "
                                class="error-text"
                              >
                                <span *ngIf="temp.errors.required">{{
                                  "common.validation_message.template_required"
                                    | translate
                                }}</span>
                              </div>
                            </div>
                          </div>

                          <div class="col">
                            <label for="slug" class="mr-sm-2 bold_labels"
                              >{{ "common.slug" | translate }}
                            </label>
                            <input
                              type="text"
                              value="{{ slug }}"
                              readonly
                              class="form-control mb-2 mr-sm-2 placeholders"
                              id="slug"
                              placeholder="{{ 'common.slug' | translate }}"
                              [(ngModel)]="slug"
                              name="slug"
                            />
                            <!-- [ngModelOptions]="{ standalone: true }" -->
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label for="desc" class="mr-sm-2 bold_labels">
                                {{ "common.description" | translate
                                }}<span class="error-text">*</span></label
                              ><br />
                              <textarea
                                class="textArea form-control placeholders"
                                id="desc"
                                [(ngModel)]="desc"
                                name="Desc"
                                rows="4"
                                cols="50"
                                placeholder="{{
                                  'common.description' | translate
                                }}"
                                #Desc="ngModel"
                                [ngClass]="{
                                  'is-invalid':
                                    (Desc.dirty && Desc.invalid) ||
                                    (f.submitted && Desc.invalid)
                                }"
                                required
                              ></textarea>
                              <!-- [ngModelOptions]="{ standalone: true }" -->
                              <div
                                *ngIf="
                                  (Desc.dirty && Desc.invalid) ||
                                  (f.submitted && Desc.invalid)
                                "
                                class="error-text"
                              >
                                <span *ngIf="Desc.errors.required">{{
                                  "common.validation_message.description_required"
                                    | translate
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col">
                            <div class="row"> -->
                          <div class="col">
                            <div class="form-group">
                              <label class="mr-sm-2 bold_labels logo_image">
                                {{ "common.logo" | translate }}
                                <span class="error-text">*</span> </label
                              ><br />
                              <div class="inputFile">
                                <label for="imageUpload" class="mr-sm-2">
                                  <div class="customInputFile">Choose File</div>
                                </label>
                                <br />
                                <input
                                  type="file"
                                  (change)="
                                    uploadFiles($event, 'logo', '2000000')
                                  "
                                  class="displayNone placeholders"
                                  name="imageUpload"
                                  id="imageUpload"
                                  accept="{{
                                    'common.allowed_images' | translate
                                  }}"
                                />
                                <div class="bearkWords">
                                  <span *ngIf="logoKey != undefined">{{
                                    inputFileName
                                  }}</span>
                                  <span
                                    *ngIf="logoKey == undefined && !isUploading"
                                    >No File Chosen</span
                                  >
                                  &nbsp;
                                  <span
                                    *ngIf="isUploading"
                                    class="spinner-border-sm text-primary"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </div>
                              </div>
                              <div *ngIf="showError == 'logo'">
                                <div
                                  *ngIf="f.submitted && logoKey == undefined"
                                  class="error-text"
                                >
                                  <span>{{
                                    "common.validation_message.logo_required"
                                      | translate
                                  }}</span>
                                </div>
                                <div *ngIf="isMismatch" class="error-text">
                                  <span>{{
                                    "common.image_format_error" | translate
                                  }}</span>
                                </div>
                                <div *ngIf="isTooBig" class="error-text">
                                  <span>
                                    {{ "common.file_size_exceed" | translate }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- </div>
                          </div> -->
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label
                                for="technology"
                                class="mr-sm-2 bold_labels"
                                >{{ "common.technology_stack" | translate
                                }}<span class="error-text">*</span></label
                              >
                              <br />
                              <input
                                class="form-control ml-10 placeholders"
                                type="text"
                                id="technologySet"
                                placeholder="Java, Angular, Python..."
                                name="technologyRef"
                                [(ngModel)]="technology"
                                #technologyRef="ngModel"
                                [ngClass]="{
                                  'is-invalid':
                                    technologyRef.dirty && technologyRef.invalid
                                }"
                                required
                              />
                              <!-- (keydown.enter)="onSkillsSetKeydown(); (false)" -->
                              <div
                                *ngIf="
                                  technologyRef.dirty && technologyRef.invalid
                                "
                                class="error-text"
                              >
                                <span *ngIf="technologyRef.errors.required">{{
                                  "common.validation_message.technology_stack_required"
                                    | translate
                                }}</span>
                              </div>
                              <!-- <app-chip
                                (onpick)="addTechnology($event)"
                                (onTechnology)="getTechStatus($event)"
                              >
                              </app-chip> -->
                              <!-- <tag-input ng-model="tags"></tag-input> -->
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label for="platform" class="mr-sm-2 bold_labels"
                                >{{ "common.platform" | translate
                                }}<span class="error-text">*</span></label
                              >
                              <br />
                              <input
                                type="text"
                                #Platform="ngModel"
                                #PlatformInput
                                [(ngModel)]="platform"
                                class="form-control mr-sm-3 placeholders"
                                id="platform"
                                placeholder="AWS, Azure, GCP"
                                [ngClass]="{
                                  'is-invalid':
                                    (Platform.dirty && Platform.invalid) ||
                                    (f.submitted && Platform.invalid)
                                }"
                                name="Platform"
                                required
                              />
                              <div
                                *ngIf="
                                  (Platform.dirty && Platform.invalid) ||
                                  (f.submitted && Platform.invalid)
                                "
                                class="error-text"
                              >
                                <span *ngIf="Platform.errors.required"
                                  >Please Provide Platform</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br /><br />
                      <div class="rightButton">
                        &nbsp;
                        <button
                          type="submit"
                          class="btn btn-primary"
                          [disabled]="f.invalid || logoKey == undefined"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div id="test-l-2" class="content">
                  <div *ngIf="!apiLoading" class="row">
                    <div class="col-md-12">
                      <app-tabs
                        (onpick)="addlist($event)"
                        [fromTemplate]="componentList"
                      ></app-tabs>
                    </div>
                  </div>
                  <br />
                  <!-- <button type="button" class="btn btn-link"  style="float: right;margin-right: 40px;" (click)="add()">
                    <i class='icon-color fa fa-plus-circle'></i>
                  </button><br> -->

                  <div class="rightButton">
                    <div (click)="stepBack()" class="btn btn-primary mt-5">
                      {{ "common.button.previous" | translate }}
                    </div>
                    &nbsp; &nbsp;
                    <span>
                      <button
                        [disabled]="
                          componentList == undefined ||
                          componentList.length == 0
                        "
                        (click)="addComponentList()"
                        class="btn btn-primary mt-5"
                      >
                        {{ "common.button.next" | translate }}
                      </button>
                    </span>
                  </div>
                </div>
                <div id="test-l-3" class="content">
                  <div *ngIf="!apiLoading" class="container">
                    <div
                      class="accordion"
                      id="accordionExample"
                      *ngFor="let component of componentList; let i = index"
                    >
                      <div class="accordion-item" id="{{ component.id }}">
                        <h2
                          class="accordion-header"
                          id="heading{{ component.id }}"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            attr.data-bs-target="#collapseOne{{ component.id }}"
                            aria-expanded="true"
                            attr.aria-controls="collapseOne{{ component.id }}"
                          >
                            {{ component.label }}
                          </button>
                        </h2>

                        <div
                          id="collapseOne{{ component.id }}"
                          class="accordion-collapse collapse"
                          attr.aria-labelledby="heading{{ component.id }}"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div id="main">
                              <div id="new" class="blackBorder">
                                <h5 class="placeholders">
                                  <b>{{
                                    "common.inbound_components" | translate
                                  }}</b>
                                </h5>
                                <br />

                                <app-tags-input
                                  (egress)="fetchRules($event)"
                                  [data]="component"
                                  [inboundRuleData]="inboundCompRule"
                                ></app-tags-input>
                              </div>
                              <div id="existing" class="blackBorder">
                                <h5 class="placeholders">
                                  <b>{{
                                    "common.outbound_components" | translate
                                  }}</b>
                                </h5>
                                <br />

                                <app-tags-output
                                  [data]="component"
                                  (outgress)="fetchRules($event)"
                                  [outboundRuleData]="outboundCompRule"
                                ></app-tags-output>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br /><br /><br /><br /><br /><br /><br />
                  &nbsp;
                  <div class="rightButton">
                    <div (click)="stepBack()" class="btn btn-primary">
                      {{ "common.button.previous" | translate }}
                    </div>
                    &nbsp;
                    <button
                      (click)="addComponentRules()"
                      class="btn btn-primary"
                    >
                      {{ "common.button.next" | translate }}
                    </button>
                  </div>
                </div>
                <div id="test-l-4" class="content">
                  <div class="container blackBorder">
                    <ul ngbNav #nav="ngbNav" class="nav-pills">
                      <li [ngbNavItem]="'architecture'">
                        <a ngbNavLink>
                          {{ "common.architecture_diagram" | translate }}
                        </a>
                        <ng-template ngbNavContent>
                          <br />
                          <div class="input-file inputFile">
                            <label for="archUpload" class="mr-sm-2 bold_labels">
                              <div class="customInputFile">Choose File</div>
                            </label>
                            <br />
                            <input
                              type="file"
                              (change)="uploadFiles($event, 'architectureDiagram', '2000000')"
                              class="displayNone placeholders"
                              name="archUpload"
                              id="archUpload"
                              accept="{{ 'common.allowed_images' | translate }}"
                            />
                            <span *ngIf="archKey != undefined">{{ inputFileName }}</span>
                            <span *ngIf="archKey == undefined && !isUploading">No File Chosen</span>
                            &nbsp;
                            <span *ngIf="isUploading" class="spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                          </div>
                          <div *ngIf="showError == 'architectureDiagram'">
                            <div *ngIf="isMismatch" class="error-text">
                              <span>{{ "common.image_format_error" | translate }}</span>
                            </div>
                            <div *ngIf="isTooBig" class="error-text">
                              <span>{{ "common.file_size_exceed" | translate }}</span>
                            </div>
                          </div>
                          <img *ngIf="archDiagramPreview" id="selected-tmp-arch-img" [src]="archDiagramPreview" />
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="'deployment'">
                        <a ngbNavLink>
                          {{ "common.deployment_diagram" | translate }}
                        </a>
                        <ng-template ngbNavContent>
                          <br />
                          <div class="input-file inputFile">
                            <label for="deployUpload" class="mr-sm-2 bold_labels">
                              <div class="customInputFile">Choose File</div>
                            </label>
                            <br />
                            <input
                              type="file"
                              (change)="uploadFiles($event, 'deploymentDiagram', '2000000')"
                              class="displayNone placeholders"
                              name="deployUpload"
                              id="deployUpload"
                              accept="{{ 'common.allowed_images' | translate }}"
                            />
                            <span *ngIf="deployKey != undefined">{{ inputFileName }}</span>
                            <span *ngIf="deployKey == undefined && !isUploading">No File Chosen</span>
                            &nbsp;
                            <span *ngIf="isUploading" class="spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                          </div>
                          <div *ngIf="showError == 'deploymentDiagram'">
                            <div *ngIf="isMismatch" class="error-text">
                              <span>{{ "common.image_format_error" | translate }}</span>
                            </div>
                            <div *ngIf="isTooBig" class="error-text">
                              <span>{{ "common.file_size_exceed" | translate }}</span>
                            </div>
                          </div>
                          <img *ngIf="deploymentDiagramPreview" id="selected-tmp-dep-img" [src]="deploymentDiagramPreview" />
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                  </div>
                
                  <div class="rightButton">
                    <button (click)="stepBack()" class="btn btn-primary mt-5">
                      {{ "common.button.previous" | translate }}
                    </button>
                    &nbsp;&nbsp;
                    <button (click)="addDiagrams()" class="btn btn-primary mt-5">
                      {{ "common.button.next" | translate }}
                    </button>
                  </div>
                </div>                
                <div id="test-l-5" class="content text-center">
                  <div *ngIf="!apiLoading" class="container">
                    <table>
                      <thead>
                        <tr>
                          <td colspan="2" class="text-center">
                            <strong>
                              {{
                                "common.template_summary" | translate
                              }}</strong
                            >
                          </td>
                        </tr>
                      </thead>
                      <tr>
                        <th>{{ "common.name" | translate }}:</th>
                        <td>{{ tempName }}</td>
                      </tr>
                      <tr>
                        <th>{{ "common.slug" | translate }}</th>
                        <td>{{ slug }}</td>
                      </tr>
                      <tr>
                        <th>{{ "common.description" | translate }}</th>
                        <td>{{ desc }}</td>
                      </tr>
                    </table>
                    <br />
                    <table>
                      <thead>
                        <tr>
                          <th>{{ "common.components" | translate }}</th>
                          <th>{{ "common.inbound" | translate }}</th>
                          <th>{{ "common.outbound" | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let finalcomponent of rulesPreview">
                          <td>
                            {{ finalcomponent.compName.replaceAll('"', "") }}
                          </td>
                          <td>
                            <li *ngFor="let subitem of finalcomponent.inbound">
                              {{ subitem }}
                            </li>
                          </td>
                          <!-- <td >{{(finalcomponent.inbound.join(',').replaceAll('"','')).replaceAll(',','<br>')}}</td> -->
                          <td>
                            <li
                              *ngFor="let subitem1 of finalcomponent.outbound"
                            >
                              {{ subitem1 }}
                            </li>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <!-- <table>
                      <nav ngbNav [destroyOnHide]="false">
                        <ngb-tab title="Logo">
                          <ng-template ngbNavContent>
                            <br />
                            <img
                              id="selected-tmp-dep-img"
                              [src]="s3Url + logoKey"
                              alt="Logo"
                              *ngIf="logoKey"
                            />
                          </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Architecture Diagram">
                          <ng-template ngbNavContent>
                            <br />
                            <img
                              id="selected-tmp-dep-img"
                              [src]="s3Url + archKey"
                              *ngIf="archKey"
                              alt="Architecture Diagram"
                            />
                          </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                          <ng-template ngbTabTitle>{{
                            "common.deployment_diagram" | translate
                          }}</ng-template>
                          <ng-template ngbNavContent
                            ><br />
                            <img
                              id="selected-tmp-dep-img"
                              [src]="s3Url + deployKey"
                              *ngIf="deployKey"
                              alt="Deployment Diagram"
                            />
                          </ng-template>
                        </ngb-tab>
                      </nav>
                    </table> -->
                    <nav ngbNav [destroyOnHide]="false">
                      <ul>
                        <li [ngbNavItem]="1">
                          <a ngbNavLink>Logo</a>
                          <ng-template ngbNavContent>
                            <img
                              id="selected-tmp-dep-img"
                              [src]="s3Url + logoKey"
                              alt="Logo"
                              *ngIf="logoKey"
                            />
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                          <a ngbNavLink>Architecture Diagram</a>
                          <ng-template ngbNavContent>
                            <img
                              id="selected-tmp-dep-img"
                              [src]="s3Url + archKey"
                              *ngIf="archKey"
                              alt="Architecture Diagram"
                            />
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                          <a ngbNavLink>{{ "common.deployment_diagram" | translate }}</a>
                          <ng-template ngbNavContent>
                            <img
                              id="selected-tmp-dep-img"
                              [src]="s3Url + deployKey"
                              *ngIf="deployKey"
                              alt="Deployment Diagram"
                            />
                          </ng-template>
                        </li>
                      </ul>
                    </nav>
                    
                    <table>
                      <!-- Your table content here -->
                    </table>
                    
                  </div>

                  <br />
                  <div class="rightButton">
                    <button (click)="stepBack()" class="btn btn-primary mt-5">
                      {{ "common.button.previous" | translate }}</button
                    >&nbsp;&nbsp;
                    <button
                      (click)="submitForDraft()"
                      class="btn btn-primary mt-5"
                    >
                      {{ "common.button.save_as_draft" | translate }}</button
                    >&nbsp;&nbsp;
                    <button
                      (click)="submitForReview()"
                      class="btn btn-primary mt-5"
                    >
                      {{ "common.button.submit_for_review" | translate }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
