<div *ngIf="apiLoading" class="d-flex justify-content-center">
  <div class="loader-create" role="status">
    <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
  </div>
</div>

<div class="outer-block" *ngIf="!apiLoading">
  <!--Edit project name-->
  <div class="project-name">
    <div class="update-name">
      <p class="name headings">Project name</p>
      <input
        type="text"
        class="form-control name-input"
        [ngModel]="projectName"
        (ngModelChange)="onChange($event)"
        required
      />
      <button
        [disabled]="saveEnabled"
        class="btn btn-primary save-btn"
        (click)="updateProjectName()"
      >
        Save
        <span
          *ngIf="updating"
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        >
        </span>
      </button>
    </div>
    <div class="temp-name" *ngIf="project.templateLabel">
      <p class="headings">Template Name</p>
      <p class="templateLabel">{{ project.templateLabel }}</p>
    </div>
  </div>

  <!--Componenet section-->
  <div class="components">
    <p class="headings">Components</p>
    <div class="accordion comp-accordion" id="accordionExample">
      <div
        class="accordion-item"
        *ngFor="
          let comp of project.allProjectComponents | orderBy: 'displayName';
          let i = index
        "
      >
        <h2 class="accordion-header" [id]="'headingOne' + i">
          <button
            class="accordion-button"
            [ngClass]="{'collapsed': activeAccordion !== i}"
            type="button"
            (click)="toggleAccordion(i)"
            [attr.data-bs-target]="'#collapseGeneral' + i"
            aria-expanded="activeAccordion === i"
            [attr.aria-controls]="'collapseGeneral' + i"
          >
            {{ comp.displayName }}
          </button>
        </h2>
        <div
          [id]="'collapseGeneral' + i"
          class="accordion-collapse collapse"
          [attr.aria-labelledby]="'headingOne' + i"
          [ngClass]="{ 'show': activeAccordion === i }"
        >
          <div class="accordion-body">
            <table class="table table-dark">
              <tbody style="border: black 1px solid; font-size: 14px">
                <tr>
                  <th style="width: 19vw" scope="row" class="text-left">
                    Repo Name
                  </th>
                  <td *ngIf="comp.repoName != ''" class="text-left">
                    {{ comp.repoName }}
                  </td>
                  <td *ngIf="comp.repoName == ''" class="text-left">
                    Not Provided
                  </td>
                </tr>
                <tr>
                  <th style="width: 19vw" scope="row" class="text-left">
                    Source Provider
                  </th>
                  <td *ngIf="comp.sourceProvider != null" class="text-left">
                    <span *ngIf="comp.sourceProvider == 'GITHUB'">GitHub</span>
                  </td>
                  <td *ngIf="comp.sourceProvider == null" class="text-left">
                    Not Provided
                  </td>
                </tr>
                <tr>
                  <th style="width: 19vw" scope="row" class="text-left">
                    CI/CD Provider
                  </th>
                  <td *ngIf="comp.cicdProvider != null" class="text-left">
                    <span *ngIf="comp.cicdProvider == 'GITHUB_ACTION'">
                      GitHub Actions
                    </span>
                  </td>
                  <td *ngIf="comp.cicdProvider == null" class="text-left">
                    Not Provided
                  </td>
                </tr>
                <tr>
                  <th style="width: 19vw" scope="row" class="text-left">
                    Component Name
                  </th>
                  <td
                    *ngFor="
                      let item of componentMap | keyvalue;
                      let inneri = index
                    "
                    [ngClass]="{
                      hide: inneri != i,
                      show: inneri == i
                    }"
                    class="text-left"
                  >
                    {{ item.value.name }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="button-edit">
              <button
                class="btn btn-primary edit-config-btn"
                (click)="editConfig('configuration', 'headingOne' + i)"
              >
                Edit Config
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Actions section-->
  <div class="actions">
    <p class="headings">Actions</p>
    <!-- <div class="edit-canvas">
      <p>Edit Project on canvas</p>
      <button class="btn btn-primary edit-btn" (click)="openeditor()">
        Edit Project
      </button>
    </div> -->
    <div class="delete-project">
      <p>Delete Project</p>
      <button disabled class="btn delete-btn" (click)="deleteproject()">
        Delete Project
        <span
          *ngIf="deleting"
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </div>
  </div>
</div>
