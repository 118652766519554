import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie';
import { ProjectService } from './project-services/project.service';
import { UserService } from '../shared-services/user/user.service';
import { NotifierToastService } from '../shared-services/notifier-toast.service';
import { ICollapseStatus } from '../portal/side-nav-container/side-nav-container.utils';
import { ProjectDataService } from './project-services/project-data.service';
import { analyticsConst } from '../shared-services/analytics-constants';
import { AnalyticsService } from '../shared-services/analytics.service';

declare var $: any;
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
  private stepper: Stepper;
  @ViewChild('searchbar')
  searchbar!: ElementRef;
  allProjectTypes: any;
  allDomains: any;
  all;
  searchText = '';
  // firstStepCompleted = false;
  // secondStepCompleted = false;
  // thirdStepCompleted = false;
  allTemplate: any;
  // selectedProjectType: string;
  // selectedDomain: string;
  // selectedTemplate: string;
  // selectedArchDiagram: string;
  deploymentDiagram: string;
  projectName: string;
  isSavingProject: boolean;
  project: any;
  Object = Object;
  apiLoading = true;
  domainPresent = false;
  loggedInUserName: string;
  // selectedProjectTypeLabel: string;
  // selectedDomainLabel: string;
  // selectedTemplateLabel: string;
  platform: any;
  platformObj: any = {
    AWS: '../../assets/images/aws.png',
    AZURE: '../../assets/images/azure.png',
    GCP: '../../assets/images/gcp.png',
    OCI: '../../assets/images/oci.png'
  };
  platformArray = {};
  tempVar =
    'You should only add tooltips to HTMLYou should only add tooltips to HTML elements that are traditionally keyboard-focusable and interactive (such as links or form controls). Although arbitrary HTML elements (such as <span>s) can be made focusable by adding the tabindex= attribute, this will add potentially annoying and confusing tab stops on non-interactive elements for keyboard users. In addition, most assistive technologies currently do not announce the tooltip in this situation.';
  showExpandedView = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private projectService: ProjectService,
    private userService: UserService,
    private notifierToast: NotifierToastService,
    public cookieService: CookieService,
    public proData: ProjectDataService,
    private analytics: AnalyticsService
  ) {}

  next() {
    this.stepper.next();
  }
  openSearch(): void {
    this.searchbar.nativeElement.focus();
  }
  searchClose(): void {
    var el = document.getElementById('searchbar');
    el.addEventListener('keypress', function (e) {
      if (e.keyCode === 13 || e.which === 13 || e.key == 'Enter') {
        e.preventDefault();
        return false;
      }
    });
    this.searchText = '';
  }
  // onSubmit() {
  //   return false;
  // }

  ngOnInit(): void {
    localStorage.removeItem('editorVisited');
    localStorage.removeItem('proId');
    localStorage.removeItem('formData');
    this.cookieService.remove('projectDetails', {
      domain: environment.COOKIE_DOMAIN,
    });
    this.cookieService.remove('projectId', {
      domain: environment.COOKIE_DOMAIN,
    });
    document.addEventListener('keypress', function (e) {
      if (e.keyCode === 13 || e.which === 13) {
        e.preventDefault();
        return false;
      }
    });
    // var el = document.getElementById("searchbar");
    //   el?.addEventListener("keypress", function(e) {
    //     if (e.keyCode === 13 || e.which === 13 || e.key=="Enter") {
    //       console.log(e.keyCode)
    //       e.preventDefault();
    //       return false;
    //     }
    //   });
    // const parentElement = document.getElementById('stepper1');
    // if (parentElement != null) {
    //   this.stepper = new Stepper(document.querySelector('#stepper1'), {
    //     linear: false,
    //     animation: true,
    //   });
    // }

    this.loggedInUserName = this.userService.loggedInUser.email;

    this.projectService.getAllTemplates(1).subscribe((data: any) => {
      // Return only active template data
      const activeTemplateData = data.filter((i: any) => {
        if (i.currentState == 'ACTIVE') {
          return i;
        }
      });
      this.allTemplate = activeTemplateData;
      this.allTemplate.forEach((element: any) => {
        const x = element.platform;
        let temp: any[] = ['AWS', 'AZURE', 'GCP', 'OCI'];
        if (element.platform.toUpperCase() in this.platformArray) {
          this.platformArray[element.platform.toUpperCase()] += 1;
        } else {
          if (temp.includes(element.platform.toUpperCase())) {
            Object.assign(this.platformArray, {
              [element.platform.toUpperCase()]: 1,
            });
          } else {
            if ('Multi-cloud'.toUpperCase() in this.platformArray) {
              this.platformArray['Multi-cloud'.toUpperCase()] += 1;
            } else {
              Object.assign(this.platformArray, {
                ['Multi-cloud'.toUpperCase()]: 1,
              });
            }
          }
        }
      });
      this.apiLoading = false;
    });
  }

  showSuccessNotification(data: any): void {
    this.notifierToast.showNotification('success', data);
  }

  showErrorNotification(data: any): void {
    this.notifierToast.showNotification('error', data);
  }

  userLogout(): void {
    this.userService.logout();
    this.router.navigate(['/auth/login']);
  }
  // selectDomainNext(projectId: any, projectLabel: any) {
  //   this.apiLoading = true;
  //   this.projectService
  //     .getDomainsbyProjectType(projectId)
  //     .subscribe((data: any) => {
  //       this.allDomains = data;
  //       if (this.allDomains.length > 0) {
  //         this.domainPresent = true;
  //       }
  //       // this.selectedProjectType = projectId;
  //       // this.selectedProjectTypeLabel = projectLabel;
  //       this.stepper.next();
  //       // this.firstStepCompleted = true;
  //       this.apiLoading = false;
  //     });
  // }

  selectTemplateNext(): void {
    this.apiLoading = true;
    this.stepper.next();
    this.apiLoading = false;
  }

  // templateSelectedNext(
  //   templateId: any,
  //   architectureDiagram: any,
  //   deploymentDiagram: any,
  //   templateLabel: any
  // ): void {
  //   this.selectedArchDiagram = architectureDiagram;
  //   this.deploymentDiagram = deploymentDiagram;
  //   this.selectedTemplate = templateId;
  //   this.selectedTemplateLabel = templateLabel;
  //   this.firstStepCompleted = true;
  //   this.stepper.next();
  // }

  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }

  scratch() {
    let param = {};
    param[analyticsConst.PARAMS.TIME] = new Date();
    param[analyticsConst.PARAMS.USERNAME] = this.loggedInUserName;
    this.analytics.logEvent(
      analyticsConst.EVENTS.ADMIN_CREATE_PROJECT_FROM_SCRATCH,
      param
    );
    this.cookieService.put('loggedInUser', this.loggedInUserName, {
      domain: environment.COOKIE_DOMAIN,
    });
    localStorage.removeItem('editorVisited');
    this.proData.saveSelectedTemp({});
    this.router.navigate(['/projects/create/basic']);
  }

  selectedTemplateData(eve: any) {
    const tempId = eve;
    const tempData = this.allTemplate.find(({ id }) => id === tempId);
    localStorage.removeItem('editorVisited');
    this.proData.saveSelectedTemp(tempData);
    let param = {};
    param[analyticsConst.PARAMS.TIME] = new Date();
    param[analyticsConst.PARAMS.SELECT_TEMPLATE_NAME] = tempData.label;
    param[analyticsConst.PARAMS.SELECT_TEMPLATE_ID] = tempData.id;
    this.analytics.logEvent(
      analyticsConst.EVENTS.ADMIN_SELECTED_TEMPLATE,
      param
    );
    this.router.navigate(['/projects/create/basic']);
  }
}
