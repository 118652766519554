import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  AwsSigV4Service,
  ISignedUrlBodyParams,
} from 'src/app/shared-services/auth/aws-sign-v4.service';
import { UploadServiceService } from '../shared-services/upload-service.service';
import { TemplateInfoService } from '../shared-services/template-info.service';
import { ICollapseStatus } from '../portal/side-nav-container/side-nav-container.utils';
import { canComponentLeave } from 'src/app/shared-services/unsaved-changes.guard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/shared-component/confirmation-dialog/confirmation-dialog.component';
import { Observable, Subject } from 'rxjs';
import { analyticsConst } from '../shared-services/analytics-constants';
import { AnalyticsService } from '../shared-services/analytics.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit, canComponentLeave {
  @ViewChild('f') templateForm: NgForm;

  componentList: any = [];
  item: any;
  selectedComponent: any;
  logoKey: any;
  s3Url = environment.KLOUDJET_S3_URL;
  archKey: any;
  deployKey: any;
  apiLoading = false;
  showError: string = '';
  isTooBig = false;
  isUploading = false;
  isMismatch = false;
  inputFileName: string = '';
  // techStatus: string = 'INVALID';

  // apiLoader=true;
  showExpandedView = false;
  folderName = 'diagram/';

  constructor(
    private router: Router,
    private http: HttpClient,
    private upload: UploadServiceService,
    private templateInfoService: TemplateInfoService,
    private sigv4Service: AwsSigV4Service,
    private modalService: NgbModal,
    private analytics: AnalyticsService
  ) {
    this.GetTemplateList();
  }

  split = [];
  firstStepCompleted = false;
  secondStepCompleted = false;
  thirdStepCompleted = false;
  fourthStepCompleted = false;
  name = 'Angular';
  extension: string;
  templateId: string;
  tempName: string;
  slug = '';
  desc: string;
  platform: string;
  logo: string;
  architectureDiagram: string;
  deploymentDiagram: string;
  fileToUpload: any;
  hideme: true;
  technologyStack: any = [];
  archDiagramPreview: string;
  deploymentDiagramPreview: string;
  tagItems: any = [];
  finalComponentRule: any = [];
  finalUniqueComponentRule: any = [];
  technology = '';
  items = [];
  rulesPreview: any = [];
  inboundCompRule: any = [];
  outboundCompRule: any = [];
  private stepper: Stepper;

  ngOnInit() {
    this.stepper = new Stepper(document.querySelector('#stepper2'), {
      linear: false,
      animation: true,
    });
    // console.log(' logokey:' + this.logoKey);
  }

  canLeave(): Observable<boolean> | boolean {
    if (this.templateForm && this.templateForm.dirty) {
      return this.openModal();
      // return window.confirm("You have unsaved changes. Are you sure, you still want to leave??");
    }
    return true;
  }

  openModal() {
    const subject = new Subject<boolean>();
    const modal = this.modalService.open(ConfirmationDialogComponent, {
      centered: true,
      scrollable: true,
      windowClass: 'final-confirm',
      backdrop: true,
    });
    modal.componentInstance.subject = subject;

    return subject.asObservable();
    // return false;
  }

  checkFormStatus() {
    if (this.firstStepCompleted) {
      if (this.templateForm.dirty) {
        this.firstStepCompleted = false;
        this.secondStepCompleted = false;
        this.thirdStepCompleted = false;
        this.fourthStepCompleted = false;
      }
    }
  }
  onSelected($event: any, c) {
    // console.log(this.item);
    // console.log('====');
    // console.log(c);
    // console.log('====');
  }
  // get template list
  GetTemplateList() {
    this.http
      .get(environment.TEMPLATE_LIST)
      .subscribe((data) => (this.tagItems = data));
    // console.log(this.tagItems);
  }

  addTechnology(data: any): void {
    this.technology = data;
    // console.log(data.status);
    // console.log(this.technology, data);
  }

  addlist(p) {
    this.componentList = p;
    // console.log('comp list :', this.componentList);
  }

  // add new components tabs on stepper 2
  add() {
    const row = document.createElement('div-container');
    let count = 1;
    count++;
    row.className = 'row' + '-' + count;
    row.innerHTML = ` <app-tabs></app-tabs>`;
    document.querySelector('.accordion-item').appendChild(row);
  }
  // generate slug for template name
  generateSlug(event) {
    const slugi = event.target.value;
    this.slug = slugi.toLowerCase().replace(/ /g, '-');
    //  this.contactForm.controls["country"].setValue("1");
  }
  // next stepper
  next() {
    this.stepper.next();
  }
  // previous stepper
  stepBack() {
    this.stepper.previous();
    // console.log('hii');
  }

  // Listen to emitter to update component rule
  fetchRules(rules) {
    // console.log('Print length', rules.length);
    // console.log('Print rule', rules);

    if (rules.length > 0) {
      rules.forEach((element) => {
        if (element.ruleType == 'Inbound') {
          this.inboundCompRule.push(element);
        } else if (element.ruleType == 'Outbound') {
          this.outboundCompRule.push(element);
        }
        this.finalComponentRule.push(element);
      });
    }
    // console.log('Print added rules', this.finalComponentRule);
  }

  // Function called on next button at stepper 2
  addComponentList() {
    if (this.componentList.length == 0 || this.componentList == undefined) {
      return;
    }

    this.apiLoading = true;
    // Filter the list of new components to be created
    const newComponent = this.componentList.filter(
      (i) => i.isNew == true && !i.hasOwnProperty('id')
    );
    const reqData = {
      data: newComponent,
    };

    // API call to create new component in bulk
    this.templateInfoService.addComponents(reqData).subscribe(
      (data: any) => {
        // Map ids for new component in complete list
        this.componentList.map((i) => {
          if (i.isNew && !i.hasOwnProperty('id')) {
            // console.log(data);
            i.id = data.body.find((x) => x.folderName == i.folderName).id;
            i.ingress = [];
            i.outgres = [];
            return true;
          }
          return true;
        });
        // console.log('componentList: ' + this.componentList);

        const initialValue = {};

        const componentArray = JSON.parse(JSON.stringify(this.componentList));

        // Modify component rule
        for (let i = 0; i < componentArray.length; i++) {
          componentArray[i].outgres = i == 0 ? [] : [componentArray[i - 1].id];
          componentArray[i].ingress =
            i < componentArray.length - 1 ? [componentArray[i + 1].id] : [];
        }

        // console.log('Print component Array with actual rule', componentArray);

        // Create object for componentMap from component list array
        const componentMap = componentArray.reduce((obj, item) => {
          return {
            ...obj,
            [item['id']]: {
              ingress: item.ingress,
              outgres: item.outgres,
              name: item.label,
            },
          };
        }, initialValue);

        // console.log(componentMap);

        const updateTemplateData = {
          id: this.templateId,
          componentMap: JSON.stringify(componentMap),
        };

        // Call API to update the template component map
        this.templateInfoService.updateTemplate(updateTemplateData).subscribe(
          (data: any) => {
            // console.log('===============');
            // console.log(data);
            // console.log('===============');
            this.apiLoading = false;
            this.secondStepCompleted = true;
            this.stepper.next();
          },
          (err: Error) => {
            // console.log(err.message);
          }
        );
      },
      (err: Error) => {
        console.log(err.message);
      }
    );
  }

  // Method to update template current status
  submitForReview() {
    const templateObj = {
      id: this.templateId,
      logo: this.s3Url + this.logoKey,
      architectureDiagram: this.archKey ? this.s3Url + this.archKey : null,
      deploymentDiagram: this.deployKey ? this.s3Url + this.deployKey : null,
      currentState: 'PENDING_REVIEW',
    };

    // Call API to update the template status to review
    this.templateInfoService.updateTemplate(templateObj).subscribe(
      (data: any) => {
        // console.log('Updated template: ', data);
        this.apiLoading = false;
        let param = {};
        param[analyticsConst.PARAMS.TIME] = new Date();
        param[analyticsConst.PARAMS.SUBMIT_TEMPLATE_ID] = this.templateId;
        this.analytics.logEvent(
          analyticsConst.EVENTS.ADMIN_TEMPLATE_SUBMIT_REVIEW,
          param
        );
        this.router.navigate(['/portal/dashboard']);
      },
      (err: Error) => {
        console.log(err.message);
        let param = {};
        param[analyticsConst.PARAMS.TIME] = new Date();
        param[analyticsConst.PARAMS.SUBMIT_TEMPLATE_ID] = this.templateId;
        this.analytics.logEvent(
          analyticsConst.EVENTS.ADMIN_TEMPLATE_SUBMIT_REVIEW_FAIL,
          param
        );
      }
    );
  }

  submitForDraft() {
    const templateObj = {
      id: this.templateId,
      logo: this.s3Url + this.logoKey,
      architectureDiagram: this.archKey ? this.s3Url + this.archKey : null,
      deploymentDiagram: this.deployKey ? this.s3Url + this.deployKey : null,
      currentState: 'DRAFTED',
    };

    // Call API to update the template status to review
    this.templateInfoService.updateTemplate(templateObj).subscribe(
      (data: any) => {
        // console.log('Updated template: ', data);
        this.apiLoading = false;
        let param = {};
        param[analyticsConst.PARAMS.TIME] = new Date();
        param[analyticsConst.PARAMS.SUBMIT_TEMPLATE_ID] = this.templateId;
        this.analytics.logEvent(
          analyticsConst.EVENTS.ADMIN_TEMPLATE_SUBMIT_DRAFT,
          param
        );
        this.router.navigate(['/portal/dashboard']);
      },
      (err: Error) => {
        let param = {};
        param[analyticsConst.PARAMS.TIME] = new Date();
        param[analyticsConst.PARAMS.SUBMIT_TEMPLATE_ID] = this.templateId;
        this.analytics.logEvent(
          analyticsConst.EVENTS.ADMIN_TEMPLATE_SUBMIT_DRAFT_FAIL,
          param
        );
        console.log(err.message);
      }
    );
  }

  // Function updates template info on next button at stepper 1
  addTempInfo(f: NgForm) {
    if (f.invalid || this.logoKey == undefined) {
      alert('invalid is form');
      return;
    }
    // tempName,desc,logo
    // let slugg=this.slug;
    // this.stepper.next();
    if (f.valid && f.submitted && this.logoKey != undefined) {
      // console.log('Submitting Template Form');
      this.apiLoading = true;
      this.templateInfoService
        .addTempInfo(
          this.tempName,
          this.slug,
          this.desc,
          this.logo,
          this.technology,
          this.platform
        )
        .subscribe(
          (data: any) => {
            this.templateId = data.id;
            // console.log('Data Added:',data.id);
            // this.showSuccessNotification('Template Created Successfully')
            // this.router.navigate(['/portal/dashboard']);
            this.apiLoading = false;
            this.firstStepCompleted = true;
            this.stepper.next();
          },
          (err: Error) => {
            // this.showErrorNotification(err.message);
            this.apiLoading = false;
            console.log(err.message);
          }
        );
    }
  }

  // Add component rules method
  addComponentRules() {
    this.apiLoading = true;
    const uniques = {};
    for (const t of this.finalComponentRule) {
      const key = t.componentId + '$' + t.ruleType + '$' + t.allowedComponentId;
      // Or whatever string criteria you want, which can be generified as Object.keys(t).join("$")
      uniques[key] = t; // Last duplicate wins
    }
    const uniqueThing = Object.values(uniques);

    this.finalUniqueComponentRule = uniqueThing;

    this.rulesPreview = JSON.parse(JSON.stringify(this.finalComponentRule));

    const componentsPreview = this.rulesPreview.reduce(
      (components, item) => ({
        ...components,
        [item.componentName]: [...(components[item.componentName] || []), item],
      }),
      {}
    );
    const rulesArray = [];

    // Prepare array to bind in rule preview step 5
    for (const component in componentsPreview) {
      const inboundArray = componentsPreview[component]
        .filter((i) => i.ruleType == 'Inbound')
        .map((i) => {
          return i.allowedComponentName;
        });
      const outboundArray = componentsPreview[component]
        .filter((i) => i.ruleType == 'Outbound')
        .map((i) => {
          return i.allowedComponentName;
        });
      rulesArray.push({
        compName: component,
        inbound: [...new Set(inboundArray)],
        outbound: [...new Set(outboundArray)],
      });
    }
    // added logic for inbound/outbound rules addition in summary
    if (this.componentList.length > rulesArray.length) {
      if (rulesArray.length > 0) {
        const unmatchedObj = this.componentList.filter(
          (compItem) =>
            !rulesArray.some((ruleItem) => compItem.label === ruleItem.compName)
        );
        // console.log(' unmatched obj :' + unmatchedObj.length);

        for (let item of unmatchedObj) {
          rulesArray.push({ compName: item.label });
        }
        this.rulesPreview = rulesArray;
      } else {
        for (let compItem of this.componentList) {
          this.rulesPreview.push({ compName: compItem.label });
        }
      }
    } else {
      this.rulesPreview = rulesArray;
    }
    // end logic for inbound/outbound rules

    // API call to create new component in bulk
    this.templateInfoService.createBulkComponentRule(uniqueThing).subscribe(
      (data: any) => {
        // console.log(data);
        this.apiLoading = false;
        this.thirdStepCompleted = true;
        this.stepper.next();
      },
      (err: Error) => {
        console.log(err.message);
        this.apiLoading = false;
      }
    );
  }

  // Function to add diagrams on next button at stepper 4
  addDiagrams() {
    this.apiLoading = true;
    const updateTemplateData = {
      id: this.templateId,
      architectureDiagram: this.architectureDiagram,
      deploymentDiagram: this.deploymentDiagram,
    };

    // Call API to update the template component map
    this.templateInfoService.updateTemplate(updateTemplateData).subscribe(
      (data: any) => {
        // console.log('===============');
        // console.log(data);
        // console.log('===============');
        this.apiLoading = false;
        this.fourthStepCompleted = true;
        this.stepper.next();
      },
      (err: Error) => {
        this.apiLoading = false;
        console.log(err.message);
      }
    );
  }

  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }

  uploadFiles(fileInputEvent, fileCategory: string, fileSize: string) {
    // console.log(fileCategory);
    switch (fileCategory) {
      case 'logo':
        this.showError = 'logo';
        break;
      case 'architectureDiagram':
        this.showError = 'architectureDiagram';
        break;
      case 'deploymentDiagram':
        this.showError = 'deploymentDiagram';
        break;
      default:
        break;
    }
    const uploadedFile: File = fileInputEvent.target.files[0];
    const filePath = fileInputEvent.target.files[0].name;
    // console.log(uploadedFile);
    // console.log(filePath);
    if (fileInputEvent.target.files[0].size > fileSize) {
      this.isUploading = false;
      this.isTooBig = true;
      return;
    }
    this.isTooBig = false;
    this.split = filePath.split('.', 10);
    const newsplit = [...new Set(this.split)];
    this.extension = filePath.split('.').pop();
    // console.log(newsplit);
    // console.log(this.split);
    // Allowing file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(filePath)) {
      this.isUploading = false;
      this.isMismatch = true;
      return;
    } else if (uploadedFile) {
      this.isUploading = true;
      this.isMismatch = false;
      var name = newsplit[0] + '';
      for (var i = 1; i < newsplit.length; i++) {
        name = name + '.' + newsplit[i];
        // console.log(name);
      }
      if (name.length > 100) {
        name = name.substring(0, 30) + '...' + this.extension;
      }
      // console.log('File Provided!');
      // this.inputFileName = fileInputEvent.target.files[0].name;
      this.inputFileName = name;
      const signedUrlBodyParams: ISignedUrlBodyParams = {
        filePath: this.folderName + uploadedFile.name,
        fileType: uploadedFile.type,
      };
      this.sigv4Service.generateSignedUrl(signedUrlBodyParams).subscribe(
        (generatedUrl) => {
          this.upload
            .uploadFileToS3(generatedUrl, uploadedFile, uploadedFile.type)
            .subscribe(
              () => {
                switch (fileCategory) {
                  case 'logo':
                    this.logoKey = signedUrlBodyParams.filePath;
                    this.logo = generatedUrl.split('?')[0];
                    break;
                  case 'architectureDiagram':
                    this.archKey = signedUrlBodyParams.filePath;
                    this.architectureDiagram = generatedUrl.split('?')[0];
                    this.archDiagramPreview = this.architectureDiagram;
                    break;
                  case 'deploymentDiagram':
                    this.deployKey = signedUrlBodyParams.filePath;
                    this.deploymentDiagram = generatedUrl.split('?')[0];
                    this.deploymentDiagramPreview = this.deploymentDiagram;
                    break;
                  default:
                    break;
                }
                this.isUploading = false;
                // console.log('File url - :', this.logo);
              },
              (err) => {
                console.log('upload err', err);
                this.isUploading = false;
              }
            );
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
}
