<div>
  <div class="sidenav sidenav-bg-color">
    <a routerLink="/portal/dashboard">
      <div
        [ngClass]="{
          'nav-link-div': true,
          'currently-selected-nav-link':
            selectedElementId === 'dashboard' ||
            currentSplittedRoute === 'dashboard'
        }"
        id="dashboard"
        (click)="setCurrentlySelectedDivId('dashboard')"
      >
        <div *ngIf="!isCollapsed; else dashboardDiv" class="nav-link-inner-div">
          <img class="nav-icon" src="../../assets/icons/dashboard.svg" />
          <p class="nav-link-text">{{ "common.dashboard" | translate }}</p>
        </div>
        <ng-template #dashboardDiv>
          <div class="nav-link-inner-div-collapsed">
            <img class="nav-icon" src="../../assets/icons/dashboard.svg" />
          </div>
        </ng-template>
      </div>
    </a>
    <!-- TipsterBot Feature -->
    <!-- <div class="sb-sidenav-menu-heading ">Tipster</div>
                <div class="dropdown  sb-sidenav-dark " id="sidenavsubmenu">
                     <a class="nav-link"> <div class="sb-nav-link-icon sb-sidenav-dark"><i class="fas fa-plus"></i></div> New Consultation
                            <span class="caret"></span>
                  </a>
                       
                    <div id="submenu"class="dropdown-menu sb-sidenav-dark">
                       <a class="nav-link sb-sidenav-dark" routerLink="/portal/assessmentbot">
                    
                        <div class="sb-nav-link-icon sb-sidenav-dark">&nbsp;&nbsp;&nbsp;<i class="fas fa-minus"></i></div>
                         Tipster Bot
                    </a>
                    
                        <a class="nav-link sb-sidenav-dark" data-parent="#menu3sub2" routerLink="/portal/survey">
                            <div class="sb-nav-link-icon sb-sidenav-dark">&nbsp;&nbsp;&nbsp;<i class="fas fa-minus"></i></div>
                            Consultancy Form
                        </a>
                      
                     
                    </div>
                </div>
               
              
                <a class="nav-link" routerLink="/portal/assessmentreports">
                    <div class="sb-nav-link-icon"><i class="fas fa-plus"></i></div>
                    My Consultancy Reports
                </a> -->
    <a routerLink="/projects">
      <div
        [ngClass]="{
          'nav-link-div': true,
          'currently-selected-nav-link':
            selectedElementId === 'project' ||
            currentSplittedRoute === 'project'
        }"
        id="project"
        (click)="setCurrentlySelectedDivId('project')"
      >
        <div *ngIf="!isCollapsed; else projectDiv" class="nav-link-inner-div">
          <img class="nav-icon" src="../../assets/icons/create-project.svg" />
          <p class="nav-link-text">Projects</p>
        </div>
        <ng-template #projectDiv>
          <div class="nav-link-inner-div-collapsed">
            <img class="nav-icon" src="../../assets/icons/create-project.svg" />
          </div>
        </ng-template>
      </div>
    </a>
    <!-- <a routerLink="/portal/template">
      <div
        [ngClass]="{
          'nav-link-div': true,
          'currently-selected-nav-link':
            selectedElementId === 'template' ||
            currentSplittedRoute === 'template'
        }"
        id="template"
        (click)="setCurrentlySelectedDivId('template')"
      >
        <div *ngIf="!isCollapsed; else templateDiv" class="nav-link-inner-div">
          <img class="nav-icon" src="../../assets/icons/create-template.svg" />
          <p class="nav-link-text">
            {{ "common.create_template" | translate }}
          </p>
        </div>
        <ng-template #templateDiv>
          <div class="nav-link-inner-div-collapsed">
            <img
              class="nav-icon"
              src="../../assets/icons/create-template.svg"
            />
          </div>
        </ng-template>
      </div>
    </a> -->
    <a class="disable-pointer-event">
      <div
        [ngClass]="{
          'nav-link-div': true,
          'currently-selected-nav-link': selectedElementId === 'template'
        }"
        id="template"
        (click)="setCurrentlySelectedDivId('template')"
      >
        <div *ngIf="!isCollapsed; else templateDiv" class="nav-link-inner-div">
          <img
            class="nav-icon grey-svg"
            src="../../assets/icons/create-template-disable.svg"
          />
          <p class="nav-link-text disabled-tab">
            Custom {{ "common.template" | translate }}s
            <sup class="superscript">
              {{ "common.coming_soon" | translate }}</sup
            >
          </p>
        </div>
        <ng-template #templateDiv>
          <div class="nav-link-inner-div-collapsed">
            <img
              class="nav-icon grey-svg"
              src="../../assets/icons/create-template-disable.svg"
            />
          </div>
        </ng-template>
      </div>
    </a>
    <a class="disable-pointer-event">
      <div
        [ngClass]="{
          'nav-link-div': true,
          'currently-selected-nav-link': selectedElementId === 'monitor'
        }"
        id="monitor"
        (click)="setCurrentlySelectedDivId('monitor')"
      >
        <div *ngIf="!isCollapsed; else monitorDiv" class="nav-link-inner-div">
          <img class="nav-icon" src="../../assets/icons/monitor.svg" />
          <p class="nav-link-text disabled-tab">
            {{ "common.monitor" | translate }}
            <sup class="superscript">
              {{ "common.coming_soon" | translate }}</sup
            >
          </p>
        </div>
        <ng-template #monitorDiv>
          <div class="nav-link-inner-div-collapsed">
            <img class="nav-icon" src="../../assets/icons/monitor.svg" />
          </div>
        </ng-template>
      </div>
    </a>
    <div
      [ngClass]="{
        'sb-sidenav-footer fixed-bottom sidenav-bg-color footer-div-width': true,
        'footer-collapsed-height': isCollapsed
      }"
    >
      <a routerLink="/portal/help-center">
        <div
          [ngClass]="{
            'nav-link-div': true,
            'currently-selected-nav-link':
              selectedElementId === 'help' ||
              currentSplittedRoute === 'help-center'
          }"
          id="help"
          (click)="setCurrentlySelectedDivId('help')"
        >
          <div *ngIf="!isCollapsed; else helpDiv" class="nav-link-inner-div">
            <img class="nav-icon" src="../../assets/icons/help.svg" />
            <p class="nav-link-text">{{ "common.help_center" | translate }}</p>
          </div>
          <ng-template #helpDiv>
            <div class="nav-link-inner-div-collapsed">
              <img class="nav-icon" src="../../assets/icons/help.svg" />
            </div>
          </ng-template>
        </div>
      </a>
      <a class="disable-pointer-event">
        <div
          [ngClass]="{
            'nav-link-div': true,
            'currently-selected-nav-link': selectedElementId === 'settings'
          }"
          id="settings"
          (click)="setCurrentlySelectedDivId('settings')"
        >
          <div
            *ngIf="!isCollapsed; else settingsDiv"
            class="nav-link-inner-div"
          >
            <img class="nav-icon" src="../../assets/icons/settings.svg" />
            <p class="nav-link-text disabled-tab">
              {{ "common.settings" | translate
              }}<sup class="superscript">
                {{ "common.coming_soon" | translate }}</sup
              >
            </p>
          </div>
          <ng-template #settingsDiv>
            <div class="nav-link-inner-div-collapsed">
              <img class="nav-icon" src="../../assets/icons/settings.svg" />
            </div>
          </ng-template>
        </div>
      </a>
      <a (click)="userLogout()">
        <div
          [ngClass]="{
            'nav-link-div': true,
            'currently-selected-nav-link': selectedElementId === 'logout'
          }"
          id="logout"
          (click)="setCurrentlySelectedDivId('logout')"
        >
          <div *ngIf="!isCollapsed; else logoutDiv" class="nav-link-inner-div">
            <img class="nav-icon" src="../../assets/icons/logout.svg" />
            <p class="nav-link-text hand-cursor">
              {{ "common.logout" | translate }}
            </p>
          </div>
          <ng-template #logoutDiv>
            <div class="nav-link-inner-div-collapsed">
              <img class="nav-icon" src="../../assets/icons/logout.svg" />
            </div>
          </ng-template>
        </div>
      </a>
      <hr />
      <div [ngClass]="{ 'footer-margin': !isCollapsed }">
        <div
          [ngClass]="{
            'sidenav-footer-version-text': !isCollapsed,
            'sidenav-footer-version-text-collapsed': isCollapsed
          }"
        >
          {{ "common.version" | translate }}
        </div>
        <div *ngIf="!isCollapsed" class="sidenav-footer-copyright-text">
          © {{ currentYear }} {{ "common.copyright_long" | translate }}
        </div>
        <div
          *ngIf="isCollapsed"
          class="sidenav-footer-copyright-collapsed-text"
        >
          © {{ currentYear }} {{ "common.copyright_short" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
