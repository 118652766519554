import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICollapseStatus } from '../../portal/side-nav-container/side-nav-container.utils';
import { ProjectDataService } from '../project-services/project-data.service';
import { ProjectService } from '../project-services/project.service';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-project-config',
  templateUrl: './project-config.component.html',
  styleUrls: ['./project-config.component.scss'],
})
export class ProjectConfigComponent implements OnInit {
  showExpandedView: boolean;
  checkCount: number = 0;
  checkModelValues = {};
  tagModelValues = {};
  checkboxRequired: boolean = false;
  tagRequired: boolean = false;
  componentMap: any;
  region: any;
  memory: any;
  apiLoading = false;
  configLoading = false;
  objData = {};
  Object = Object;
  proid: string;
  env: any;
  formData = {};
  config = {};
  defaultData = {};
  apisubmit = false;
  Secret = {};
  textModel: any = {};
  listRequiredInputEmpty: boolean = false;
  constructor(
    private proService: ProjectService,
    private proData: ProjectDataService,
    public cookieService: CookieService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) { }

  async ngOnInit(): Promise<void> {
    this.apiLoading = true;
    this.configLoading = true;
    if (!localStorage.getItem('proId')) {
      localStorage.setItem('proId', this.cookieService.get('projectId'));
    }
    await this.proService
      .fetchPro(localStorage.getItem('proId'))
      .subscribe((data: any) => {
        this.proData.saveSelectedTemp(data.project);
        let temp = {
          name: data.project.name,
          env_array: JSON.parse(data.project.allProjectComponents[0]['env']),
        };
        this.env = JSON.parse(data.project.allProjectComponents[0]['env']);
        this.proData.saveProjectEnv(temp);
        this.proData.saveProject(data.project);
        localStorage.setItem(
          'projectComponent',
          JSON.stringify(data.project.projectComponents)
        );
        this.proid = localStorage.getItem('proId');
        const tempData = this.proData.returnProject();
        this.componentMap = JSON.parse(tempData?.componentMap);
        Object.keys(this.componentMap).forEach((element) => {
          this.formData[element] = {};
          this.checkModelValues[element] = {};
          this.tagModelValues[element] = {};
          this.objData[element] = {};
          this.formData[element]['usage'] =
            data.project.allProjectComponents.find(
              (x) => x.componentId == element
            ).displayName;
          this.formData[element]['repo_name'] =
            data.project.allProjectComponents.find(
              (x) => x.componentId == element
            ).repoName;
          this.defaultData[element] = {};
          this.Secret[element] = {};
        });
        this.proService
          .getConfigByCompId(Object.keys(this.componentMap))
          .subscribe((data: any) => {
            let object = data.reduce(function (r, a) {
              r[a.componentId] = r[a.componentId] || [];
              r[a.componentId].push(a);
              return r;
            }, Object.create(null));
            for (const key in object) {
              if (Object.prototype.hasOwnProperty.call(object, key)) {
                const element = object[key];
                if (element.length >= 2) {
                  this.config[key] = element.sort((a, b) =>
                    a.priority > b.priority ? 1 : -1
                  );
                } else {
                  this.config[key] = element;
                }
                element.forEach((ele) => {
                  if (ele.inputType == 'password_secret') {
                    this.Secret[key][ele.configName] = '';
                  } else if (
                    ele.inputType == 'list' ||
                    ele.inputType == 'list_of_checkbox'
                  ) {
                    if (ele.isRequired && ele.inputType == 'list_of_checkbox') {
                      console.log(ele);
                      this.checkCount++;
                      this.checkModelValues[ele.componentId][ele.configName] =
                        {};
                      this.checkModelValues[ele.componentId][ele.configName] =
                        ele.defaultValue.value;
                    }
                    if (ele.defaultValue['value'] != null) {
                      // console.log(ele.configName)
                      // console.log(ele.defaultValue.value)
                      // console.log("defaultValue not null")
                      this.formData[key][ele.configName] =
                        ele.defaultValue['value'];
                    } else {
                      this.formData[key][ele.configName] = [];
                    }
                    this.defaultData[key][ele.configName] =
                      ele.defaultValue['value'];
                  } else if (ele.inputType == 'list_object_expression') {
                    if (
                      ele.isRequired &&
                      (JSON.stringify(ele.defaultValue['value']) == '[]' ||
                        ele.defaultValue['value'] === null)
                    ) {
                      // console.log("1")
                      console.log(ele.defaultValue['value']);
                      this.formData[key][ele.configName] = [{}];
                      // localStorage.setItem('formData', JSON.stringify(this.formData));
                      console.log(this.formData[key][ele.configName]);
                    } else if (
                      !ele.isRequired &&
                      ele.defaultValue['value'] == null
                    ) {
                      // console.log("3")
                      console.log(ele.defaultValue['value']);
                      this.formData[key][ele.configName] = [];
                      // localStorage.setItem('formData', JSON.stringify(this.formData));
                    } else {
                      // console.log("4")
                      console.log(ele.defaultValue['value']);
                      this.formData[key][ele.configName] =
                        ele.defaultValue['value'];
                      // localStorage.setItem('formData', JSON.stringify(this.formData));
                    }
                    // let defaultValue = ele.defaultValue['value']
                    // if(JSON.stringify(ele.defaultValue['value']) == '[]'){
                    //   console.log("yes")
                    //   console.log(ele.defaultValue['value'])
                    // }
                    // if(ele.defaultValue['value'] == null){
                    //   console.log("yessss")
                    //   console.log(ele.defaultValue['value'])
                    // }
                    // if(ele.isRequired && (defaultValue==null || ele.defaultValue.value === '[]'))
                    // if(ele.defaultValue['value']!=null)
                    // {
                    //   // if(ele.isRequired && !ele.defaultValue['value'].length){
                    //   //   this.formData[key][ele.configName].push({});
                    //   // }
                    //   this.formData[key][ele.configName] =
                    //   ele.defaultValue['value'];
                    //   // console.log("here")
                    //   // console.log(this.formData[key][ele.configName])
                    // }else{
                    //   this.formData[key][ele.configName]=[]
                    // }
                    this.defaultData[key][ele.configName] =
                      ele.defaultValue['value'];
                  } else if (ele.inputType == 'object') {
                    this.formData[key][ele.configName] = {};
                    if (ele.isRequired) {
                      // this.tagRequired = true;
                      console.log('true');
                    }
                    this.tagModelValues[ele.componentId][ele.configName] = ele;
                    console.log(this.tagModelValues, ele);

                    this.objData[key][ele.configName] = this.fb.group({
                      kvtags: this.fb.array([]),
                    });
                    if (localStorage.getItem('formData')) {
                      for (const objKey in JSON.parse(
                        localStorage.getItem('formData')
                      )[key][ele.configName]) {
                        if (
                          Object.prototype.hasOwnProperty.call(
                            JSON.parse(localStorage.getItem('formData'))[key][
                            ele.configName
                            ],
                            objKey
                          )
                        ) {
                          const element = JSON.parse(
                            localStorage.getItem('formData')
                          )[key][ele.configName][objKey];
                          console.log(element);
                          this.kvtags(key, ele.configName).push(
                            this.fb.group({
                              keytag: objKey,
                              valuetag: element,
                            })
                          );
                        }
                      }
                    }
                    this.defaultData[key][ele.configName] =
                      ele.defaultValue['value'];
                  } else if (ele.inputType == 'object_expression') {
                    if (ele.defaultValue['value'] != null) {
                      this.formData[key][ele.configName] =
                        ele.defaultValue['value'];
                    } else {
                      let temp = {};
                      // ele.children.forEach(element => {
                      //   temp[element.configName]=''
                      // });
                      this.formData[key][ele.configName] = temp;
                      this.defaultData[key][ele.configName] =
                        ele.defaultValue['value'];
                    }
                  } else {
                    this.defaultData[key][ele.configName] =
                      ele.defaultValue['value'];
                    if (ele.defaultValue['value'] != null) {
                      this.formData[key][ele.configName] =
                        ele.defaultValue['value'];
                    } else {
                      this.formData[key][ele.configName] = '';
                    }
                  }
                });
              }
            }
            this.configLoading = false;
            if (localStorage.getItem('formData')) {
              this.formData = JSON.parse(localStorage.getItem('formData'));
              // this.textModel = this.formData
            } else {
              // console.log("Hi")
              localStorage.setItem('formData', JSON.stringify(this.formData));
            }
          });
        this.apiLoading = false;
      });
  }

  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }

  saveData(configName, event, itemkey) {
    this.formData[itemkey][configName] = event.target.value;
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  saveDataListCheck(configName, event, itemkey, data) {
    if (this.formData[itemkey][configName] == '') {
      this.formData[itemkey][configName] = [];
    }
    if (event.target.checked == true) {
      this.formData[itemkey][configName].push(event.target.value);
      console.log('checkform', this.formData[itemkey][configName]);
      this.checkBoxRequiredTrue();
    } else {
      const index = this.formData[itemkey][configName].findIndex(
        (i) => i === event.target.value
      );
      if (index >= 0) {
        this.formData[itemkey][configName].splice(index, 1);
        console.log('uncheckform', this.formData[itemkey][configName]);
        this.checkBoxRequiredTrue();
      }
    }
  }

  saveDataObjListCheck(configName, childConfig, event, itemkey, data) {
    if (this.formData[itemkey][configName][childConfig] == '') {
      this.formData[itemkey][configName][childConfig] = [];
    }
    if (event.target.checked == true) {
      this.formData[itemkey][configName][childConfig].push(
        event.target.value
      );
      // this.checkModelValues[itemkey][configName].push(event.target.value);
      console.log(
        'checkform',
        this.formData[itemkey][configName][childConfig]
      );
      // this.objExpCheckBoxRequiredTrue();
    } else {
      const index = this.formData[itemkey][configName][
        childConfig
      ].findIndex((i) => i === event.target.value);
      if (index >= 0) {
        this.formData[itemkey][configName][childConfig].splice(index, 1);
        // this.checkModelValues[itemkey][configName].splice(index, 1);
        console.log(
          'uncheckform',
          this.formData[itemkey][configName][childConfig]
        );
        // this.objExpCheckBoxRequiredTrue();
      }
    }
  }

  saveDataList(configName, event, itemkey, i) {
    // console.log("configLoading in saveDataList",this.configLoading)
    this.formData[itemkey][configName][i] = event.target.value;
    if (
      this.formData[itemkey][configName][i].length >= 1 &&
      this.formData[itemkey][configName].length == i
    ) {
      this.formData[itemkey][configName][i + 1] = '';
    }
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  saveDataListExp(configName, event, itemkey, i, field) {
    this.formData[itemkey][configName][i][field] = event.target.value;
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }
  saveDataChildren(configName, event, itemkey, i, field) {
    this.formData[itemkey][configName][i][field] = event.target.value;
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }
  saveDataListChildren(
    configName,
    event,
    itemkey,
    parentIndex,
    childindex,
    field
  ) {
    // this.formData[itemkey][configName][i][field][c] = event.target.value;
    // console.log(this.formData);
    // localStorage.setItem('formData', JSON.stringify(this.formData));
    this.formData[itemkey][configName][parentIndex][field][childindex] =
      event.target.value;
    if (
      this.formData[itemkey][configName][parentIndex][field][childindex]
        .length >= 1 &&
      this.formData[itemkey][configName][parentIndex][field][childindex]
        .length == childindex
    ) {
      this.formData[itemkey][configName][parentIndex][field][childindex + 1] =
        '';
    }
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }
  addListChildren(key, configName, i, children, index) {
    this.formData[key][configName][i][children][index] = '';
    console.log(this.formData);
  }
  saveDataSecret(configName, event, itemkey) {
    this.Secret[itemkey][configName] = event.target.value;
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }
  saveDataObjectExp(configName, event, itemkey, key) {
    this.formData[itemkey][configName][key] = event.target.value;
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }
  saveDataRadio(configName, event, itemkey, value) {
    console.log(value);
    this.formData[itemkey][configName] = value;
    console.log(event);
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }
  saveDataListObjectListCheckbox(configName, childConfig, i, event, itemkey) {
    if (!this.formData[itemkey][configName][i][childConfig]) {
      this.formData[itemkey][configName][i][childConfig] = [];
    }
    if (event.target.checked == true) {
      this.formData[itemkey][configName][i][childConfig].push(
        event.target.value
      );
      // this.checkModelValues[itemkey][configName].push(event.target.value);
      console.log(
        'checkform',
        this.formData[itemkey][configName][i][childConfig]
      );
      // this.objExpCheckBoxRequiredTrue();
    } else {
      const index = this.formData[itemkey][configName][i][
        childConfig
      ].findIndex((i) => i === event.target.value);
      if (index >= 0) {
        this.formData[itemkey][configName][i][childConfig].splice(index, 1);
        // this.checkModelValues[itemkey][configName].splice(index, 1);
        console.log(
          'uncheckform',
          this.formData[itemkey][configName][childConfig]
        );
        // this.objExpCheckBoxRequiredTrue();
      }
    }
  }

  next() {
    this.apisubmit = true;
    let temp = this.defaultData;
    let repoData = {};
    let usageData = {};
    let finalData = {};
    for (const key in this.objData) {
      if (Object.prototype.hasOwnProperty.call(this.objData, key)) {
        for (const configData in this.objData[key]) {
          if (
            Object.prototype.hasOwnProperty.call(this.objData[key], configData)
          ) {
            if (this.objData[key][configData].value['kvtags'].length > 0) {
              let obj = {};
              for (const i in this.objData[key][configData].value['kvtags']) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    this.objData[key][configData].value['kvtags'],
                    i
                  )
                ) {
                  const element =
                    this.objData[key][configData].value['kvtags'][i];
                  obj[element.keytag] = element.valuetag;
                  this.formData[key][configData] = obj;
                }
              }
            }
          }
        }
      }
    }
    for (const key in this.formData) {
      if (Object.prototype.hasOwnProperty.call(this.formData, key)) {
        repoData[key] = this.formData[key]['repo_name'];
        usageData[key] = this.formData[key]['usage'];
        delete this.formData[key]['repo_name'];
        delete this.formData[key]['usage'];
        for (const configData in this.formData[key]) {
          if (
            Object.prototype.hasOwnProperty.call(this.formData[key], configData)
          ) {
            if (this.formData[key][configData] != null) {
              // if (this.formData[key][configData].length >= 1) {
              temp[key][configData] = this.formData[key][configData];
              // }

              // if (
              //   !this.formData[key][configData].length &&
              //   typeof this.formData[key][configData] == 'object'
              // ) {
              //   if (Object.keys(this.formData[key][configData]).length >= 1) {
              //     temp[key][configData] = this.formData[key][configData];
              //   }
              // }
              // else{
              //   temp[key][configData] = this.formData[key][configData];
              // }
              // temp[key][configData] = this.formData[key][configData];
            }
            // else{
            //   console.log("as")
            //   console.log(configData)
            //   console.log(this.formData[key][configData])
            // }
          }
        }
        finalData[key] = temp[key];
      }
    }
    let tData = {};
    for (const key in finalData) {
      if (Object.prototype.hasOwnProperty.call(finalData, key)) {
        const ele = finalData[key];
        tData[key] = {};
        this.env.forEach((element) => {
          tData[key][element.env] = finalData[key];
        });
      }
    }
    let pro = this.proData.returnProject();
    let keySecrets = {};
    for (const key in pro.projectComponents) {
      if (Object.prototype.hasOwnProperty.call(pro.projectComponents, key)) {
        const element = pro.projectComponents[key];
        keySecrets[element] = this.Secret[key];
      }
    }
    let dataSecret = {
      userId: JSON.parse(localStorage.getItem('loggedInUser')).id,
      projectId: localStorage.getItem('proId'),
      componentId: Object.values(pro.projectComponents),
      key: keySecrets,
      type: 'string',
      version: '2',
    };
    this.proService
      .saveConfig(
        JSON.parse(localStorage.getItem('loggedInUser')).id,
        localStorage.getItem('proId'),
        tData,
        JSON.parse(localStorage.getItem('projectComponent')),
        repoData,
        usageData
      )
      .subscribe((data) => {
        this.proService.saveSecret(dataSecret).subscribe((data) => {
          localStorage.removeItem('formData');
          this.apisubmit = false;
          this.router.navigate([`/projects/create/${this.proid}/summary`]);
        });
      });
    // this.router.navigate([`/projects/create/${this.proid}/summary`]);
  }

  // prev() {
  //   this.router.navigate([`/projects/create/${this.proid}/artifacts`]);
  // }

  kvtags(key, configName): UntypedFormArray {
    this.objData[key][configName].get('kvtags');
    // this.tagFieldCheck()
    for (const key in this.objData) {
      if (Object.prototype.hasOwnProperty.call(this.objData, key)) {
        for (const configData in this.objData[key]) {
          if (
            Object.prototype.hasOwnProperty.call(this.objData[key], configData)
          ) {
            if (this.objData[key][configData].value['kvtags'].length > 0) {
              let obj = {};
              for (const i in this.objData[key][configData].value['kvtags']) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    this.objData[key][configData].value['kvtags'],
                    i
                  )
                ) {
                  const element =
                    this.objData[key][configData].value['kvtags'][i];
                  obj[element.keytag] = element.valuetag;
                  this.formData[key][configData] = obj;
                }
              }
            }
          }
        }
      }
    }
    return this.objData[key][configName].get('kvtags') as UntypedFormArray;
  }

  newTAG(): UntypedFormGroup {
    return this.fb.group({
      keytag: ['', [Validators.required, Validators.minLength(2)]],
      valuetag: ['', [Validators.required, Validators.minLength(2)]],
    });
  }

  addTAG(key, configName) {
    this.kvtags(key, configName).push(this.newTAG());
    setTimeout(() => {
      this.tagFieldCheck();
    }, 200);
  }

  addListExp(key, configName, children) {
    this.formData[key][configName].push({});
  }

  removeTAG(i: number, key, configName) {
    this.kvtags(key, configName).removeAt(i);
    setTimeout(() => {
      this.tagFieldCheck();
    }, 200);
  }
  removeListExp(i: number, key, configName) {
    this.formData[key][configName].splice(i, 1);
  }

  checkBoxRequiredTrue() {
    for (const key in this.checkModelValues) {
      for (const key2 in this.checkModelValues[key]) {
        console.log('formdata', this.formData[key][key2]);
        console.log('formdata', this.formData[key][key2].length);
        if (this.formData[key][key2].length == 0) {
          console.log('formdata', this.formData[key][key2]);
          console.log('formdata', this.formData[key][key2].length);
          this.checkboxRequired = true;
          break;
        } else {
          this.checkboxRequired = false;
        }
      }
    }
  }
  objExpCheckBoxRequiredTrue() {
    for (const componentID in this.checkModelValues) {
      for (const parentConfig in this.checkModelValues[componentID]) {
        for (const childConfig in this.checkModelValues[componentID][
          parentConfig
        ]) {
          console.log(
            'formdata',
            this.formData[componentID][parentConfig][childConfig]
          );
          console.log(
            'formdata',
            this.formData[componentID][parentConfig][childConfig].length
          );
          if (
            this.formData[componentID][parentConfig][childConfig].length == 0
          ) {
            console.log(
              'formdata',
              this.formData[componentID][parentConfig][childConfig]
            );
            console.log(
              'formdata',
              this.formData[componentID][parentConfig][childConfig].length
            );
            this.checkboxRequired = true;
            break;
          } else {
            this.checkboxRequired = false;
          }
        }
      }
    }
  }
  addField(configName, itemkey, i) {
    this.formData[itemkey][configName][i] = '';
  }
  removeListChildren(
    configID,
    parentConfigName,
    parentIndex,
    childConfigName,
    childIndex
  ) {
    this.formData[configID][parentConfigName][parentIndex][
      childConfigName
    ].splice(childIndex, 1);
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  removeField(configName, itemkey, i) {
    console.log('removeField');
    this.formData[itemkey][configName].splice(i, 1);
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  removeObjExpField(configName, itemkey, i) {
    console.log('removeObjExpField');
    this.formData[itemkey][configName] = [];
    console.log(this.formData[itemkey][configName]);
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  addObjChildField(parentConfigName, itemkey, childConfigName, i) {
    this.formData[itemkey][parentConfigName][0][childConfigName][i] = '';
  }

  addObjChildFieldEmpty(parentConfigName, itemkey, childConfigName) {
    this.formData[itemkey][parentConfigName][0][childConfigName] = [''];
  }

  addListObjChildFieldEmpty(parentConfigName, itemkey, index, childConfigName) {
    this.formData[itemkey][parentConfigName][index][childConfigName] = [''];
  }

  tagFieldCheck() {
    for (const key in this.tagModelValues) {
      for (const key2 in this.tagModelValues[key]) {
        console.log('tagFormData', this.formData);
        console.log('key1', key, 'key2', key);
        for (const key3 in this.formData[key][key2]) {
          console.log(key3, this.formData[key][key2][key3]);
          if (
            key3.trim() == '' ||
            key3 == null ||
            this.formData[key][key2][key3].trim() == '' ||
            this.formData[key][key2][key3] == null
          ) {
            this.tagRequired = true;
            console.log('true');
            this.disableClick();
            return;
          } else {
            console.log('false');
            this.disableClick();
            this.tagRequired = false;
          }
        }
      }
    }
  }

  removeObjChildField(parentConfigName, itemkey, childConfigName, i) {
    this.formData[itemkey][parentConfigName][childConfigName].splice(i, 1);
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  saveDataObjExpList(parentConfigName, event, itemkey, childConfigName, i) {
    // console.log("configLoading in saveDataList",this.configLoading)
    this.formData[itemkey][parentConfigName][childConfigName][i] =
      event.target.value;
    if (
      this.formData[itemkey][parentConfigName][childConfigName][i].length >=
      1 &&
      this.formData[itemkey][parentConfigName][childConfigName].length == i
    ) {
      this.formData[itemkey][parentConfigName][childConfigName][i + 1] = '';
    }
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  addObjExp(key, configName, children) {
    // console.log("in")
    // this.formData[key][configName] = [{}];
    children.forEach((childConfig) => {
      console.log(childConfig.configName);
      this.formData[key][configName][childConfig.configName] = '';
    });
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  disableClick() {
    console.log(
      'tagRequired',
      this.tagRequired,
      'checkbox',
      this.checkboxRequired
    );
  }
}
