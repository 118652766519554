<div class="scratch-card card button-list card-block shadow pointer">
  <h3 class="item-card-title card-title mt-3">
    <b>Create from scratch</b><br />
  </h3>
  <div class="card-body">
    <div class="btn btn-link add-plus">
      <i class="icon-color fa fa-plus-circle"></i>
    </div>
  </div>
</div>
