<div class="sb-nav-fixed">
  <app-header [showExpandedView]="showExpandedView"></app-header>

  <div id="layoutSidenav">
    <app-side-nav-container
      (sendCollapsedStatus)="checkCollapseStatus($event)"
    ></app-side-nav-container>
    <div
      id="layoutSidenav_content"
      [ngClass]="{
        'shrinked-view': !showExpandedView,
        'expanded-view': showExpandedView
      }"
    >
      <main>
        <div class="container-fluid">
          <div class="row project-button">
            <div class="mr-auto p-2">
              <h2 class="font30">{{ "common.create_project" | translate }}</h2>
            </div>
          </div>

          <div id="stepper1" class="bs-stepper">
            <app-stepper></app-stepper>
            <div *ngIf="apiLoading" class="d-flex justify-content-center">
              <div class="loader-create" role="status">
                <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
              </div>
            </div>
            <div *ngIf="!apiLoading" class="bs-stepper-content">
              <div id="test-l-3">
                <ul ngbNav #nav="ngbNav" class="nav nav-tabs" [destroyOnHide]="false">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink class="nav-link">
                      <i class="placeholders">{{
                        "common.architecture_diagram" | translate
                      }}</i>
                    </a>
                    <ng-template ngbNavContent>
                      <img
                        id="selected-tmp-arch-img"
                        [src]="selectedArchDiagram"
                      />
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink class="nav-link">
                      <i class="placeholders">{{
                        "common.deployment_diagram" | translate
                      }}</i>
                    </a>
                    <ng-template ngbNavContent>
                      <img
                        id="selected-tmp-dep-img"
                        [src]="deploymentDiagram"
                      />
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              
                <button
                  type="submit"
                  (click)="next()"
                  class="btn btn-primary mr-3 mt-5"
                >
                  {{ "common.button.next" | translate }}
                  <span
                    *ngIf="submitting"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
                <button
                  *ngIf="showEdit == 'true'"
                  type="submit"
                  (click)="openeditor()"
                  class="btn btn-primary mr-3 mt-5"
                >
                  Edit
                </button>
              </div>              
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
