import { Component, OnInit } from '@angular/core';
import { UserRegistrationService } from '../services/user-registration.service';
import { Router } from '@angular/router';
import { UserService } from '../../shared-services/user/user.service';
import { NotifierToastService } from '../../shared-services/notifier-toast.service';
import { analyticsConst } from '../../shared-services/analytics-constants';
import { AnalyticsService } from '../../shared-services/analytics.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  agreeWithTerms = false;
  isRegistring: boolean = false;
  showRegSubError: boolean = false;
  regSubError: string;
  showRegSuccess: boolean = false;
  successMessage: string;
  submittingForm = false;

  constructor(
    private router: Router,
    private userRegistrationService: UserRegistrationService,
    private userService: UserService,
    private notifierToast: NotifierToastService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    if (this.userService.isUserLoggedIn()) {
      this.router.navigate(['/portal/dashboard']);
    }
  }

  showSuccessNotification(data) {
    this.notifierToast.showNotification('success', data);
  }

  showErrorNotification(data) {
    this.notifierToast.showNotification('error', data);
  }

  agreedWithTerms(event) {
    if (event.target.checked) {
      this.agreeWithTerms = true;
    } else {
      this.agreeWithTerms = false;
    }
  }

  checkConfirmPassword(event) {}

  registerSubmit() {
    const emailCoDomainCheck = this.email.indexOf('@impetus.co.in');
    const emailComDomainCheck = this.email.indexOf('@impetus.com');
    if (emailCoDomainCheck > -1 || emailComDomainCheck > -1) {
      this.submittingForm = true;
      this.isRegistring = true;
      this.userRegistrationService
        .register({
          email: this.email,
          name: this.fullName,
          password: this.password,
        })
        .subscribe(
          (data) => {
            this.showRegSuccess = true;
            this.successMessage =
              'A confirmation link has been sent to your registered email address. Please confirm to sign in';
            this.showSuccessNotification(this.successMessage);
            setTimeout((_) => {
              let param = {};
              param[analyticsConst.PARAMS.SINGUP_TIME] = new Date();
              this.analytics.logEvent(
                analyticsConst.EVENTS.ADMIN_SINGUP,
                param
              );
              this.router.navigate(['/auth/login']);
            }, 2000);
          },
          (err) => {
            this.isRegistring = false;
            this.showRegSubError = true;
            this.regSubError = err.message;
            this.showErrorNotification(err.message);
            let param = {};
            param[analyticsConst.PARAMS.SINGUP_TIME] = new Date();
            this.analytics.logEvent(
              analyticsConst.EVENTS.ADMIN_SINGUP_FAIL,
              param
            );
            this.submittingForm = false;
          }
        );
    } else {
      this.showErrorNotification('invalid email address');
    }
  }
}
