import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotifierToastService } from 'src/app/shared-services/notifier-toast.service';
import { ProjectDataService } from '../../project-services/project-data.service';
import { ProjectService } from '../../project-services/project.service';

@Component({
  selector: 'app-edit-config',
  templateUrl: './edit-config.component.html',
  styleUrls: ['./edit-config.component.scss'],
})
export class EditConfigComponent implements OnInit {
  saveEnabled: boolean = true;
  @Input() pro: any;
  @Input() currentEnv: string;
  @Output() updated = new EventEmitter<boolean>();
  proid: string;
  activeAccordion: number;
  activeAccord: string = '';
  componentMap: any;
  formData: any = {};
  objData = {};
  Object = Object;
  defaultData: any = {};
  totalConfigData: any = {};
  config: any = {};
  Secret = {};
  apiLoading: boolean;
  projectComponent: any;
  saving: boolean;
  env: any;
  checkModelValues = {};
  tagModelValues = {};
  checkCount: number = 0;
  checkboxRequired: boolean = false;
  tagRequired: boolean = false;

  constructor(
    private proService: ProjectService,
    private notifierToast: NotifierToastService,
    private fb: UntypedFormBuilder,
    private proData: ProjectDataService
  ) {}

  async ngOnInit(): Promise<void> {
    this.apiLoading = true;
    if (localStorage.getItem('activeAccord') != null) {
      this.activeAccord = localStorage.getItem('activeAccord');
    }
    this.projectComponent = this.pro.projectComponents;
    console.log(this.pro);
    this.env = this.currentEnv;
    // this.env = JSON.parse(this.pro.allProjectComponents[0].env);
    this.pro.allProjectComponents.forEach((element) => {
      this.totalConfigData[element.componentId] = element.configDetails;
      this.formData[element.componentId] = {};
      this.objData[element.componentId] = {};
      this.checkModelValues[element.componentId] = {};
      this.tagModelValues[element.componentId] = {};
      this.formData[element.componentId]['repo_name'] = element.repoName;
      this.formData[element.componentId]['usage'] = element.displayName;
      this.Secret[element.componentId] = {};
      // if (element.configDetails) {
      //   let keys = Object.keys(element.configDetails);
      //   console.log("keys",keys)
      for (const key in element.configDetails[this.env]) {
        if (
          Object.prototype.hasOwnProperty.call(
            element.configDetails[this.env],
            key
          )
        ) {
          this.formData[element.componentId][key] =
            element.configDetails[this.env][key];
        }
      }
      // }
    });
    this.proid = localStorage.getItem('editId');
    localStorage.setItem('formData', JSON.stringify(this.formData));
    this.componentMap = JSON.parse(this.pro.componentMap);
    Object.keys(this.componentMap).forEach((element) => {
      this.defaultData[element] = {};
      this.checkModelValues[element] = {};
    });
    this.proService
      .getConfigByCompId(Object.keys(this.componentMap))
      .subscribe((data: any) => {
        let object = data.reduce(function (r, a) {
          r[a.componentId] = r[a.componentId] || [];
          r[a.componentId].push(a);
          return r;
        }, Object.create(null));
        for (const key in object) {
          if (Object.prototype.hasOwnProperty.call(object, key)) {
            const element = object[key];
            if (element.length >= 2) {
              this.config[key] = element.sort((a, b) =>
                a.priority > b.priority ? 1 : -1
              );
            } else {
              this.config[key] = element;
            }
            element.forEach((ele) => {
              if (!ele.defaultValue || !ele.defaultValue['value']) {
                ele.defaultValue = {
                  value: '',
                };
              }
              if (
                ele.inputType == 'list' ||
                ele.inputType == 'list_of_checkbox'
              ) {
                if (ele.isRequired && ele.inputType == 'list_of_checkbox') {
                  console.log(ele);
                  this.checkCount++;
                  console.log(this.checkModelValues);
                  this.checkModelValues[key][ele.configName] = {};
                  // this.checkModelValues[ele.componentId][ele.configName] = ele.defaultValue.value;
                }
                if (!this.formData[key][ele.configName]) {
                  this.formData[key][ele.configName] = [];
                }
                this.defaultData[key][ele.configName] =
                  ele.defaultValue['value'];
              } else if (ele.inputType == 'list_object_expression') {
              } else if (ele.inputType == 'object') {
                this.formData[key][ele.configName] = {};
                if (ele.isRequired) {
                  console.log(ele);
                  this.tagRequired = true;
                }
                this.tagModelValues[ele.componentId][ele.configName] = ele;
                // console.log(this.tagModelValues,ele)
                this.objData[key][ele.configName] = this.fb.group({
                  kvtags: this.fb.array([]),
                });
                if (localStorage.getItem('formData')) {
                  for (const objKey in JSON.parse(
                    localStorage.getItem('formData')
                  )[key][ele.configName]) {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        JSON.parse(localStorage.getItem('formData'))[key][
                          ele.configName
                        ],
                        objKey
                      )
                    ) {
                      const element = JSON.parse(
                        localStorage.getItem('formData')
                      )[key][ele.configName][objKey];
                      console.log(element);
                      this.kvtags(key, ele.configName).push(
                        this.fb.group({
                          keytag: objKey,
                          valuetag: element,
                        })
                      );
                    }
                  }
                }
                this.defaultData[key][ele.configName] =
                  ele.defaultValue['value'];
              } else if (ele.inputType == 'object_expression') {
                if (!this.formData[key][ele.configName]) {
                  let temp = {};
                  // ele.children.forEach(element => {
                  //   temp[element.configName]=''
                  // });
                  this.formData[key][ele.configName] = temp;
                  // this.defaultData[key][ele.configName] =
                  //   ele.defaultValue['value'];
                }
              } else {
                // this.defaultData[key][ele.configName] =
                //   ele.defaultValue['value'];
                // if (!this.formData[key][ele.configName]) {
                //   this.formData[key][ele.configName] = '';
                // }
              }
              this.Secret[key][ele.configName] = '';
              // this.formData[key][ele.configName] = '';
            });
          }
        }
        this.apiLoading = false;
      });
  }

  toggleAccordion(index: number): void {
    this.activeAccordion = this.activeAccordion === index ? null : index;
  }

  saveData(configName, event, itemkey) {
    this.saveEnabled = false;
    this.formData[itemkey][configName] = event.target.value;
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  saveDataSecret(configName, event, itemkey) {
    this.saveEnabled = false;
    this.Secret[itemkey][configName] = event.target.value;
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  saveDataListCheck(configName, event, itemkey, data) {
    this.saveEnabled = false;
    if (this.formData[itemkey][configName] == '') {
      this.formData[itemkey][configName] = [];
    }
    if (event.target.checked == true) {
      this.formData[itemkey][configName].push(event.target.value);
      console.log('checkform', this.formData[itemkey][configName]);
      this.checkBoxRequiredTrue();
    } else {
      const index = this.formData[itemkey][configName].findIndex(
        (i) => i === event.target.value
      );
      if (index >= 0) {
        this.formData[itemkey][configName].splice(index, 1);
        console.log('uncheckform', this.formData[itemkey][configName]);
        this.checkBoxRequiredTrue();
      }
    }
  }

  saveDataList(configName, event, itemkey, i) {
    this.saveEnabled = false;
    this.formData[itemkey][configName][i] = event.target.value;
    if (
      this.formData[itemkey][configName][i].length >= 1 &&
      this.formData[itemkey][configName].length == i
    ) {
      this.formData[itemkey][configName][i + 1] = '';
    }
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  // saveDataListExp(configName, event, itemkey, i, field) {
  //   this.formData[itemkey][configName][i][field] = event.target.value;
  //   localStorage.setItem('formData', JSON.stringify(this.formData));
  // }
  // saveDataObjectExp(configName, event, itemkey, key) {
  //   this.formData[itemkey][configName][key] = event.target.value;
  //   localStorage.setItem('formData', JSON.stringify(this.formData));
  // }

  saveConfig() {
    this.saving = true;
    let temp = this.defaultData;
    let fdata = this.formData;
    let repoData = {};
    let usageData = {};
    let finalData = {};
    for (const key in this.objData) {
      if (Object.prototype.hasOwnProperty.call(this.objData, key)) {
        for (const configData in this.objData[key]) {
          if (
            Object.prototype.hasOwnProperty.call(this.objData[key], configData)
          ) {
            if (this.objData[key][configData].value['kvtags'].length > 0) {
              let obj = {};
              for (const i in this.objData[key][configData].value['kvtags']) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    this.objData[key][configData].value['kvtags'],
                    i
                  )
                ) {
                  const element =
                    this.objData[key][configData].value['kvtags'][i];
                  obj[element.keytag] = element.valuetag;
                  this.formData[key][configData] = obj;
                }
              }
            }
          }
        }
      }
    }
    for (const key in fdata) {
      if (Object.prototype.hasOwnProperty.call(fdata, key)) {
        repoData[key] = fdata[key]['repo_name'];
        usageData[key] = fdata[key]['usage'];
        for (const configData in fdata[key]) {
          if (Object.prototype.hasOwnProperty.call(fdata[key], configData)) {
            // if (fdata[key][configData] && fdata[key][configData].length > 1) {
            //   temp[key][configData] = fdata[key][configData];
            // }
            if (fdata[key][configData] != null) {
              temp[key][configData] = fdata[key][configData];
            }
          }
        }
        finalData[key] = temp[key];
        delete finalData[key]['repo_name'];
        delete finalData[key]['usage'];
      }
    }
    // let tData = {};
    for (const key in finalData) {
      if (Object.prototype.hasOwnProperty.call(finalData, key)) {
        //     // const ele = finalData[key];
        //     tData[key] = {};
        //     // this.env.forEach((element) => {
        //     //   tData[key][element.env] = finalData[key];
        //     // });
        //     tData[key][this.env] = finalData[key];
        this.totalConfigData[key][this.env] = finalData[key];
      }
    }
    console.log(this.totalConfigData);
    let keySecrets = {};
    for (const key in this.pro.projectComponents) {
      if (
        Object.prototype.hasOwnProperty.call(this.pro.projectComponents, key)
      ) {
        const element = this.pro.projectComponents[key];
        keySecrets[element] = this.Secret[key];
      }
    }
    let dataSecret = {
      userId: JSON.parse(localStorage.getItem('loggedInUser')).id,
      projectId: localStorage.getItem('editId'),
      componentId: Object.values(this.pro.projectComponents),
      key: keySecrets,
      type: 'string',
      version: '2',
    };
    this.proService
      .saveConfig(
        JSON.parse(localStorage.getItem('loggedInUser')).id,
        localStorage.getItem('editId'),
        this.totalConfigData,
        this.projectComponent,
        repoData,
        usageData
      )
      .subscribe((data) => {
        this.proService.saveSecret(dataSecret).subscribe((data) => {
          this.notifierToast.showNotification(
            'success',
            'Configurations Saved Successfully'
          );
          this.saving = false;
          this.updated.emit(true);
          // this.ngOnInit();
        }),
          (err: Error) => {
            this.notifierToast.showNotification('error', err.message);
            this.saving = false;
          };
      });
  }

  kvtags(key, configName): UntypedFormArray {
    this.objData[key][configName].get('kvtags');
    this.tagFieldCheck();
    for (const key in this.objData) {
      if (Object.prototype.hasOwnProperty.call(this.objData, key)) {
        for (const configData in this.objData[key]) {
          if (
            Object.prototype.hasOwnProperty.call(this.objData[key], configData)
          ) {
            if (this.objData[key][configData].value['kvtags'].length > 0) {
              let obj = {};
              for (const i in this.objData[key][configData].value['kvtags']) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    this.objData[key][configData].value['kvtags'],
                    i
                  )
                ) {
                  const element =
                    this.objData[key][configData].value['kvtags'][i];
                  obj[element.keytag] = element.valuetag;
                  this.formData[key][configData] = obj;
                }
              }
            }
          }
        }
      }
    }
    return this.objData[key][configName].get('kvtags') as UntypedFormArray;
  }

  newTAG(): UntypedFormGroup {
    return this.fb.group({
      keytag: '',
      valuetag: '',
    });
  }

  addTAG(key, configName) {
    this.kvtags(key, configName).push(this.newTAG());
    // this.tagFieldCheck()
    setTimeout(() => {
      this.tagFieldCheck();
    }, 200);
  }

  // addListExp(key, configName, valueExpression) {
  //   this.formData[key][configName].push(...valueExpression);
  // }

  removeTAG(i: number, key, configName) {
    this.kvtags(key, configName).removeAt(i);
    // this.tagFieldCheck();
    setTimeout(() => {
      this.tagFieldCheck();
    }, 200);
  }
  // removeListExp(i: number, key, configName) {
  //   this.formData[key][configName].splice(i, 1);
  // }

  addObjExp(key, configName, children) {
    console.log('in');
    children.forEach((childConfig) => {
      console.log(childConfig.configName);
      this.formData[key][configName][childConfig.configName] = '';
    });
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  removeObjExpField(configName, itemkey, i) {
    console.log('removeObjExpField');
    this.formData[itemkey][configName] = {};
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  saveDataObjectExp(configName, event, itemkey, key) {
    this.saveEnabled = false;
    this.formData[itemkey][configName][key] = event.target.value;
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  saveDataObjListCheck(configName, childConfig, event, itemkey, data) {
    if (this.formData[itemkey][configName][childConfig] == '') {
      this.formData[itemkey][configName][childConfig] = [];
    }
    if (event.target.checked == true) {
      this.formData[itemkey][configName][childConfig].push(event.target.value);
      // this.checkModelValues[itemkey][configName].push(event.target.value);
      console.log('checkform', this.formData[itemkey][configName][childConfig]);
      this.objExpCheckBoxRequiredTrue();
    } else {
      const index = this.formData[itemkey][configName][childConfig].findIndex(
        (i) => i === event.target.value
      );
      if (index >= 0) {
        this.formData[itemkey][configName][childConfig].splice(index, 1);
        // this.checkModelValues[itemkey][configName].splice(index, 1);
        console.log(
          'uncheckform',
          this.formData[itemkey][configName][childConfig]
        );
        this.objExpCheckBoxRequiredTrue();
      }
    }
  }

  objExpCheckBoxRequiredTrue() {
    for (const componentID in this.checkModelValues) {
      for (const parentConfig in this.checkModelValues[componentID]) {
        for (const childConfig in this.checkModelValues[componentID][
          parentConfig
        ]) {
          console.log(
            'formdata',
            this.formData[componentID][parentConfig][childConfig]
          );
          console.log(
            'formdata',
            this.formData[componentID][parentConfig][childConfig].length
          );
          if (
            this.formData[componentID][parentConfig][childConfig].length == 0
          ) {
            console.log(
              'formdata',
              this.formData[componentID][parentConfig][childConfig]
            );
            console.log(
              'formdata',
              this.formData[componentID][parentConfig][childConfig].length
            );
            this.checkboxRequired = true;
            break;
          } else {
            this.checkboxRequired = false;
          }
        }
      }
    }
  }

  addObjChildFieldEmpty(parentConfigName, itemkey, childConfigName) {
    this.formData[itemkey][parentConfigName][childConfigName] = [''];
  }

  addObjChildField(parentConfigName, itemkey, childConfigName, i) {
    this.formData[itemkey][parentConfigName][childConfigName][i] = '';
  }

  saveDataObjExpList(parentConfigName, event, itemkey, childConfigName, i) {
    // console.log("configLoading in saveDataList",this.configLoading)
    this.formData[itemkey][parentConfigName][childConfigName][i] =
      event.target.value;
    if (
      this.formData[itemkey][parentConfigName][childConfigName][i].length >=
        1 &&
      this.formData[itemkey][parentConfigName][childConfigName].length == i
    ) {
      this.formData[itemkey][parentConfigName][childConfigName][i + 1] = '';
    }
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  removeObjChildField(parentConfigName, itemkey, childConfigName, i) {
    this.formData[itemkey][parentConfigName][childConfigName].splice(i, 1);
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  addListExp(key, configName, children) {
    this.formData[key][configName].push({});
  }

  removeListExp(i: number, key, configName) {
    this.formData[key][configName].splice(i, 1);
  }

  saveDataListObjectListCheckbox(configName, childConfig, i, event, itemkey) {
    this.saveEnabled = false;
    if (!this.formData[itemkey][configName][i][childConfig]) {
      this.formData[itemkey][configName][i][childConfig] = [];
    }
    if (event.target.checked == true) {
      this.formData[itemkey][configName][i][childConfig].push(
        event.target.value
      );
      // this.checkModelValues[itemkey][configName].push(event.target.value);
      console.log(
        'checkform',
        this.formData[itemkey][configName][i][childConfig]
      );
      this.objExpCheckBoxRequiredTrue();
    } else {
      const index = this.formData[itemkey][configName][i][
        childConfig
      ].findIndex((i) => i === event.target.value);
      if (index >= 0) {
        this.formData[itemkey][configName][i][childConfig].splice(index, 1);
        // this.checkModelValues[itemkey][configName].splice(index, 1);
        console.log(
          'uncheckform',
          this.formData[itemkey][configName][childConfig]
        );
        this.objExpCheckBoxRequiredTrue();
      }
    }
  }

  removeListChildren(
    configID,
    parentConfigName,
    parentIndex,
    childConfigName,
    childIndex
  ) {
    this.formData[configID][parentConfigName][parentIndex][
      childConfigName
    ].splice(childIndex, 1);
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  saveDataListChildren(
    configName,
    event,
    itemkey,
    parentIndex,
    childindex,
    field
  ) {
    // this.formData[itemkey][configName][i][field][c] = event.target.value;
    // console.log(this.formData);
    // localStorage.setItem('formData', JSON.stringify(this.formData));
    this.formData[itemkey][configName][parentIndex][field][childindex] =
      event.target.value;
    if (
      this.formData[itemkey][configName][parentIndex][field][childindex]
        .length >= 1 &&
      this.formData[itemkey][configName][parentIndex][field][childindex]
        .length == childindex
    ) {
      this.formData[itemkey][configName][parentIndex][field][childindex + 1] =
        '';
    }
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  addListChildren(key, configName, i, children, index) {
    this.saveEnabled = false;
    this.formData[key][configName][i][children][index] = '';
    console.log(this.formData);
  }

  saveDataChildren(configName, event, itemkey, i, field) {
    this.saveEnabled = false;
    this.formData[itemkey][configName][i][field] = event.target.value;
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  saveDataListExp(configName, event, itemkey, i, field) {
    this.saveEnabled = false;
    this.formData[itemkey][configName][i][field] = event.target.value;
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }

  addField(configName, itemkey, i) {
    this.formData[itemkey][configName][i] = '';
  }

  removeField(configName, itemkey, i) {
    console.log('removeField');
    this.formData[itemkey][configName].splice(i, 1);
    localStorage.setItem('formData', JSON.stringify(this.formData));
  }
  checkBoxRequiredTrue() {
    for (const key in this.checkModelValues) {
      for (const key2 in this.checkModelValues[key]) {
        console.log('formdata', this.formData[key][key2]);
        console.log('formdata', this.formData[key][key2].length);
        if (this.formData[key][key2].length == 0) {
          console.log('formdata', this.formData[key][key2]);
          console.log('formdata', this.formData[key][key2].length);
          this.checkboxRequired = true;
          break;
        } else {
          this.checkboxRequired = false;
        }
      }
    }
  }

  tagFieldCheck() {
    for (const key in this.tagModelValues) {
      for (const key2 in this.tagModelValues[key]) {
        for (const key3 in this.formData[key][key2]) {
          if (
            key3.trim() == '' ||
            key3 == null ||
            this.formData[key][key2][key3].trim() == '' ||
            this.formData[key][key2][key3] == null
          ) {
            this.tagRequired = true;
            console.log('nokey3');
            return;
          } else {
            this.tagRequired = false;
          }
        }
      }
    }
  }
}
