import { Component, OnInit } from '@angular/core';
import { UserLoginService } from '../services/user-login.service';
import { UserService } from '../../shared-services/user/user.service';
import { User } from '../../shared-services/user/user';
import { Router } from '@angular/router';
import { NotifierToastService } from '../../shared-services/notifier-toast.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { analyticsConst } from '../../shared-services/analytics-constants';
import { AnalyticsService } from '../../shared-services/analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  isLoggingIn = false;
  showLoginSubError = false;
  loginSubError: string;
  submittingForm = false;

  constructor(
    private router: Router,
    private userLoginService: UserLoginService,
    private userService: UserService,
    private http: HttpClient,
    private cookieService: CookieService,
    private notifierToast: NotifierToastService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    if (this.userService.isUserLoggedIn()) {
      this.router.navigate(['/portal/dashboard']);
    }
  }

  showSuccessNotification(data: string) {
    this.notifierToast.showNotification('success', data);
  }

  showErrorNotification(data: string) {
    this.notifierToast.showNotification('error', data);
  }

  getuser(username: string) {
    return this.http.get(environment.GET_USER + username);
  }
  async loginSubmit() {
    this.submittingForm = true;
    this.isLoggingIn = true;
    this.userLoginService.authenticate(this.email, this.password).subscribe(
      (data) => {
        this.getuser(data.username).subscribe((userdetail: any) => {
          let user = new User(data.username, userdetail.name, this.email);
          this.userService.setLoggedInUser(user);
          this.cookieService.putObject('userDetails', user, {
            domain: environment.COOKIE_DOMAIN,
          });
          let param = {};
          param[analyticsConst.PARAMS.LOGIN_TIME] = new Date();
          this.analytics.logEvent(analyticsConst.EVENTS.ADMIN_LOGIN, param);
          this.router.navigate(['/portal/dashboard']);
        });
      },
      (err) => {
        this.isLoggingIn = false;
        this.showLoginSubError = true;
        this.loginSubError = err.message;
        this.showErrorNotification(err.message);
        let param = {};
        param[analyticsConst.PARAMS.LOGIN_TIME] = new Date();
        this.analytics.logEvent(analyticsConst.EVENTS.ADMIN_LOGIN_FAIL, param);
        this.submittingForm = false;
      }
    );
  }
}
