import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
// import aws4 from "aws4";
const AWS = require('aws-sdk');
// import Auth from '@aws-amplify/auth';
// import { Signer } from '@aws-amplify/core';
import * as urlLib from 'url';
import { environment } from '../../../environments/environment';

export interface ISignedUrlBodyParams {
  filePath: string;
  fileType: string;
}
@Injectable({
  providedIn: 'root',
})
export class AwsSigV4Service {
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  signRequest(request, AWSCredentials) {
    let parserURL = urlLib.parse(request.urlWithParams, true, true);
    const { search, ...parsedUrl } = parserURL;
    let formattedUrl = urlLib.format({
      ...parsedUrl,
      query: { ...parsedUrl.query },
    });

    let httpRequest = new AWS.HttpRequest(formattedUrl, environment.region);
    httpRequest.method = request.method.toUpperCase();
    httpRequest.headers.host = parserURL.host;
    httpRequest.headers['Content-Type'] = 'application/json';
    httpRequest.headers['Accept'] = 'application/json';

    if (request.body) {
      httpRequest.body = JSON.stringify(request.body);
    }

    let v4signer = new AWS.Signers.V4(httpRequest, 'execute-api', true);
    v4signer.addAuthorization(AWSCredentials, AWS.util.date.getDate());
    if (v4signer.request.headers['host']) {
      delete v4signer.request.headers['host']; //Browser throws a warning if attempting to set host header
    }
    // httpRequest.headers.set('Authorization', `Bearer ${localStorage.getItem('tokenid')}`);
    httpRequest.headers['Authorization'] = `Bearer ${this.cookieService.get(
      'idToken'
    )}`;
    return { headers: httpRequest.headers };
  }

  generateSignedUrl(
    signedUrlBodyParams: ISignedUrlBodyParams
  ): Observable<string> {
    const url = environment.GENERATE_SIGNED_S3_URL;
    return this.http.post<string>(url, signedUrlBodyParams);
  }
}
