<!-- <meta name="viewport" content="width=device-width, initial-scale=1" />
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script> -->

<nav
  class="sb-topnav navbar navbar-expand header-margin"
  [ngClass]="{
    'sb-topnav navbar navbar-expand': true,
    'header-shrinked-view': !showExpandedView,
    'header-expanded-view': showExpandedView
  }"
>
  <h3>Welcome, {{ loggedInUserName }}</h3>
  <!-- <a class="navbar-brand" routerLink="/portal/dashboard">KloudJet</a> -->
  <!-- <button
    class="btn btn-link btn-sm order-1 order-lg-0"
    id="sidebarToggle"
    href="#"
  >
    <i class="fas fa-bars"></i>
  </button> -->
  <button
    class="btn button outline navbar-nav take_tour ml-auto"
    [disabled]="true"
  >
    {{ "common.button.take_tour" | translate }}
  </button>
  <button
    (click)="createProject()"
    [routerLink]="['/projects/create']"
    class="btn btn-primary button"
  >
    {{ "common.create_project" | translate }}
  </button>
  <!-- <ul class="navbar-nav ml-auto">
    <li class="nav-item" ngbDropdown>
      <a
        class="nav-link"
        tabindex="0"
        ngbDropdownToggle
        id="navbarDropdown2"
        role="button"
      >
        <i class="fas fa-user fa-fw"></i>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarDropdown2"
        class="dropdown-menu dropdown-menu-right"
      >
        <a ngbDropdownItem (click)="$event.preventDefault()"
          >My Profile
          <sup style="font-size: 8px; color: #007bff"> Coming </sup>
          <sup style="font-size: 8px; color: #007bff">Soon</sup></a
        >
        <a ngbDropdownItem href="#" (click)="$event.preventDefault()">
          Settings <sup style="font-size: 8px; color: #007bff"> Coming </sup>
          <sup style="font-size: 8px; color: #007bff">Soon</sup></a
        >
        <a ngbDropdownItem href="#" (click)="userLogout()">Logout</a> 
      </div>
    </li>
  </ul> -->
</nav>
