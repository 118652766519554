import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  AwsSigV4Service,
  ISignedUrlBodyParams,
} from 'src/app/shared-services/auth/aws-sign-v4.service';
import { UploadServiceService } from 'src/app/shared-services/upload-service.service';
import { UserService } from 'src/app/shared-services/user/user.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Output() onpick = new EventEmitter<any>();
  @Input('fromTemplate') compListFromParent: any[];
  @ViewChild('componentPost', { static: false }) componentPost: NgForm;
  isUploading = false;
  uploadedFileUrl: string;
  isUploaded = false;
  isMismatch = false;
  isTooBig = false;
  newSelect: boolean = false;
  cid: number;
  name: string;
  existingSelect: boolean = false;
  Componentname: string;
  inputFileName: string;
  Platform: string;
  technology: string;
  repoUrl: string;
  branch: string;
  folderName: string;
  description: string;
  // preDeploymentNote: string;
  // postDeploymentNote: string;
  templateComponent: any;
  componentList: any;
  existingId: any;
  loggedUser: any; //=localStorage.getItem('loggedInUser')
  tabs: TabsComponent[] = [];
  constructor(
    private userService: UserService,
    private upload: UploadServiceService,
    private sigv4Service: AwsSigV4Service
  ) {}

  ngOnInit(): void {}
  // addTechnology(data: any): void {
  //   this.technology = data;
  // }
  addTier() {
    let temp = new TabsComponent(
      this.userService,
      this.upload,
      this.sigv4Service
    );
    let num = this.tabs.length + 1;
    temp.name = 'Component' + num;
    if (this.newSelect == true) {
      this.newSelect = !this.newSelect;
    }
    if (this.existingSelect == true) {
      this.existingSelect = !this.existingSelect;
    }
    this.tabs.push(temp);
    // console.log(this.tabs);
  }
  deleteTier(id: number) {
    this.tabs.splice(
      this.tabs.findIndex((tab) => tab.cid === id),
      1
    );
  }
  addNewComponent(
    f: NgForm,
    name,
    desc,
    branch,
    folder,
    // pre,
    // post,
    platform,
    codeUrl
  ) {
    if (f.invalid && f.submitted) {
      // console.log('invalid is form');
      return;
    }
    if (f.valid && f.submitted) {
      //save button disabled
      this.newSelect = !this.newSelect;
      let component = {
        label: name,
        technology: this.technology,
        branch: branch,
        folderName: folder,
        // preDeploymentNote: pre,
        // postDeploymentNote: post,
        isNew: true,
        createdBy: this.userService.loggedInUser.id,
        platform: platform,
        repoUrl: codeUrl,
      };

      this.compListFromParent.push(component);
      this.onpick.emit(this.compListFromParent);
      // this.newSelect=!this.newSelect;
    }
  }
  receiveHandler(p) {
    this.existingId = p.comp;
    // console.log('Exising component', this.existingId);
    // if (this.componentList.index(this.existingId) == -1) {
    //       this.componentList.push(this.existingId);
    // }
    this.existingSelect = p.svalue;

    this.compListFromParent.push(this.existingId);
    this.onpick.emit(this.compListFromParent);
  }

  uploadFiles(fileInputEvent) {
    const uploadedFile: File = fileInputEvent.target.files[0];
    const filePath = fileInputEvent.target.files[0].name;
    // console.log(filePath);
    if (fileInputEvent.target.files[0].size > 5000000) {
      this.isUploading = false;
      this.isTooBig = true;
      return;
    }
    // Allowing file type
    var allowedExtensions = /(\.zip)$/i;
    if (!allowedExtensions.exec(filePath)) {
      this.isUploading = false;
      this.isMismatch = true;
      return;
    } else if (uploadedFile) {
      this.isUploading = true;
      this.isTooBig = false;
      this.isMismatch = false;
      // console.log('File Provided!');
      this.inputFileName = fileInputEvent.target.files[0].name;
      // console.log(this.inputFileName);
      const signedUrlBodyParams: ISignedUrlBodyParams = {
        filePath: 'Code/' + uploadedFile.name,
        fileType: uploadedFile.type,
      };
      this.sigv4Service.generateSignedUrl(signedUrlBodyParams).subscribe(
        (generatedUrl) => {
          this.upload
            .uploadFileToS3(generatedUrl, uploadedFile, uploadedFile.type)
            .subscribe(
              () => {
                this.uploadedFileUrl = generatedUrl.split('?')[0];
                this.isUploading = false;
                this.isUploaded = true;
                // console.log('File url - :', this.uploadedFileUrl);
              },
              (err) => {
                this.isUploading = false;
                this.isUploaded = false;
                // console.log('upload err', err);
              }
            );
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  onSubmit() {}
}
