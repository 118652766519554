import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Stepper from 'bs-stepper';
import { CookieService } from 'ngx-cookie';
import { AnalyticsService } from 'src/app/shared-services/analytics.service';
import { NotifierToastService } from 'src/app/shared-services/notifier-toast.service';
import { UserService } from 'src/app/shared-services/user/user.service';
import { DashboardService } from '../dashboard-services/dashboard.service';
import { environment } from 'src/environments/environment';
import { analyticsConst } from 'src/app/shared-services/analytics-constants';
import { ICollapseStatus } from 'src/app/portal/side-nav-container/side-nav-container.utils';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss'],
})
export class ProjectDashboardComponent implements OnInit {
  allApps: any;
  closeResult = '';
  private stepper: Stepper;
  projectEmpty = false;
  projectPresent = false;
  loggedInUserName: string;
  showEditFlow = false;
  selectedTemplateId: string;
  deleting = false;
  deletingId: string;
  apiLoaded = false;
  platformObj: any = {
    AWS: '../../assets/images/aws.png',
    AZURE: '../../assets/images/azure.png',
    GCP: '../../assets/images/gcp.png',
    OCI: '../../assets/images/oci.png'
  };
  projects: any = [];
  apiLoading = true;
  isPenddingDownloads = false;
  penddingDownloadJobIds: any[] = [];
  checkPenddingDownloadJobsInterval: any;
  inTracing: boolean = false;
  showExpandedView = false;
  constructor(
    private httpClient: HttpClient,
    private dashboardService: DashboardService,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService,
    private router: Router,
    private notifierToast: NotifierToastService,
    public cookieService: CookieService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.loggedInUserName = this.userService.loggedInUser.email;
    this.loadDownloadJobs();
    this.dashboardService
      .getAllApps(this.userService.loggedInUser.id)
      .subscribe(
        (data: any) => {
          this.allApps = data;
          this.allApps.forEach((project: any) => {
            if (project.projectTemplate) {
              project.selectedProjectTemplate = project.projectTemplate;
              delete project.projectTemplate;
              if (project.templateDownloadJob) {
                delete project.templateDownloadJob;
              }
              project.diagram =
                project.selectedProjectTemplate.logo || environment.GET_LOGO;
            }
          });
          this.allApps = this.allApps.sort((a: any, b: any) =>
            a.updatedAt < b.updatedAt ? 1 : -1
          );
          // console.log('all brands data', this.allApps);
          if (data.length > 0) {
            this.projectEmpty = false;
            this.projectPresent = true;
          } else {
            this.projectEmpty = true;
            this.projectPresent = false;
          }
          this.apiLoaded = true;
        },
        (error) => {
          this.projectEmpty = true;
          ``;
          this.projectPresent = false;
        }
      );
  }

  compare(createdAt: any, updatedAt: any) {
    const x = new Date(createdAt);
    const y = new Date(updatedAt);
    if (
      x.getHours() === y.getHours() &&
      x.getDate() === y.getDate() &&
      x.getMonth() === y.getMonth() &&
      x.getMinutes() === y.getMinutes() &&
      x.getSeconds() == y.getSeconds()
    ) {
      return true;
    } else {
      return false;
    }
  }

  showSuccessNotification(data: any) {
    this.notifierToast.showNotification('success', data);
  }

  showErrorNotification(data: any) {
    this.notifierToast.showNotification('error', data);
  }

  ngOnDestroy() {
    if (this.checkPenddingDownloadJobsInterval) {
      clearInterval(this.checkPenddingDownloadJobsInterval);
      this.checkPenddingDownloadJobsInterval = null;
    }
  }

  loadDownloadJobs() {
    this.httpClient
      .get(
        environment.GET_DOWNLOAD_JOBS + '/' + this.userService.loggedInUser.id
      )
      .subscribe(
        (data) => {
          this.projects = data;
          if (this.projects) {
            this.projects.forEach((project: any) => {
              if (project.projectTemplate && project.projectDownloadJob) {
                // project.selectedProjectTemplate = project.projectTemplate;

                project.projectDownloadJob.forEach((downloadJob: any) => {
                  if (downloadJob.jobStatus === 'Completed') {
                    project.downloadJob = downloadJob;
                  } else if (downloadJob.jobStatus === 'InProgress') {
                    let param = {};
                    param[analyticsConst.PARAMS.TIME] = new Date();
                    this.analytics.logEvent(
                      analyticsConst.EVENTS.ADMIN_DOWNLOAD_PROJECT_START,
                      param
                    );
                    project.downloadJob = downloadJob;
                    this.isPenddingDownloads = true;
                    this.penddingDownloadJobIds.push(project.downloadJob.id);
                  }
                });

                if (!project.downloadJob) {
                  project.downloadJob = project.projectDownloadJob[0];
                }
                delete project.projectTemplate;
                delete project.templateDownloadJob;
              }
            });
            this.apiLoading = false;
            this.projectEmpty = false;
          } else {
            this.projectEmpty = true;
          }
          // this.loadDraftTemplates();
        },
        (err) => {
          // this.loadDraftTemplates();
          this.apiLoading = false;
          this.projectEmpty = true;
          let param = {};
          param[analyticsConst.PARAMS.TIME] = new Date();
          this.analytics.logEvent(
            analyticsConst.EVENTS.ADMIN_DOWNLOAD_PROJECT_FAIL,
            param
          );
          this.showErrorNotification(err.message);
          // alert(err.message);
        }
      );
  }

  traceInProgressJobs(that?: any) {
    let self = that;
    if (!self) {
      self = this;
    }

    if (self.isPenddingDownloads) {
      if (!self.inTracing) {
        self.inTracing = true;
        // Check if any pendding job then intiate Timer
        if (!self.checkPenddingDownloadJobsInterval) {
          self.checkPenddingDownloadJobsInterval = setInterval((_) => {
            self.traceInProgressJobs(self);
          }, 1500);
        }
        self.httpClient
          .post(environment.GET_JOB_STATUS, {
            jobIds: self.penddingDownloadJobIds,
          })
          .subscribe(
            (downloadJobs: []) => {
              if (downloadJobs && downloadJobs.length > 0) {
                downloadJobs.forEach((downloadJob) => {
                  self.updateJobInProjects(downloadJob);
                });
              } else {
                self.isPenddingDownloads = false;
                self.downloadJobs = [];
                if (self.checkPenddingDownloadJobsInterval) {
                  clearInterval(self.checkPenddingDownloadJobsInterval);
                  self.checkPenddingDownloadJobsInterval = null;
                }
              }
              self.inTracing = false;
            },
            (err: any) => {
              this.showErrorNotification(err.message);
              // alert(err.message);
              self.inTracing = false;
            }
          );
      }
    } else if (self.checkPenddingDownloadJobsInterval) {
      clearInterval(self.checkPenddingDownloadJobsInterval);
      self.checkPenddingDownloadJobsInterval = null;
    }
  }

  updateJobInProjects(downloadJob: any) {
    this.projects.every((project: any) => {
      if (project?.downloadJob?.id === downloadJob?.id) {
        project.downloadJob.jobProgress = downloadJob?.jobProgress;
        project.downloadJob.jobStatus = downloadJob?.jobStatus;
        project.downloadJob.failedReason = downloadJob?.failedReason;
        project.source = downloadJob.source;

        if (project.downloadJob.jobStatus !== 'InProgress') {
          this.penddingDownloadJobIds = this.penddingDownloadJobIds.filter(
            (id) => id !== downloadJob.id
          );
        }
        return false;
      }
      return true;
    });
  }

  intiateDownloadJob(project: any, projectId: any) {
    project.isDownloadStarting = true;
    this.httpClient.get(environment.INITIATE_DOWNLOAD + projectId).subscribe(
      (downloadJob: any) => {
        project.isDownloadStarting = false;

        project.downloadJob = downloadJob;
        this.penddingDownloadJobIds.push(downloadJob.id);
        this.isPenddingDownloads = true;
        this.traceInProgressJobs();
      },
      (err) => {
        project.isDownloadStarting = false;
        this.showErrorNotification(err.message);
        // alert(err.message);
      }
    );
  }

  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }

  deleteproject(id: string) {
    this.deleting = true;
    this.deletingId = id;
    this.httpClient.delete(environment.DELETE_PROJECT + '/' + id).subscribe(
      (data) => {
        this.notifierToast.showNotification(
          'success',
          'Project Deleted Successfully'
        );
        let param = {};
        param[analyticsConst.PARAMS.TIME] = new Date();
        param[analyticsConst.PARAMS.DELETE_PROJECT_ID] = id;
        this.analytics.logEvent(
          analyticsConst.EVENTS.ADMIN_DELETE_PROJECT,
          param
        );
        this.ngOnInit();
        this.deleting = false;
        this.deletingId = undefined;
      },
      (err: Error) => {
        this.showErrorNotification(err.message);
        let param = {};
        param[analyticsConst.PARAMS.TIME] = new Date();
        param[analyticsConst.PARAMS.DELETE_PROJECT_ID] = id;
        this.analytics.logEvent(
          analyticsConst.EVENTS.ADMIN_DELETE_PROJECT_FAIL,
          param
        );
        this.deleting = false;
      }
    );
  }
  editProject(id: string, name: string) {
    localStorage.setItem('editId', id);
    localStorage.setItem('editName', name);
    let param = {};
    param[analyticsConst.PARAMS.EDIT_PROJECT_ID] = id;
    param[analyticsConst.PARAMS.TIME] = new Date();
    param[analyticsConst.PARAMS.EDIT_PROJECT_NAME] = name;
    this.analytics.logEvent(analyticsConst.EVENTS.ADMIN_EDIT_PROJECT, param);
    this.router.navigate(['/projects/edit/general']);
  }

  createProject(): void {
    let param = {};
    param[analyticsConst.PARAMS.TIME] = new Date();
    this.analytics.logEvent(analyticsConst.EVENTS.ADMIN_CREATE_PROJECT, param);
  }
}
