import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared-services/user/user.service';
import { ICollapseStatus } from '../../portal/side-nav-container/side-nav-container.utils';
import { ProjectDataService } from '../project-services/project-data.service';
import { ProjectService } from '../project-services/project.service';
import { CookieService } from 'ngx-cookie';
import { AnalyticsService } from 'src/app/shared-services/analytics.service';
import { environment } from 'src/environments/environment';
import { analyticsConst } from 'src/app/shared-services/analytics-constants';

@Component({
  selector: 'app-project-dia',
  templateUrl: './project-dia.component.html',
  styleUrls: ['./project-dia.component.scss'],
})
export class ProjectDiaComponent implements OnInit {
  showExpandedView: boolean;
  selectedArchDiagram: string;
  deploymentDiagram: string;
  selectedTemplateLabel: string;
  proid: string;
  project: any;
  projectName: string;
  apiLoading: boolean;
  env: any;
  submitting = false;
  showEdit = 'true';
  constructor(
    private proData: ProjectDataService,
    private router: Router,
    private projectService: ProjectService,
    private userService: UserService,
    public cookieService: CookieService,
    private analytics: AnalyticsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.apiLoading = true;
    if (this.cookieService.get('projectId')) {
      this.showEdit = 'false';
      localStorage.setItem('proId', this.cookieService.get('projectId'));
      await this.projectService
        .fetchPro(localStorage.getItem('proId'))
        .subscribe((data: any) => {
          this.proData.saveSelectedTemp(data.project);
          let temp = {
            name: data.project.name,
            env_array: JSON.parse(data.project.allProjectComponents[0]['env']),
          };
          this.env = JSON.parse(data.project.allProjectComponents[0]['env']);
          this.proData.saveProjectEnv(temp);
          this.proData.saveProject(data.project);
          localStorage.setItem(
            'projectComponent',
            JSON.stringify(data.project.projectComponents)
          );
          this.project = data.project;
          this.proid = localStorage.getItem('proId');
          const tempData = this.proData.returnProject();
          this.selectedArchDiagram = tempData?.architectureDiagram;
          this.deploymentDiagram = tempData?.deploymentDiagram;
          this.projectName = this.proData.returnProjectEnv()?.name;
          this.apiLoading = false;
        });
    } else if (
      this.proData.returnSelectedTemp() &&
      this.proData.returnSelectedTemp().id &&
      this.proData.returnProjectEnv()
    ) {
      this.proid = localStorage.getItem('proId');
      const tempData = this.proData.returnSelectedTemp();
      this.selectedArchDiagram = tempData?.architectureDiagram;
      this.deploymentDiagram = tempData?.deploymentDiagram;
      this.projectName = this.proData.returnProjectEnv()?.name;
      this.apiLoading = false;
    } else {
      this.router.navigate([`/projects/create`]);
    }
  }

  next() {
    let data = this.proData.returnProjectEnv();
    if (data.env_array.length != 0) {
      let temp = [];
      data.env_array.forEach((element) => {
        // temp.push(JSON.stringify(element));
        temp.push(element);
      });
      this.env = JSON.stringify(temp);
      // console.log(this.env);
    }
    // console.log(this.productForm.value);
    this.env = this.env.replace('\\', '');
    if (this.cookieService.get('projectId') && localStorage.getItem('proId')) {
      this.router.navigate([
        `/projects/create/${localStorage.getItem('proId')}/source`,
      ]);
    } else if (this.cookieService.get('projectId')) {
      localStorage.setItem('proId', this.cookieService.get('projectId'));
      this.router.navigate([
        `/projects/create/${localStorage.getItem('proId')}/source`,
      ]);
    } else this.saveProject();
  }

  prev() {
    this.router.navigate([`/projects/create/basic`]);
  }

  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }
  saveProject() {
    this.submitting = true;

    this.projectService
      .saveProject(
        this.proData.returnProjectEnv().name,
        this.userService.loggedInUser.id,
        this.proData.returnSelectedTemp().id,
        this.env
      )
      .subscribe(
        (data: any) => {
          this.project = data.project;
          this.proData.saveProject(this.project);
          localStorage.removeItem('tempId');
          localStorage.setItem('proId', this.project.id);
          this.submitting = false;
          this.router.navigate([`/projects/create/${this.project.id}/source`]);
        },
        (err: Error) => {
          console.log(err.message);
        }
      );
  }

  async openeditor(): Promise<void> {
    await this.cookieService.remove('projectId', {
      domain: environment.COOKIE_DOMAIN,
    });
    await this.cookieService.remove('templateId', {
      domain: environment.COOKIE_DOMAIN,
    });
    await this.cookieService.remove('loggedInUser', {
      domain: environment.COOKIE_DOMAIN,
    });
    await this.cookieService.remove('tempTemplate', {
      domain: environment.COOKIE_DOMAIN,
    });
    await this.cookieService.remove('projectDetails', {
      domain: environment.COOKIE_DOMAIN,
    });
    const url = environment.EDITOR_URL;
    let param = {};
    param[analyticsConst.PARAMS.TIME] = new Date();
    param[analyticsConst.PARAMS.PROJECT_ID] =
      this.proData.returnSelectedTemp().id;
    this.analytics.logEvent(analyticsConst.EVENTS.ADMIN_EDIT_ON_CANVAS, param);
    this.cookieService.put('templateId', this.proData.returnSelectedTemp().id, {
      domain: environment.COOKIE_DOMAIN,
    });
    this.cookieService.put(
      'loggedInUser',
      localStorage.getItem('loggedInUser'),
      {
        domain: environment.COOKIE_DOMAIN,
      }
    );
    this.cookieService.put(
      'projectDetails',
      JSON.stringify(this.proData.returnProjectEnv()),
      {
        domain: environment.COOKIE_DOMAIN,
      }
    );
    localStorage.setItem('editorVisited', 'true');
    window.open(url, '_self');
  }
}
