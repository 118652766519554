export const analyticsConst = {
  EVENTS: {
    ADMIN_LOGIN: 'admin_login',
    ADMIN_LOGIN_FAIL: 'admin_login_fail',
    ADMIN_SINGUP: 'admin_signup',
    ADMIN_SINGUP_FAIL: 'admin_signup_fail',
    ADMIN_DOWNLOAD_PROJECT_START: 'download_project_start',
    ADMIN_DOWNLOAD_PROJECT_FAIL: 'download_project_fail',
    ADMIN_DELETE_PROJECT: 'admin_delete_project',
    ADMIN_DELETE_PROJECT_FAIL: 'admin_delete_project_fail',
    ADMIN_DELETE_TEMPLATE: 'admin_delete_template',
    ADMIN_DELETE_TEMPLATE_FAIL: 'admin_delete_template_fail',
    ADMIN_EDIT_PROJECT: 'admin_edit_project',
    ADMIN_CREATE_PROJECT: 'admin_create_project',
    ADMIN_CREATE_PROJECT_STEP2: 'admin_create_project_step2',
    ADMIN_CREATE_PROJECT_STEP3: 'admin_create_project_step3',
    ADMIN_CREATE_PROJECT_STEP4: 'admin_create_project_step4',
    ADMIN_SELECTED_TEMPLATE: 'admin_select_template',
    ADMIN_CREATE_TEMPLATE: 'admin_create_template',
    ADMIN_CICD_SKIP: 'admin_cicd_skip',
    ADMIN_VCS_SKIP: 'admin_vcs_skip',
    ADMIN_SELECT_GITHUB_VCS: 'admin_select_github_vcs',
    ADMIN_SELECT_GITACTION_CICD: 'admin_select_gitaction_cicd',
    ADMIN_PROJECT_SUBMIT: 'admin_project_submit',
    ADMIN_TEMPLATE_SUBMIT_DRAFT: 'admin_template_submit_draft',
    ADMIN_TEMPLATE_SUBMIT_REVIEW: 'admin_template_submit_review',
    ADMIN_TEMPLATE_SUBMIT_DRAFT_FAIL: 'admin_template_submit_draft_fail',
    ADMIN_TEMPLATE_SUBMIT_REVIEW_FAIL: 'admin_template_submit_review_fail',
    ADMIN_EDIT_PROJECT_CONFIG: 'admin_edit_project_config',
    ADMIN_HELP_CENTER: 'admin_help_center',
    ADMIN_LOGOUT: 'admin_logout',
    ADMIN_EDIT_ON_CANVAS: 'admin_edit_on_canvas',
    ADMIN_CREATE_PROJECT_FROM_SCRATCH: 'admin_create_project_from_scratch',
  },
  PARAMS: {
    USERNAME: 'username',
    TIME: 'time',
    PROJECT_ID: 'project_id',
    SUBMIT_TEMPLATE_ID: 'submit_template_id',
    EDIT_PROJECT_ID: 'edit_project_id',
    EDIT_PROJECT_NAME: 'edit_project_name',
    LOGIN_TIME: 'login_time',
    SINGUP_TIME: 'signup_time',
    SELECT_TEMPLATE_ID: 'select_template_id',
    SELECT_TEMPLATE_NAME: 'select_template_name',
    DELETE_TEMPLATE_ID: 'delete_template_id',
    DELETE_PROJECT_ID: 'delete_project_id',
    DOWNLOAD_TIME: 'download_time',
    SUBMIT_PROJECT_ID: 'submit_project_id',
    LOGOUT_TIME: 'logout_time',
  },
};
