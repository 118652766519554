<div class="container-fluid">
  <div class="row no-gutter">
    <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
    <div class="col-md-8 col-lg-6">
      <div class="login d-flex align-items-center py-5">
        <div class="container">
          <div class="row">
            <div class="col-md-9 col-lg-8 mx-auto">
              <h3 class="login-heading">
                {{ "auth.loginPage.forgot_password" | translate }}
              </h3>
              <form
                #requestPasswordForm="ngForm"
                (ngSubmit)="requestPasswordSubmit()"
              >
                <div class="form-label-group mb-3">
                  <label for="inputEmail">{{
                    "auth.loginPage.email" | translate
                  }}</label>
                  <input
                    type="email"
                    [(ngModel)]="email"
                    pattern="[a-z0-9._]+@(?:impetus.com|impetus.co.in)$"
                    required
                    #emailField="ngModel"
                    name="email"
                    [ngClass]="{
                      'is-invalid': emailField.dirty && emailField.invalid
                    }"
                    id="inputEmail"
                    class="form-control placeholders"
                    placeholder="{{ 'common.message.enter_email' | translate }}"
                  />

                  <div
                    *ngIf="emailField.invalid && emailField.dirty"
                    class="error-text"
                  >
                    <div *ngIf="emailField.errors.required">
                      {{
                        "common.validation_message.email_required" | translate
                      }}
                    </div>
                    <div *ngIf="emailField.errors.pattern">
                      {{
                        "common.validation_message.email_incorrect" | translate
                      }}
                    </div>
                  </div>
                </div>

                <button
                  [disabled]="!requestPasswordForm.form.valid || isRequesting"
                  class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold login-size"
                  type="submit"
                >
                  {{ "common.button.submit" | translate }}
                  <span
                    *ngIf="isRequesting"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
