<div
  id="nav-container"
  [ngClass]="{ 'sidenav-toggled': isCollapsed, sidenav: true }"
>
  <!-- <nav class="sb-sidenav accordion" id="sidenavAccordion"> -->
  <!-- <div class="container-fluid"> -->
  <div class="row-2 first-row-height remove-padding">
    <div class="col-sm-11 remove-padding setWidth">
      <div class="icon-container">
        <a routerLink="/portal/dashboard">
          <img
            *ngIf="!isCollapsed"
            src="../../assets/icons/logo.svg"
            class="kloudjet-expanded-icon"
          />
          <img
            *ngIf="isCollapsed"
            src="../../assets/icons/app-icon.svg"
            class="kloudjet-collapsed-icon"
          />
        </a>
      </div>
    </div>
    <!-- <div class="col-sm-1 remove-padding">
                    <div class="icon-container2"></div>
                </div> -->
  </div>
  <div class="row-10 second-row-height remove-padding">
    <div class="col-sm-11 remove-padding setWidth">
      <div class="sidenav-content-container sidenav-bg-color">
        <!-- child components for admin & editor side nav contents can be put here -->
        <app-side-nav-admin [isCollapsed]="isCollapsed"></app-side-nav-admin>
      </div>
    </div>
    <div class="remove-padding collapse-column">
      <div
        class="collapse-icon-container"
        (click)="toggleSideNav()"
        data-toggle="collapse"
        data-target="#nav-container"
      >
        <img
          *ngIf="!isCollapsed"
          src="../../assets/icons/collapse-arrow.svg"
          class="collapse-icon"
        />
        <img
          *ngIf="isCollapsed"
          src="../../assets/icons/expand-arrow.svg"
          class="collapse-icon"
        />
      </div>
    </div>
  </div>
  <!-- </div> -->
  <!-- </nav> -->
</div>
