import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-tags-output',
  templateUrl: './tags-output.component.html',
  styleUrls: ['./tags-output.component.scss'],
})
export class TagsOutputComponent implements OnInit {
  @Output() outgress = new EventEmitter();
  tagItems: any = [];
  @Input() outboundRuleData: any = [];

  constructor(private http: HttpClient) {
    //this.GetTemplateList();
  }
  outboundItem: any = [];
  componentOutboundRule = [];
  comp: any = [];
  @Input() data: any;
  ngOnInit(): void {
    this.comp = this.data;
    //console.log(this.comp);

    this.http.get(environment.TEMPLATE_LIST).subscribe((data) => {
      this.tagItems = data['body'];

      if (this.outboundRuleData && this.outboundRuleData[0]) {
        this.outboundRuleData.forEach((element) => {
          if (element.componentId == this.comp.id) {
            this.tagItems.forEach((ele) => {
              if (ele.id == element.allowedComponentId) {
                this.outboundItem.push(ele);
                return;
              }
            });
          }
        });
      }
    });
  }
  GetTemplateList() {
    this.http
      .get(environment.TEMPLATE_LIST)
      .subscribe((data) => (this.tagItems = data['body']));
  }
  onSelected($event: any) {
    // console.log(this.outboundItem);
    // console.log('Printing outbound array....', this.outboundItem);
    // console.log('Outboundcomponent selected is:', this.comp);

    this.outboundItem.forEach((element) => {
      // console.log('Printing element', element);
      let isRuleExist = this.componentOutboundRule.find(
        (o) =>
          o.componentId == this.comp.id && o.allowedComponentId == element.id
      );
      if (!isRuleExist && !this.comp.ingress?.includes(element.id)) {
        this.componentOutboundRule.push({
          componentId: this.comp.id,
          componentName: this.comp.label,
          ruleType: 'Outbound',
          allowedComponentId: element.id,
          allowedComponentName: element.label,
        });
      }
    });
    // console.log('Printing complete rule', this.componentOutboundRule);
    this.outgress.emit(this.componentOutboundRule);
  }
}
