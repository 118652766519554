<div class="sb-nav-fixed">
  <app-header [showExpandedView]="showExpandedView"></app-header>

  <div id="layoutSidenav">
    <app-side-nav-container
      (sendCollapsedStatus)="checkCollapseStatus($event)"
    ></app-side-nav-container>
    <div
      id="layoutSidenav_content"
      [ngClass]="{
        'shrinked-view': !showExpandedView,
        'expanded-view': showExpandedView
      }"
    >
      <main>
        <div class="container-fluid">
          <div class="row project-button">
            <div class="mr-auto p-2">
              <h2 class="font30">{{ "common.create_project" | translate }}</h2>
            </div>
          </div>

          <div id="stepper1" class="bs-stepper">
            <app-stepper></app-stepper>
            <div *ngIf="apiLoading" class="d-flex justify-content-center">
              <div class="loader-create" role="status">
                <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
              </div>
            </div>
            <div class="bs-stepper-content">
              <div id="test-l-2" *ngIf="!apiLoading">
                <form (ngSubmit)="onSubmit()">
                  <div
                    class="form-group d-flex flex-column justify-content-center"
                  >
                    <div class="row">
                      <div class="placeholders">
                        <p class="text-muted headings">
                          {{ "common.project_info" | translate }}
                        </p>
                        <table class="table table-dark">
                          <tbody style="border: black 1px solid">
                            <tr>
                              <th scope="row" class="text-left">
                                {{ "common.project_name" | translate }}
                              </th>
                              <td class="text-left">{{ project.name }}</td>
                            </tr>

                            <tr>
                              <th scope="row" class="text-left">
                                {{ "common.selected_template" | translate }}
                              </th>
                              <td class="text-left">
                                {{ project.templateLabel }}
                                <span *ngIf="!project.templateLabel"
                                  >Created From Scratch</span
                                >
                              </td>
                            </tr>

                            <tr>
                              <th scope="row" class="text-left">
                                {{ "common.env" | translate }}
                              </th>
                              <td class="text-left">
                                <tr *ngFor="let e of env">
                                  {{
                                    e.env
                                  }}
                                  deployed on
                                  {{
                                    e.source_branch
                                  }}
                                </tr>
                              </td>
                            </tr>

                            <tr>
                              <th scope="row" class="text-left">
                                Source Provider
                              </th>
                              <td class="text-left">
                                <span
                                  *ngIf="
                                    project.allProjectComponents[0]
                                      .sourceProvider == 'GITHUB'
                                  "
                                  >GitHub</span
                                >
                                <span
                                  *ngIf="
                                    !project.allProjectComponents[0]
                                      .sourceProvider
                                  "
                                  >Not Provided</span
                                >
                              </td>
                            </tr>

                            <tr>
                              <th scope="row" class="text-left">
                                {{ "common.ci_cd" | translate }}
                              </th>
                              <td class="text-left">
                                <span
                                  *ngIf="
                                    project.allProjectComponents[0]
                                      .cicdProvider == 'GITHUB_ACTION'
                                  "
                                  >GitHub Actions</span
                                >
                                <span
                                  *ngIf="
                                    !project.allProjectComponents[0]
                                      .cicdProvider
                                  "
                                  >Not Provided</span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="accordion" id="accordionExample">
                      <p class="sub-headings">Components Configuration</p>
                      <div
                        class="accordion-item"
                        *ngFor="
                          let comp of project.allProjectComponents;
                          let i = index
                        "
                      >
                        <h2 class="accordion-header" [id]="'headingOne' + i">
                          <button
                            class="accordion-button"
                            [ngClass]="{ 'collapsed': activeAccordion !== i }"
                            type="button"
                            (click)="toggleAccordion(i)"
                            [attr.data-bs-target]="'#collapseOne' + i"
                            [attr.aria-expanded]="activeAccordion === i"
                            [attr.aria-controls]="'collapseOne' + i"
                          >
                            {{ comp.displayName }}
                          </button>
                        </h2>
                        <div
                          [id]="'collapseOne' + i"
                          class="accordion-collapse collapse"
                          [ngClass]="{ 'show': activeAccordion === i }"
                          [attr.aria-labelledby]="'headingOne' + i"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <table class="table table-dark">
                              <tbody style="border: black 1px solid; font-size: 14px">
                                <tr>
                                  <th style="width: 19vw" scope="row" class="text-left">
                                    Repo Name
                                  </th>
                                  <td class="text-left">{{ comp.repoName }}</td>
                                </tr>
                                <tr *ngFor="let item of configData[comp.componentId] | keyvalue">
                                  <th style="width: 19vw" scope="row" class="text-left">
                                    {{ config[comp.componentId][item.key] }}
                                  </th>
                                  <td class="text-left" *ngIf="(item.value | typeof) != 'object'">
                                    {{ item.value }}
                                  </td>
                                  <td class="text-left" *ngIf="(item.value | typeof) === 'object'">
                                    {{ item.value | json }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="edit_button">
                              <button class="btn btn-primary mt-3" (click)="editProject()">
                                {{ "common.button.edit" | translate }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    

                    <div class="row artifacts">
                      <p class="headings">Artifacts</p>
                      <ul ngbNav #nav="ngbNav" class="nav nav-tabs" [destroyOnHide]="false">
                        <li [ngbNavItem]="1">
                          <a class="nav-link" ngbNavLink>
                            <p class="placeholders">
                              {{ "common.architecture_diagram" | translate }}
                            </p>
                          </a>
                          <ng-template ngbNavContent>
                            <img
                              id="selected-tmp-arch-img"
                              [src]="project.architectureDiagram"
                            />
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                          <a class="nav-link" ngbNavLink>
                            <p class="placeholders">
                              {{ "common.deployment_diagram" | translate }}
                            </p>
                          </a>
                          <ng-template ngbNavContent>
                            <img
                              id="selected-tmp-dep-img"
                              [src]="project.deploymentDiagram"
                            />
                          </ng-template>
                        </li>
                      </ul>
                    </div>
                    <div [ngbNavOutlet]="nav"></div>
                    
                    <div>
                      <button
                        (click)="saveProjectName()"
                        class="btn btn-primary mt-5"
                      >
                        {{ "common.button.submit" | translate
                        }}<span
                          *ngIf="submitting"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                      <!-- <button
                        type="submit"
                        (click)="prev()"
                        class="btn btn-primary mt-5 mr-3"
                        style="margin-right: 3px"
                      >
                        {{ "common.button.previous" | translate }}
                      </button> -->
                    </div>
                  </div>
                </form>

                <br />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
