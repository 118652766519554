import { Component, OnInit } from '@angular/core';
import { UserLoginService } from '../services/user-login.service';
import { UserService } from '../../shared-services/user/user.service';
import { User } from '../../shared-services/user/user';
import { Router } from '@angular/router';
import { NotifierToastService } from '../../shared-services/notifier-toast.service';

@Component({
  selector: 'app-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss'],
})
export class RequestPasswordComponent implements OnInit {
  email: string;
  submittingForm = false;
  isRequesting: boolean = false;
  successMessgae = '';
  isPassworedSend = false;

  constructor(
    private router: Router,
    private userLoginService: UserLoginService,
    private userService: UserService,
    private notifierToast: NotifierToastService
  ) {}

  ngOnInit(): void {
    if (this.userService.isUserLoggedIn()) {
      this.router.navigate(['/portal/dashboard']);
    }
  }

  showSuccessNotification(data) {
    this.notifierToast.showNotification('success', data);
  }

  showErrorNotification(data) {
    this.notifierToast.showNotification('error', data);
  }

  requestPasswordSubmit() {
    this.isRequesting = true;
    this.userLoginService.forgotPassword(this.email).subscribe(
      (data) => {
        this.isRequesting = false;
        this.isPassworedSend = true;
        this.successMessgae = 'Please check you Email inbox !!!';
        this.showSuccessNotification('Please check you Email inbox !!!');
        setTimeout((_) => {
          this.router.navigate(['/auth/login']);
        }, 2000);
      },
      (err) => {
        this.isRequesting = false;
        this.isPassworedSend = false;
        // alert(err.message);
        this.showErrorNotification(err.message);
      }
    );
  }
}
