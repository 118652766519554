import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProjectDataService } from './project-data.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private httpClient: HttpClient) {}

  public getAllProjectTypes() {
    return this.httpClient.get(environment.PROJECT_TYPES_GET);
  }

  public getDomainsbyProjectType(projectTypeId) {
    return this.httpClient.get(
      environment.GET_DOMAINS_BY_PROJECT_TYPE + projectTypeId
    );
  }

  public getTemplatebyDomainAndProjectType(
    domainId,
    projectTypeId,
    defaultValue
  ) {
    const opts = {
      params: new HttpParams({
        fromString:
          'domainId=' +
          domainId +
          '&projectTypeId=' +
          projectTypeId +
          '&isDefault=' +
          defaultValue,
      }),
    };
    return this.httpClient.get(environment.GET_TEMPLATE, opts);
  }

  public getAllTemplates(defaultValue) {
    const opts = {
      params: new HttpParams({ fromString: '&isDefault=' + defaultValue }),
    };
    return this.httpClient.get(environment.GET_TEMPLATE, opts);
  }

  public saveProject(
    projectName: string,
    userId: string,
    templateId: string,
    env: any
  ) {
    return this.httpClient.put(environment.CREATE_PROJECT, {
      name: projectName,
      userId: userId,
      templateId: templateId,
      env: env,
    });
  }

  public getConfigByCompId(componentIds: any) {
    return this.httpClient.post(environment.GET_CONFIG_BY_COMPONENT_ID, {
      components: componentIds,
    });
  }
  public saveConfig(
    userId,
    projectId,
    ConfigJson,
    components,
    repoData,
    usageData
  ) {
    let obj = {
      components: components,
      projectId: projectId,
      createdBy: userId,
      configDetails: ConfigJson,
      repoName: repoData,
      displayName: usageData,
    };
    return this.httpClient.put(environment.UPDATE_COMP_BY_PROJECT, obj);
  }

  public fetchPro(id: any) {
    return this.httpClient.get(environment.GET_PROJECT_WITH_COMP + id);
  }

  public updateSource(
    userId,
    projectId,
    components,
    token,
    tokenType,
    sourceProvider
  ) {
    let obj = {
      components: components,
      projectId: projectId,
      createdBy: userId,
      token: token,
      tokenType: tokenType,
      sourceProvider: sourceProvider,
    };
    return this.httpClient.put(environment.UPDATE_COMP_BY_PROJECT, obj);
  }

  public updatecicd(userId, projectId, components, cicdProvider) {
    let obj = {
      components: components,
      projectId: projectId,
      createdBy: userId,
      cicdProvider: cicdProvider,
    };
    return this.httpClient.put(environment.UPDATE_COMP_BY_PROJECT, obj);
  }

  public getProjectConfig(id: string) {
    let obj = {
      id: id,
      components: Object.keys(
        JSON.parse(localStorage.getItem('projectComponent'))
      ),
    };
    return this.httpClient.post(environment.GET_PROJECT_WITH_COMP_CONFIG, obj);
  }

  public updateProjectName(name: String) {
    return this.httpClient.put(environment.UPDATE_PROJ, {
      name: name,
      projectId: localStorage.getItem('editId'),
      userId: JSON.parse(localStorage.getItem('loggedInUser')).id,
      template: {},
    });
  }

  public saveSecret(obj) {
    return this.httpClient.post(environment.SAVE_SECRET, obj);
  }
}
