import { Injectable } from '@angular/core';
import { User } from './user';
import { LocalStorageService } from '../../shared-services/local-storage.service';
import { environment } from '../../../environments/environment';
import * as AWS from 'aws-sdk';
import { CookieService } from 'ngx-cookie';
import { analyticsConst } from '../../shared-services/analytics-constants';
import { AnalyticsService } from '../../shared-services/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  loggedInUser: User;

  constructor(
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private analytics: AnalyticsService
  ) {
    // Get User From this.localStorageService
    if (
      this.localStorageService.getItem('loggedInUser') &&
      this.localStorageService.getItem('loggedInUser') != null
    ) {
      this.loggedInUser = JSON.parse(
        this.localStorageService.getItem('loggedInUser')
      );
    } else {
      this.loggedInUser = new User();
    }

    // Set Default Image
    // if (!this.loggedInUser.profilePic) {
    //   this.loggedInUser.profilePic =
    //     environment.ASSETS_URL + '/assets/svg/gender-neutral-avatar-new.svg';
    // }
  }

  public logout(): void {
    this.loggedInUser = undefined;
    this.localStorageService.removeItem('loggedInUser');
    this.localStorageService.removeItem('asc-creds');
    this.cookieService.removeAll();
    let param = {};
    param[analyticsConst.PARAMS.LOGOUT_TIME] = new Date();
    this.analytics.logEvent(analyticsConst.EVENTS.ADMIN_LOGOUT, param);
    try {
      if (
        (AWS.config.credentials as AWS.CognitoIdentityCredentials) &&
        (AWS.config.credentials as AWS.CognitoIdentityCredentials).clearCachedId
      ) {
        (
          AWS.config.credentials as AWS.CognitoIdentityCredentials
        ).clearCachedId();
      }
    } catch (e) {}
  }

  public isUserLoggedIn(): boolean {
    if (
      this.localStorageService.getItem('loggedInUser') != null &&
      this.loggedInUser &&
      this.loggedInUser.email
    ) {
      // verfy asc-creds
      const awsCreds = this.localStorageService.getItem('asc-creds');
      if (awsCreds) {
        let credentials: any = atob(awsCreds);
        try {
          credentials = JSON.parse(decodeURIComponent(credentials));
          if (credentials.et) {
            const date = new Date(credentials.et);
            if (date.getTime() > Date.now()) {
              return true;
            }
          }
        } catch (error) {
          // Invalid Credentials
        }
      }
    }
    return false;
  }

  public setLoggedInUser(user: User): void {
    if (user.email && user.id) {
      this.loggedInUser = user;
      this.localStorageService.setItem('loggedInUser', JSON.stringify(user));
    }
  }
}
