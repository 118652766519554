import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardServiceGuard  {
  constructor(private Authservice: AuthService, private router: Router) {}
  canActivate(): boolean {
    const existingLocalCreds =
      this.Authservice.getExistingAWSCredsFromStorage();
    //console.log('gaurd');
    if (
      !this.Authservice.isValidAWSCredentials(existingLocalCreds) ||
      !localStorage.getItem('loggedInUser')
    ) {
      this.Authservice.setCognitoCredentials();
      this.Authservice.getAWSTokens();
      this.router.navigateByUrl('auth/login');
      return false;
    } else {
      return this.Authservice.isValidAWSCredentials(existingLocalCreds);
    }
  }
}
