<div class="outerDiv" *ngIf="type == 'project'">
  <div
    class="card button-list card-block shadow pointer"
    *ngFor="
      let template of receivedArray
        | filter : { search: searchText, searchfilter: platform }
    "
    (click)="getTemplateId(template.id)"
  >
    <ng-template #tipContent>
      {{ template.description }}
    </ng-template>
    <div class="templateLabel" [ngbTooltip]="tipContent" placement="top">
      <h3 class="item-card-title card-title margin-6">
        <b>{{ template.label }}</b>
      </h3>
    </div>

    <div class="align-row">
      <div *ngFor="let p of template.platform.split(',')">
        <img
          src="{{ platformObj[p.trim().toUpperCase()] }}"
          class="cloud-provider margin-6"
        />
      </div>
    </div>
    <div class="card-footer bg-transparent margin-6">
      <div class="technologyStack">
        <b>Technology Stack</b>
      </div>
      <p class="technologies">
        {{ template.technology }}
      </p>
    </div>
  </div>
</div>

<div class="outerDiv" *ngIf="type == 'myTemplate'">
  <div
    class="card card-detail card-block shadow dashboard-card"
    *ngFor="let item of receivedArray"
  >
    <ng-template #tipContent>{{ item.label }}</ng-template>
    <div [ngbTooltip]="tipContent" placement="top" class="projectHeight">
      <h3 class="item-card-title card-title mt-3 bottomMargin">
        <b>{{ item.label | slice : 0 : 30 }}</b
        ><b *ngIf="item.label.length > 30">...</b>
      </h3>
      {{ item.description }}
    </div>
    <div class="align-row">
      <div *ngFor="let p of item.platform.split(',')">
        <div *ngIf="platformObj.hasOwnProperty(p.trim().toUpperCase())">
          <img
            src="{{ platformObj[p.trim().toUpperCase()] }}"
            class="cloud-provider"
          />
        </div>
      </div>
      <div class="edited-time status">
        {{ "common.status" | translate }}:
        <p *ngIf="item.currentState == 'DRAFTED'">
          {{ "common.draft" | translate }}
        </p>
        <p *ngIf="item.currentState == 'ACTIVE'">
          {{ "common.published" | translate }}
        </p>
        <p *ngIf="item.currentState == 'PENDING_REVIEW'">
          {{ "common.pending_for_review" | translate }}
        </p>
        <p *ngIf="item.currentState == 'REJECTED'">
          {{ "common.rejected" | translate }}
        </p>
      </div>
    </div>

    <div class="card-footer bg-transparent">
      <ng-template #edit>{{ "common.edit_template" | translate }}</ng-template>
      <ng-template #delete>{{
        "common.delete_template" | translate
      }}</ng-template>
      <div>
        <div class="edited-time">
          <p *ngIf="compare(item.createdAt, item.updatedAt)">
            {{ "common.footer_card.created_on" | translate }}
            <br />{{ item.createdAt | date : "hh:mm a" }}
            {{ item.createdAt | date : " MMM dd, yyyy" }}
          </p>
          <p *ngIf="!compare(item.createdAt, item.updatedAt)">
            {{ "common.footer_card.updated_on" | translate }}
            <br />{{ item.updatedAt | date : "hh:mm a" }}
            {{ item.updatedAt | date : " MMM dd, yyyy" }}
          </p>
        </div>
        <div class="d-flex project-box-bottom">
          <img
            *ngIf="
              item.currentState == 'DRAFTED' &&
              (!deleting || deletingId != item.id)
            "
            class="icon pointer"
            [ngbTooltip]="delete"
            src="../../../assets/icons/delete.svg"
            (click)="deletetemplate(item.id)"
          />
          <div
            *ngIf="deleting && deletingId == item.id"
            class="icon spinner-border loader-create"
            role="status"
          ></div>
          <img
            *ngIf="item.currentState == 'DRAFTED'"
            class="icon pointer"
            src="../../../assets/icons/edit-disabled.svg"
            [ngbTooltip]="edit"
            placement="bottom-right"
          />
        </div>
      </div>
    </div>
  </div>
</div>
