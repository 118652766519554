import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared-services/user/user.service';
import { ICollapseStatus } from '../../portal/side-nav-container/side-nav-container.utils';
import { ProjectDataService } from '../project-services/project-data.service';
import { ProjectService } from '../project-services/project.service';
import { CookieService } from 'ngx-cookie';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/shared-services/analytics.service';
import { analyticsConst } from 'src/app/shared-services/analytics-constants';

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss'],
})
export class EnvironmentComponent implements OnInit {
  productForm: UntypedFormGroup;
  showExpandedView: boolean;
  project: any;
  env: any;
  apiLoading = false;
  submitDisabled = true;
  envDisabled = true;

  constructor(
    private fb: UntypedFormBuilder,
    private proData: ProjectDataService,
    private router: Router,
    public cookieService: CookieService,
    private analytics: AnalyticsService,
    private projectService: ProjectService,
    private userService: UserService
  ) {
    this.productForm = this.fb.group({
      name: '',
      env_array: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    // this.env_array().push(this.newEnv());
    this.cookieService.remove('projectId', {
      domain: environment.COOKIE_DOMAIN,
    });
    const cookieValue = localStorage.getItem('editorVisited');
    if (cookieValue == 'true') {
      localStorage.removeItem('editorVisited');
      this.router.navigate(['projects/create']);
    }
    this.env_array().push(this.initialEnv());
    this.env_array().push(this.initialEnv2());
    this.env_array().push(this.newEnv());
  }

  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }

  env_array(): UntypedFormArray {
    return this.productForm.get('env_array') as UntypedFormArray;
  }

  newEnv(): UntypedFormGroup {
    return this.fb.group({
      env: '',
      source_branch: '',
    });
  }

  initialEnv(): UntypedFormGroup {
    return this.fb.group({
      env: 'dev',
      source_branch: 'develop',
    });
  }

  initialEnv2(): UntypedFormGroup {
    return this.fb.group({
      env: 'prod',
      source_branch: 'master',
    });
  }

  updateValue(i) {
    let last = this.productForm.value.env_array.length - 1;
    if (
      this.productForm.value.env_array[last].env.length >= 2 &&
      this.productForm.value.env_array[last].source_branch.length >= 2
    ) {
      this.env_array().push(this.newEnv());
    }
    this.checkEnv();
  }

  removeEnv(i: number) {
    this.env_array().removeAt(i);
    this.checkEnv();
  }

  filter() {
    for (let i = 0; i < this.productForm.value.env_array.length; i++) {
      if (
        this.productForm.value.env_array[i].env.length == 0 &&
        this.productForm.value.env_array[i].source_branch.length == 0
      ) {
        this.env_array().removeAt(i);
      }
    }
  }

  onSubmit() {
    this.filter();
    this.proData.saveProjectEnv(this.productForm.value);
    if (
      this.proData.returnSelectedTemp() &&
      this.proData.returnSelectedTemp().id
    ) {
      this.router.navigate([`/projects/create/artifacts`]);
    } else {
      this.editor();
    }
    // console.log(this.env);
  }
  prev() {
    this.router.navigate([`projects/create`]);
  }
  async editor() {
    await this.cookieService.remove('projectId', {
      domain: environment.COOKIE_DOMAIN,
    });
    await this.cookieService.remove('templateId', {
      domain: environment.COOKIE_DOMAIN,
    });
    await this.cookieService.remove('loggedInUser', {
      domain: environment.COOKIE_DOMAIN,
    });
    await this.cookieService.remove('tempTemplate', {
      domain: environment.COOKIE_DOMAIN,
    });
    await this.cookieService.remove('projectDetails', {
      domain: environment.COOKIE_DOMAIN,
    });
    const url = environment.EDITOR_URL;
    let param = {};
    param[analyticsConst.PARAMS.TIME] = new Date();
    param[analyticsConst.PARAMS.USERNAME] = JSON.parse(
      localStorage.getItem('loggedInUser')
    ).email;
    this.analytics.logEvent(
      analyticsConst.EVENTS.ADMIN_CREATE_PROJECT_FROM_SCRATCH,
      param
    );
    this.cookieService.put(
      'loggedInUser',
      localStorage.getItem('loggedInUser'),
      {
        domain: environment.COOKIE_DOMAIN,
      }
    );
    this.cookieService.put(
      'projectDetails',
      JSON.stringify(this.proData.returnProjectEnv()),
      {
        domain: environment.COOKIE_DOMAIN,
      }
    );
    localStorage.setItem('editorVisited', 'true');
    window.open(url, '_self');
  }

  checkLenght(val: string) {
    this.checkEnv();
    if (val.length > 1) {
      this.submitDisabled = false;
    } else {
      this.submitDisabled = true;
    }
  }

  checkEnv() {
    let a = this.env_array().value;
    if (a.length == 0) {
      // console.log('0', true);
      this.envDisabled = true;
    } else if (
      a.length == 1 &&
      a[0].source_branch.trim() == '' &&
      a[0].env.trim() == ''
    ) {
      // console.log('1', true);
      this.envDisabled = true;
    } else
      for (let i = 0; i < a.length; i++) {
        if (a[i]?.env != '' && a[i]?.source_branch != '') {
          this.envDisabled = false;
          // console.log('for', false);
        } else if (
          (a[i]?.source_branch == '' && a[i]?.env != '') ||
          (a[i]?.env == '' && a[i]?.source_branch != '')
        ) {
          // console.log('for', true);
          this.envDisabled = true;
          break;
        }
      }
  }
}
