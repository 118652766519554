<div *ngIf="apiLoading" class="d-flex justify-content-center">
  <div class="loader-create" role="status">
    <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
  </div>
</div>
<div *ngIf="!apiLoading" class="code-management">
  <div class="accordion comp-accordion" id="accordionExample">
    <div
      class="accordion-item"
      *ngFor="
        let comp of pro.allProjectComponents | orderBy: 'displayName';
        let i = index
      "
    >
      <h2 class="accordion-header" [id]="'headingCode' + i">
        <button
          class="accordion-button"
          [ngClass]="{ 'collapsed': activeAccordion !== i }"
          type="button"
          (click)="toggleAccordion(i)"
          [attr.data-bs-target]="'#collapseCode' + i"
          [attr.aria-expanded]="activeAccordion === i"
          [attr.aria-controls]="'collapseCode' + i"
        >
          {{ comp.displayName }}
        </button>
      </h2>
      <div
        [id]="'collapseCode' + i"
        class="accordion-collapse collapse"
        [ngClass]="{ 'show': activeAccordion === i }"
        [attr.aria-labelledby]="'headingCode' + i"
        data-bs-parent="#accordionExample"
        style=""
      >
        <div class="accordion-body">
          <!--providers-->
          <div class="providers">
            <p class="headings">Providers</p>
            <div class="provider-body">
              <div *ngIf="comp.sourceProvider == 'GITHUB'">
                <img
                  class="git-svgs"
                  src="../../../../assets/images/github-svgrepo-com.svg"
                />
                <p class="displayName">GitHub</p>
              </div>
              <div *ngIf="comp.sourceProvider == 'GITLAB'">
                <img
                  class="git-svgs lab-svg"
                  src="./../../../assets/images/gitlab-svgrepo-com.svg"
                />
                <p class="displayName">GitLab</p>
              </div>
              <div *ngIf="comp.sourceProvider == 'BITBUCKET'">
                <img
                  class="git-svgs lab-svg"
                  src="./../../../assets/images/bitbucket-svgrepo-com.svg"
                />
                <p class="displayName">BitBucket</p>
              </div>
              <p class="provider-name" *ngIf="comp.cicdProvider == null">
                Not Provided
              </p>
            </div>
          </div>
          <!--code branches-->
          <div class="providers">
            <p class="headings">Code Branches</p>
            <p
              class="provider-name name-chip"
              *ngFor="let name of branches; let i = index"
            >
              {{ name }}
            </p>
          </div>
          <div class="providers">
            <p class="headings">Environment</p>
            <p
              class="provider-name name-chip"
              *ngFor="let name of env; let i = index"
            >
              {{ name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
