import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../shared-services/user/user.service';
import { Router } from '@angular/router';
import { ICollapseStatus } from '../../portal/side-nav-container/side-nav-container.utils';
import { analyticsConst } from '../../shared-services/analytics-constants';
import { AnalyticsService } from '../../shared-services/analytics.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false;
  loggedInUserName: string;
  @Input() showExpandedView: boolean;
  href: string;
  constructor(
    private userService: UserService,
    private router: Router,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.loggedInUserName = this.userService.loggedInUser.name;
    $(document).ready(() => {
      // Add active state to sidbar nav links
      const path = window.location.href; // because the 'href' property of the DOM element is the absolute path
      $('#sidenavsubmenu #layoutSidenav_nav .sb-sidenav a.nav-link').each(
        () => {
          if (this.href === path) {
            $(this).addClass('active');
          }
        }
      );

      // Toggle the side navigation
      // $('#sidebarToggle').on('click', (e) => {
      //   e.preventDefault();
      //   $('body').toggleClass('sb-sidenav-toggled');
      // });
    });
  }

  userLogout(): void {
    this.userService.logout();
    this.router.navigate(['/auth/login']);
  }

  createProject(): void {
    let param = {};
    param[analyticsConst.PARAMS.TIME] = new Date();
    this.analytics.logEvent(analyticsConst.EVENTS.ADMIN_CREATE_PROJECT, param);
  }
}
