import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ProjectDataService } from '../project/project-services/project-data.service';
import { ProjectService } from '../project/project-services/project.service';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss'],
})
export class RedirectionComponent implements OnInit {
  constructor(
    private proService: ProjectService,
    private proData: ProjectDataService,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit(): void {
    const proId = localStorage.getItem('proId');
    console.log(this.router.url.split('code=')[1]);
    this.redirect().subscribe((data) => {
      console.log(data);
      this.proService
        .updateSource(
          JSON.parse(localStorage.getItem('loggedInUser')).id,
          localStorage.getItem('proId'),
          JSON.parse(localStorage.getItem('projectComponent')),
          data,
          'GITHUB',
          'GITHUB'
        )
        .subscribe((data) => {
          console.log(data);
          this.router.navigate([
            `/projects/create/${localStorage.getItem('proId')}/cicd`,
          ]);
        });
    });
  }

  redirect() {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Headers': 'X-Requested-With',
      'X-OAuth-Scopes': 'repo,workflow',
      'X-Accepted-OAuth-Scopes': 'repo,workflow',
      Accept: 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    });
    //  this.router.navigate([`/projects/create/${proId}/cicd`]);
    return this.http.post(
      'https://n2ye8qds27.us-east-1.awsapprunner.com/https://github.com/login/oauth/access_token',
      {
        client_id: environment.CLIENT_ID,
        client_secret: environment.CLIENT_SECRET,
        code: `${this.router.url.split('code=')[1]}`,
      },
      {
        headers: headers,
      }
    );
  }
}
