import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonParse',
})
export class JsonParsePipe implements PipeTransform {
  transform(str: string, ...args: unknown[]): any {
    if (JSON.parse(str)) {
      return JSON.parse(str);
    }
    str = str.replace(/'|"/g, (v) => {
      if (v == "'") {
        return '"';
      } else {
        return "'";
      }
    });
    return JSON.parse(str);
  }
}
