import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

export interface canComponentLeave {
  canLeave: () => Observable<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard  {
  canDeactivate(component: canComponentLeave): Observable<boolean> | boolean {
    if (component.canLeave) {
      return component.canLeave();
    }
    return true;
  }
}
