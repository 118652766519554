import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared-services/user/user.service';

import { analyticsConst } from '../../shared-services/analytics-constants';
import { AnalyticsService } from '../../shared-services/analytics.service';
@Component({
  selector: 'app-side-nav-admin',
  templateUrl: './side-nav-admin.component.html',
  styleUrls: ['./side-nav-admin.component.scss'],
})
export class SideNavAdminComponent implements OnInit {
  @Input() isCollapsed = false;
  selectedElementId: string;
  currentSplittedRoute: string;
  currentYear: number;
  constructor(
    private userService: UserService,
    private router: Router,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('portal')) {
      this.currentSplittedRoute = this.router.url.split('portal/')[1];
    }
    if (this.router.url.includes('project')) {
      this.currentSplittedRoute = 'project';
    }

    this.currentYear = new Date().getFullYear();
  }

  setCurrentlySelectedDivId(elementId: string): void {
    let param = {};
    param[analyticsConst.PARAMS.TIME] = new Date();
    this.analytics.logEvent(analyticsConst.EVENTS.ADMIN_CREATE_PROJECT, param);
    this.selectedElementId = ''; // empty string for route based guard changes
  }

  userLogout(): void {
    this.userService.logout();
    this.router.navigate(['/auth/login']);
  }
}
