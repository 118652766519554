export const environment = {
  production: false,
  region: 'us-east-1',

  identityPoolId: 'us-east-1:34b75940-e9f3-4edc-9df7-3ac8a39f6a54',
  unAuthRoleARN:
    'arn:aws:iam::507831496546:role/kloudjet-infra-identity-de-CognitoUnAuthorizedRole-1U72WGEH59UW8',
  authRoleARN:
    'arn:aws:iam::507831496546:role/kloudjet-infra-identity-dev-CognitoAuthorizedRole-SUIXQ5W7P2Z',
  userPoolId: 'us-east-1_LmZBectnO',
  clientId: '3od2i3dabtfo143kro6psui58j',
  getIdPoolLogins(userRole?: string) {
    switch (userRole) {
      default:
        return (
          'cognito-idp.' + this.region + '.amazonaws.com/' + this.userPoolId
        );
    }
  },
  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  ASSETS_URL: 'https://assets.ascendmedia.online',
  TWELVE_HOURS_MILLI: 4.32e7,
  API_HOST: '.execute-api.us-east-1.amazonaws.com',
  GET_DOWNLOAD_JOBS:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/get/all',
  GET_JOB_STATUS:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/job-status-by-jids',
  INITIATE_DOWNLOAD:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/initiate-job/',
  GET_LOGO:
    'https://kloudjet-admin-portal-storage-dev.s3.amazonaws.com/diagram/logo.jpg',
  DASHBOARD_ALL_GET:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/get/all/$username',
  PROJECT_TYPES_GET:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/projectType/get/all/',
  GET_DOMAINS_BY_PROJECT_TYPE:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/domain-by-project/',
  GET_TEMPLATE:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template-by-project-domain/get',
  CREATE_PROJECT:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/create', // /project/create
  TEMPLATE_LIST:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/getAllTemplateWithRule',
  DIAGRAM_MAP:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/get/',
  UPDATE_MAP:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/saveEditTemplate',
  TEMPLATE_URL:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/adminCreateTemplate',
  NEW_COMPONENT_URL:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/createBulkTemplateComponent',
  UPDATE_TEMPLATE:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/update',
  BULK_CREATE_COMPONENT_RULE:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/createTemplateComponentRuleBulk',
  CREATE_TEMPLATE:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/adminCreateTemplate',
  GET_ASSESSMENT:
    'https://itrh1jrsxd.execute-api.us-east-1.amazonaws.com/dev/v1/report/$user',
  SURVEY_DATA:
    'https://itrh1jrsxd.execute-api.us-east-1.amazonaws.com/dev/v1/survey',
  KLOUDJET_STORAGE_BUCKET: 'kloudjet-admin-portal-storage-dev',
  KLOUDJET_S3_URL:
    'https://kloudjet-admin-portal-storage-dev.s3.amazonaws.com/',
  GET_USER:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/getuser/',
  DELETE_PROJECT:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/delete',
  DRAFT_TEMPLATES:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/getDraftTemplateByUserId',
  DELETE_DRAFT_TEMPLATE:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/removeDraftTemplate',
  GENERATE_SIGNED_S3_URL:
    'https://zh222iv0fb.execute-api.us-east-1.amazonaws.com/dev/v1/template/generateSignedS3Url',
  GET_CONFIG_BY_COMPONENT_ID:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/getConfigurationByComponentId',
  UPDATE_COMP_BY_PROJECT:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/updateProjectComponentByProject',
  GET_PROJECT_WITH_COMP:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/getProjectWithComponent/',
  SAVE_PROJECT:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/saveProject',
  GET_PROJECT_WITH_COMP_CONFIG:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/getProjectWithComponentConfig',
  UPDATE_PROJ:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/project/saveEditProject',
  SAVE_SECRET:
    'https://3jxewdfyyl.execute-api.us-east-1.amazonaws.com/dev/v1/saveSecrets/saveSecretsForProjectComponent',
  REDIRECT_LINK:
    'https://github.com/login/oauth/authorize?redirect_uri=https://dev.kloudjet.com/portal/redirection&scope=repo,workflow&client_id=f4ae173e04da745eba17',
  CLIENT_ID: 'f4ae173e04da745eba17',
  CLIENT_SECRET: '66af86b75bdc509268e438df4b8409f7ed49bf48',
  //analytics
  apiKey: 'AIzaSyC_5SwwxSpmjU7WwTp0xt1gP8lgtpGnb10',
  authDomain: 'kloudjet-portal-dev.firebaseapp.com',
  projectId: 'kloudjet-portal-dev',
  storageBucket: 'kloudjet-portal-dev.appspot.com',
  messagingSenderId: '669722004359',
  appId: '1:669722004359:web:d8456c08ec6052a80fe122',
  measurementId: 'G-T1GKMJC7Z8',

  // for local host run editor on 5200
  // EDITOR_URL: 'http://localhost:5200/',
  // COOKIE_DOMAIN: '',
  // for development mode
  EDITOR_URL: 'https://dev.editor.kloudjet.com/',
  COOKIE_DOMAIN: '.kloudjet.com',
};
