<div class="sb-nav-fixed">
  <app-header [showExpandedView]="showExpandedView"></app-header>

  <div id="layoutSidenav">
    <app-side-nav-container
      (sendCollapsedStatus)="checkCollapseStatus($event)"
    ></app-side-nav-container>
    <div
      id="layoutSidenav_content"
      [ngClass]="{
        'shrinked-view': !showExpandedView,
        'expanded-view': showExpandedView
      }"
    >
      <main>
        <div class="container-fluid">
          <div class="row project-button">
            <div class="mr-auto p-2">
              <h2 class="font30">{{ "common.create_project" | translate }}</h2>
            </div>
          </div>

          <div id="stepper1" class="bs-stepper">
            <app-stepper></app-stepper>
            <div *ngIf="apiLoading" class="d-flex justify-content-center">
              <div class="loader-create" role="status">
                <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
                <!-- <span class="sr-only">{{ "common.loading" | translate }}</span> -->
              </div>
            </div>
            <div id="test-l-1">
              <div
                *ngIf="!apiLoading"
                class="form-group d-flex flex-column justify-content-center"
              >
                <br />
                <label class="heading-input" for="exampleInputEmail1"
                  >{{ "common.select_template" | translate }}
                  <div class="filter">
                    <div class="search-block">
                      <input
                        class="search-control regular-font-search-text"
                        type="text"
                        placeholder="Search"
                        [(ngModel)]="searchText"
                        name="searchText"
                        id="searchbar"
                        #searchbar
                      />
                      <button
                        class="close-button"
                        *ngIf="searchText.length"
                        (click)="searchClose()"
                        id="searchButton"
                      >
                        <span class="fa fa-close"></span>
                      </button>
                    </div>
                    <div class="filterInnerLeft">
                      <p class="filter-text">
                        {{ "common.filter_by" | translate }}:
                      </p>
                    </div>
                    <div class="filterInnerRight">
                      <select
                        [(ngModel)]="platform"
                        name="platform"
                        class="select custom-select"
                      >
                        <option
                          [selected]="true"
                          [ngValue]="all"
                          class="option"
                        >
                          All ({{ allTemplate.length }})
                        </option>
                        <option
                          value="{{ p }}"
                          class="option"
                          *ngFor="let p of Object.keys(platformArray)"
                        >
                          {{ p }} ({{ platformArray[p] }})
                        </option>
                      </select>
                    </div>
                  </div>
                </label>
                <div class="row" style="padding: 20px">
                  <app-scratch-card class="app_card" (click)="scratch()">
                  </app-scratch-card>
                  <app-card
                    class="app_card"
                    [type]="'project'"
                    [platform]="platform"
                    [searchText]="searchText"
                    (tempID)="selectedTemplateData($event)"
                    [receivedArray]="allTemplate"
                  >
                  </app-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
