<form #configForm="ngForm" (ngSubmit)="next()">
  <div class="sb-nav-fixed">
    <app-header [showExpandedView]="showExpandedView"></app-header>

    <div id="layoutSidenav">
      <app-side-nav-container (sendCollapsedStatus)="checkCollapseStatus($event)"></app-side-nav-container>
      <div id="layoutSidenav_content" [ngClass]="{
          'shrinked-view': !showExpandedView,
          'expanded-view': showExpandedView
        }">
        <main>
          <div class="container-fluid">
            <div class="row project-button">
              <div class="mr-auto p-2">
                <h2 class="font30">
                  {{ "common.create_project" | translate }}
                </h2>
              </div>
            </div>

            <div id="stepper1" class="bs-stepper">
              <app-stepper></app-stepper>
              <div *ngIf="apiLoading" class="d-flex justify-content-center">
                <div class="loader-create" role="status">
                  <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
                </div>
              </div>
              <div class="bs-stepper-content" *ngIf="!apiLoading">
                <div id="test-l-6">
                  <div class="component-name" *ngFor="let item of componentMap | keyvalue; let i = index">
                    <div>
                      <p class="comp_name">{{ item.value.name }}</p>
                      <div class="section1">
                        <div class="usage dropdown-input">
                          <p class="dynamic_head margin-0">Display name</p>
                          <input type="text" class="form-control section1-input" [value]="
                              formData[item.key]['usage']
                                ? formData[item.key]['usage']
                                : ''
                            " (input)="saveData('usage', $event, item.key)" />
                        </div>
                        <div class="repo-name dropdown-input">
                          <p class="dynamic_head margin-0">Repository</p>
                          <input type="text" class="form-control section1-input" [value]="
                              formData[item.key]['repo_name']
                                ? formData[item.key]['repo_name']
                                : ''
                            " (input)="saveData('repo_name', $event, item.key)" />
                        </div>
                      </div>
                      <div *ngIf="configLoading" class="d-flex justify-content-center">
                        <div class="loader-create" role="status">
                          <img src="../../assets/icons/new-loader.svg" alt="Loading..." />
                        </div>
                      </div>
                      <p class="configuration" *ngIf="
                          config[item.key] &&
                          config[item.key].length &&
                          config[item.key].length > 0
                        ">
                        Configuration
                      </p>
                      <div *ngFor="let con of config[item.key]">
                        <div class="dropdown-input" *ngIf="con.inputType == 'dropdown'">
                          <ng-template #tipContent>
                            {{ con.description }}
                          </ng-template>
                          <div class="dynamic_head margin-0">
                            {{ con.displayName }}
                            <span class="required-asterisk" *ngIf="con.isRequired ? true : false">*</span>
                            <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                              src="../../../assets/icons/help-about-svgrepo-com.svg" />
                          </div>
                          <select name="{{ con.configName }}" class="select custom-select"
                            [ngModel]="formData[item.key][con.configName]" [required]="con.isRequired ? true : false"
                            (change)="
                              saveData(con.configName, $event, item.key)
                            ">
                            <option value="" class="option" [selected]="
                                formData[item.key][con.configName] &&
                                formData[item.key][con.configName].length < 2
                              ">
                              Select {{ con.displayName }}
                            </option>
                            <option value="{{ p.value }}" class="option" [selected]="
                                formData[item.key][con.configName] &&
                                formData[item.key][con.configName] == p.value
                                  ? true
                                  : false
                              " *ngFor="let p of con.possibleValues | jsonParse">
                              {{ p.displayName }}
                            </option>
                          </select>
                        </div>
                        <div class="dropdown-input" *ngIf="con.inputType == 'text'">
                          <ng-template #tipContent>
                            {{ con.description }}
                          </ng-template>
                          <div class="dynamic_head margin-0">
                            {{ con.displayName }}
                            <span class="required-asterisk" *ngIf="con.isRequired ? true : false">*</span>
                            <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                              src="../../../assets/icons/help-about-svgrepo-com.svg" />
                          </div>
                          <input type="text" name="{{ con.configName }}" class="form-control section1-input"
                            [(ngModel)]="formData[item.key][con.configName]" #textField="ngModel" [ngClass]="{
                              'is-invalid': textField.dirty && textField.invalid
                            }" [required]="con.isRequired ? true : false"
                            (input)="saveData(con.configName, $event, item.key)" [value]="
                              formData[item.key][con.configName]
                                ? formData[item.key][con.configName]
                                : ''
                            " />
                        </div>
                        <div class="dropdown-input" *ngIf="con.inputType == 'checkbox'">
                          <ng-template #tipContent>
                            {{ con.description }}
                          </ng-template>
                          <div class="dynamic_head margin-0">
                            {{ con.displayName }}
                            <span class="required-asterisk" *ngIf="con.isRequired ? true : false">*</span>
                            <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                              src="../../../assets/icons/help-about-svgrepo-com.svg" />
                          </div>
                          <div class="checkbox-new-css">
                            <div *ngFor="let p of con.possibleValues | jsonParse">
                              <input class="form-check-input" id="{{ con.configName }}" type="checkbox"
                                [value]="p.value" name="{{ con.configName }}" [required]="con.isRequired ? true : false"
                                [checked]="
                                  formData[item.key][con.configName] &&
                                  formData[item.key][con.configName].length >=
                                    1 &&
                                  formData[item.key][con.configName] == p.value
                                " (click)="
                                  saveData(con.configName, $event, item.key)
                                " />
                              <span>{{ p.displayName }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="dropdown-input" *ngIf="con.inputType == 'radio'">
                          <ng-template #tipContent>
                            {{ con.description }}
                          </ng-template>
                          <div class="dynamic_head margin-0">
                            {{ con.displayName }}
                            <span class="required-asterisk" *ngIf="con.isRequired ? true : false">*</span>
                            <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                              src="../../../assets/icons/help-about-svgrepo-com.svg" />
                          </div>
                          <div class="radio-options">
                            <div *ngFor="let p of con.possibleValues | jsonParse">
                              <input class="form-check-input" id="{{ con.configName }}" type="radio" [value]="p.value"
                                [checked]="
                                  formData[item.key][con.configName] &&
                                  formData[item.key][con.configName].length >=
                                    1 &&
                                  formData[item.key][con.configName] == p.value
                                    ? p.value
                                    : ''
                                " name="{{ con.configName }}" [required]="con.isRequired ? true : false" (click)="
                                  saveDataRadio(
                                    con.configName,
                                    $event,
                                    item.key,
                                    p.value
                                  )
                                " />
                              <span>{{ p.displayName }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="dropdown-input" *ngIf="con.inputType == 'list_of_checkbox'">
                          <ng-template #tipContent>
                            {{ con.description }}
                          </ng-template>
                          <div class="dynamic_head margin-0">
                            {{ con.displayName }}
                            <span class="required-asterisk" *ngIf="con.isRequired ? true : false">*</span>
                            <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                              src="../../../assets/icons/help-about-svgrepo-com.svg" />
                          </div>
                          <div class="checkbox-new-css">
                            <div *ngFor="let p of con.possibleValues | jsonParse">
                              <input class="form-check-input" id="{{ con.configName }}" type="checkbox"
                                [value]="p.value" name="{{ con.configName }}" [required]="con.isRequired ? true : false"
                                [checked]="
                                  formData[item.key][con.configName] &&
                                  formData[item.key][con.configName].length >=
                                    1 &&
                                  formData[item.key][con.configName].includes(
                                    p.value
                                  )
                                " (click)="
                                  saveDataListCheck(
                                    con.configName,
                                    $event,
                                    item.key,
                                    formData[item.key][con.configName]
                                  )
                                " />
                              <span>{{ p.displayName }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="dropdown-input" *ngIf="con.inputType == 'list'">
                          <ng-template #tipContent>
                            {{ con.description }}
                          </ng-template>
                          <div class="dynamic_head margin-0">
                            {{ con.displayName }}
                            <span class="required-asterisk" *ngIf="con.isRequired ? true : false">*</span>
                            <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                              src="../../../assets/icons/help-about-svgrepo-com.svg" />
                          </div>
                          <table class="table dynamic-table">
                            <div>
                              <tr *ngIf="
                                  formData[item.key][con.configName].length == 0
                                ">
                                <td>
                                  <p class="dynamic-table-th-list" colspan="2">
                                    Add {{ con.displayName }}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <div class="outer-div-list" *ngFor="
                                    let v of [].constructor(
                                      formData[item.key][con.configName].length
                                    );
                                    let i = index
                                  ">
                                  <td>
                                    <input type="text" [(ngModel)]="
                                        formData[item.key][con.configName][i]
                                      " #textField="ngModel" [ngClass]="{
                                        'is-invalid':
                                          textField.dirty && textField.invalid
                                      }" name="{{ con.configName }}{{ i }}" class="form-control section1-input"
                                      [required]="true" (input)="
                                        saveDataList(
                                          con.configName,
                                          $event,
                                          item.key,
                                          i
                                        )
                                      " [value]="
                                        formData[item.key][con.configName][i]
                                          ? formData[item.key][con.configName][
                                              i
                                            ]
                                          : ''
                                      " />
                                  </td>
                                  <td>
                                    <img class="tag-remove tag-remove-ingress tag-remove-list" [ngClass]="{
                                        'disabled-click':
                                          con.isRequired == true &&
                                          this.formData[item.key][
                                            con.configName
                                          ].length <= 1
                                      }" src="../../../assets/icons/cross.svg" (click)="
                                        removeField(con.configName, item.key, i)
                                      " />
                                  </td>
                                </div>
                              </tr>
                              <tr>
                                <td (click)="
                                    addField(
                                      con.configName,
                                      item.key,
                                      formData[item.key][con.configName].length
                                    )
                                  " [ngClass]="{
                                    'nolist-add':
                                      this.formData[item.key][con.configName]
                                        .length < 1
                                  }" class="btn btn-link add-plus add-list-button-list">
                                  <i [ngClass]="{
                                      'float-right':
                                        this.formData[item.key][con.configName]
                                          .length < 1
                                    }" class="icon-color fa fa-plus-circle"></i>
                                </td>
                              </tr>
                            </div>
                          </table>
                        </div>
                        <div class="dropdown-input" *ngIf="con.inputType == 'object_expression'">
                          <ng-template #tipContent>
                            {{ con.description }}
                          </ng-template>
                          <div class="dynamic_head margin-0">
                            {{ con.displayName }}
                            <span class="required-asterisk" *ngIf="con.isRequired ? true : false">*</span>
                            <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                              src="../../../assets/icons/help-about-svgrepo-com.svg" />
                          </div>
                          <div class="dynamic_head_data">
                            <table class="table dynamic-table">
                              <tr *ngIf="
                                  !con.isRequired &&
                                  Object.keys(formData[item.key][con.configName]).length == 0
                                ">
                                <th>
                                  <p class="dynamic-table-th" colspan="2">
                                    Add {{ con.displayName }}
                                  </p>
                                </th>
                                <th></th>
                                <th>
                                  <div (click)="
                                      addObjExp(
                                        item.key,
                                        con.configName,
                                        con.children
                                      )
                                    " class="btn btn-link add-plus">
                                    <i class="icon-color fa fa-plus-circle"></i>
                                  </div>
                                </th>
                              </tr>
                              <tr *ngIf="
                                  con.isRequired ||
                                  Object.keys(formData[item.key][con.configName]).length != 0
                                ">
                                <div class="section1-input" *ngFor="let configChild of con.children">
                                  <div class="dropdown-input" *ngIf="configChild.inputType == 'dropdown'">
                                    <ng-template #tipContent>
                                      {{ configChild.description }}
                                    </ng-template>
                                    <div class="dynamic_head margin-0">
                                      {{ configChild.displayName }}
                                      <span class="required-asterisk" *ngIf="
                                          configChild.isRequired ? true : false
                                        ">*</span>
                                      <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                                        src="../../../assets/icons/help-about-svgrepo-com.svg" />
                                    </div>
                                    <select name="{{ configChild.configName }}" class="select custom-select" [ngModel]="
                                        formData[item.key][con.configName][
                                          configChild.configName
                                        ]
                                      " [required]="
                                        configChild.isRequired ? true : false
                                      " (change)="
                                        saveDataObjectExp(
                                          con.configName,
                                          $event,
                                          item.key,
                                          configChild.configName
                                        )
                                      ">
                                      <option value="" class="option" [selected]="
                                          formData[item.key][con.configName][
                                            configChild.configName
                                          ] &&
                                          formData[item.key][con.configName][
                                            configChild.configName
                                          ].length < 2
                                        ">
                                        Select {{ configChild.displayName }}
                                      </option>
                                      <option value="{{ p.value }}" class="option" [selected]="
                                          formData[item.key][con.configName][
                                            configChild.configName
                                          ] &&
                                          formData[item.key][con.configName][
                                            configChild.configName
                                          ].length > 2 &&
                                          formData[item.key][con.configName][
                                            configChild.configName
                                          ] == p.value
                                            ? true
                                            : false
                                        " *ngFor="
                                          let p of configChild.possibleValues
                                            | jsonParse
                                        ">
                                        {{ p.displayName }}
                                      </option>
                                    </select>
                                  </div>
                                  <div class="dropdown-input" *ngIf="configChild.inputType == 'text'">
                                    <ng-template #tipContent>
                                      {{ configChild.description }}
                                    </ng-template>
                                    <div class="dynamic_head margin-0">
                                      {{ configChild.displayName }}
                                      <span class="required-asterisk" *ngIf="
                                          configChild.isRequired ? true : false
                                        ">*</span>
                                      <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                                        src="../../../assets/icons/help-about-svgrepo-com.svg" />
                                    </div>
                                    <input type="text" name="{{ configChild.configName }}"
                                      class="form-control section1-input" [(ngModel)]="
                                        formData[item.key][con.configName][
                                          configChild.configName
                                        ]
                                      " #textField="ngModel" [ngClass]="{
                                        'is-invalid':
                                          textField.dirty && textField.invalid
                                      }" [required]="
                                        configChild.isRequired ? true : false
                                      " (input)="
                                        saveDataObjectExp(
                                          con.configName,
                                          $event,
                                          item.key,
                                          configChild.configName
                                        )
                                      " [value]="
                                        formData[item.key][con.configName][
                                          configChild.configName
                                        ]
                                          ? formData[item.key][
                                              con.configName
                                            ][configChild.configName]
                                          : ''
                                      " />
                                  </div>
                                  <div class="dropdown-input" *ngIf="
                                      configChild.inputType ==
                                      'list_of_checkbox'
                                    ">
                                    <ng-template #tipContent>
                                      {{ configChild.description }}
                                    </ng-template>
                                    <div class="dynamic_head margin-0">
                                      {{ configChild.displayName }}
                                      <span class="required-asterisk" *ngIf="
                                          configChild.isRequired ? true : false
                                        ">*</span>
                                      <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                                        src="../../../assets/icons/help-about-svgrepo-com.svg" />
                                    </div>
                                    <div class="checkbox-new-css">
                                      <div *ngFor="
                                          let p of configChild.possibleValues
                                            | jsonParse
                                        ">
                                        <input class="form-check-input" id="{{ configChild.configName }}"
                                          type="checkbox" [value]="p.value" name="{{ configChild.configName }}"
                                          [required]="
                                            configChild.isRequired
                                              ? true
                                              : false
                                          " [checked]="
                                            formData[item.key][
                                              con.configName
                                            ][configChild.configName] &&
                                            formData[item.key][
                                              con.configName
                                            ][configChild.configName]
                                              .length >= 1 &&
                                            formData[item.key][
                                              con.configName
                                            ][
                                              configChild.configName
                                            ].includes(p.value)
                                          " (click)="
                                            saveDataObjListCheck(
                                              con.configName,
                                              configChild.configName,
                                              $event,
                                              item.key,
                                              formData[item.key][
                                                con.configName
                                              ][configChild.configName]
                                            )
                                          " />
                                        <span>{{ p.displayName }}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="dropdown-input" *ngIf="configChild.inputType == 'list'">
                                    <ng-template #tipContent>
                                      {{ configChild.description }}
                                    </ng-template>
                                    <div class="dynamic_head margin-0">
                                      {{ configChild.displayName }}
                                      <span class="required-asterisk" *ngIf="
                                          configChild.isRequired ? true : false
                                        ">*</span>
                                      <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                                        src="../../../assets/icons/help-about-svgrepo-com.svg" />
                                    </div>
                                    <div>
                                      <div *ngIf="
                                          !formData[item.key][
                                            con.configName
                                          ][configChild.configName]
                                        " (click)="
                                          addObjChildFieldEmpty(
                                            con.configName,
                                            item.key,
                                            configChild.configName
                                          )
                                        " class="btn btn-link add-plus">
                                        <i class="icon-color fa fa-plus-circle"></i>
                                      </div>
                                      <div *ngIf="
                                          formData[item.key][con.configName][
                                            configChild.configName
                                          ]
                                        " (click)="
                                          addObjChildField(
                                            con.configName,
                                            item.key,
                                            configChild.configName,
                                            formData[item.key][
                                              con.configName
                                            ][configChild.configName].length
                                          )
                                        " class="btn btn-link add-plus">
                                        <i class="icon-color fa fa-plus-circle"></i>
                                      </div>
                                      <div *ngIf="
                                          formData[item.key][con.configName][
                                            configChild.configName
                                          ]
                                        ">
                                        <div class="section1-input" *ngFor="
                                            let v of [].constructor(
                                              formData[item.key][
                                                con.configName
                                              ][configChild.configName]
                                                .length
                                            );
                                            let i = index
                                          ">
                                          <input type="text" [(ngModel)]="
                                              formData[item.key][
                                                con.configName
                                              ][configChild.configName][i]
                                            " #textField="ngModel" [ngClass]="{
                                              'is-invalid':
                                                textField.dirty &&
                                                textField.invalid
                                            }" name="{{ configChild.configName }}{{
                                              i
                                            }}" class="form-control" [required]="true" (input)="
                                              saveDataObjExpList(
                                                con.configName,
                                                $event,
                                                item.key,
                                                configChild.configName,
                                                i
                                              )
                                            " [value]="
                                              formData[item.key][
                                                con.configName
                                              ][configChild.configName][i]
                                                ? formData[item.key][
                                                    con.configName
                                                  ][configChild.configName][
                                                    i
                                                  ]
                                                : ''
                                            " />
                                          <div>
                                            <img class="tag-remove tag-remove-ingress" [ngClass]="{
                                                'disabled-click':
                                                  configChild.isRequired ==
                                                    true &&
                                                  this.formData[item.key][
                                                    con.configName
                                                  ][configChild.configName]
                                                    .length <= 1
                                              }" src="../../../assets/icons/cross.svg" (click)="
                                                removeObjChildField(
                                                  con.configName,
                                                  item.key,
                                                  configChild.configName,
                                                  i
                                                )
                                              " />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <td *ngIf="!con.isRequired">
                                  <img class="tag-remove tag-remove-ingress" src="../../../assets/icons/cross.svg"
                                    (click)="
                                      removeObjExpField(
                                        con.configName,
                                        item.key,
                                        i
                                      )
                                    " />
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>

                        <div class="dropdown-input" *ngIf="con.inputType == 'object'">
                          <ng-template #tipContent>
                            {{ con.description }}
                          </ng-template>
                          <div class="dynamic_head margin-0">
                            {{ con.displayName }}
                            <span class="required-asterisk" *ngIf="con.isRequired ? true : false">*</span>
                            <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                              src="../../../assets/icons/help-about-svgrepo-com.svg" />
                          </div>
                          <div class="dynamic-form">
                            <form [formGroup]="objData[item.key][con.configName]">
                              <table class="table dynamic-table" formArrayName="kvtags">
                                <tr *ngIf="
                                    kvtags(item.key, con.configName).length == 0
                                  ">
                                  <th>
                                    <p class="dynamic-table-th" colspan="2">
                                      Add {{ con.displayName }}
                                    </p>
                                  </th>
                                  <th></th>
                                  <th>
                                    <div (click)="addTAG(item.key, con.configName)" class="btn btn-link add-plus">
                                      <i class="icon-color fa fa-plus-circle"></i>
                                    </div>
                                  </th>
                                </tr>
                                <tr class="dynamic-tr" *ngFor="
                                    let kv of kvtags(item.key, con.configName)
                                      .controls;
                                    let i = index
                                  " [formGroupName]="i">
                                  <td>
                                    <input type="text" (input)="tagFieldCheck()" formControlName="keytag"
                                      class="form-control" value="kv.keytag" />
                                  </td>
                                  <td>
                                    <input type="text" (input)="tagFieldCheck()" formControlName="valuetag"
                                      value="kv.valuetag" class="form-control" />
                                  </td>
                                  <td>
                                    <img [ngClass]="{
                                        'disabled-click':
                                          con.isRequired == true &&
                                          kvtags(item.key, con.configName)
                                            .length == 1
                                      }" class="tag-remove tag-remove-div" src="../../../assets/icons/cross.svg"
                                      (click)="
                                        removeTAG(i, item.key, con.configName)
                                      " />
                                  </td>
                                </tr>
                                <tr class="dynamic-tr">
                                  <td></td>
                                  <td></td>
                                  <div *ngIf="
                                      kvtags(item.key, con.configName).length !=
                                      0
                                    " (click)="addTAG(item.key, con.configName)"
                                    class="btn btn-link add-plus bottom-add">
                                    <i class="icon-color fa fa-plus-circle"></i>
                                  </div>
                                </tr>
                              </table>
                            </form>
                          </div>
                        </div>

                        <div class="dropdown-input" *ngIf="con.inputType == 'list_object_expression'">
                          <ng-template #tipContent>
                            {{ con.description }}
                          </ng-template>
                          <div class="dynamic_head margin-0">
                            {{ con.displayName }}
                            <span class="required-asterisk" *ngIf="con.isRequired ? true : false">*</span>
                            <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                              src="../../../assets/icons/help-about-svgrepo-com.svg" />
                          </div>
                          <div class="dynamic_head_data">
                            <table class="table dynamic-table">
                              <tr *ngIf="
                                  formData[item.key][con.configName].length == 0
                                ">
                                <th>
                                  <p class="dynamic-table-th" colspan="2">
                                    Add {{ con.displayName }}
                                  </p>
                                </th>
                                <th></th>
                                <th>
                                  <div (click)="
                                      addListExp(
                                        item.key,
                                        con.configName,
                                        con.children
                                      )
                                    " class="btn btn-link add-plus">
                                    <i class="icon-color fa fa-plus-circle"></i>
                                  </div>
                                </th>
                              </tr>
                              <tr *ngFor="
                                  let v of [].constructor(
                                    formData[item.key][con.configName].length
                                  );
                                  let i = index
                                " class="dynamic-tr">
                                <td class="dynamic-td" colspan="2">
                                  <div class="dynamic_fields" *ngFor="let field of con.children">
                                    <div div class="dropdown-input" *ngIf="field.inputType == 'text'">
                                      <ng-template #tipContent>
                                        {{ field.description }}
                                      </ng-template>
                                      <p class="list_obj_dynamic_head margin-0">
                                        {{ field.displayName }}
                                        <span class="required-asterisk" *ngIf="
                                            field.isRequired ? true : false
                                          ">*</span>
                                        <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                                          src="../../../assets/icons/help-about-svgrepo-com.svg" />
                                      </p>

                                      <input type="text" name="{{ field.configName }}{{ i }}"
                                        class="form-control list_obj_dynamic_inputs" [(ngModel)]="
                                          formData[item.key][con.configName][i][
                                            field.configName
                                          ]
                                        " #textField="ngModel" [ngClass]="{
                                          'is-invalid':
                                            textField.dirty && textField.invalid
                                        }" [required]="
                                          field.isRequired ? true : false
                                        " (input)="
                                          saveDataListExp(
                                            con.configName,
                                            $event,
                                            item.key,
                                            i,
                                            field.configName
                                          )
                                        " [value]="
                                          formData[item.key][con.configName][i][
                                            field.configName
                                          ]
                                            ? formData[item.key][
                                                con.configName
                                              ][i][field.configName]
                                            : ''
                                        " />
                                    </div>

                                    <div class="dropdown-input" *ngIf="field.inputType == 'dropdown'">
                                      <ng-template #tipContent>
                                        {{ field.description }}
                                      </ng-template>
                                      <div class="list_obj_dynamic_head margin-0">
                                        {{ field.displayName }}
                                        <span class="required-asterisk" *ngIf="
                                            field.isRequired ? true : false
                                          ">*</span>
                                        <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                                          src="../../../assets/icons/help-about-svgrepo-com.svg" />
                                      </div>
                                      <select name="{{ field.configName }}{{ i }}"
                                        class="list_obj_dynamic_inputs select custom-select" [required]="
                                          field.isRequired ? true : false
                                        " [ngModel]="
                                          formData[item.key][con.configName][i][
                                            field.configName
                                          ]
                                        " (change)="
                                          saveDataChildren(
                                            con.configName,
                                            $event,
                                            item.key,
                                            i,
                                            field.configName
                                          )
                                        ">
                                        >
                                        <option value="" class="option" [selected]="
                                            formData[item.key][con.configName][
                                              i
                                            ][field.configName] &&
                                            formData[item.key][con.configName][
                                              i
                                            ][field.configName].length < 2
                                          ">
                                          Select {{ field.displayName }}
                                        </option>
                                        <option value="{{ p.value }}" class="option" [selected]="
                                            formData[item.key][con.configName][
                                              i
                                            ][field.configName] &&
                                            formData[item.key][con.configName][
                                              i
                                            ][field.configName].length > 2 &&
                                            formData[item.key][con.configName][
                                              i
                                            ][field.configName] == p.value
                                              ? true
                                              : false
                                          " *ngFor="
                                            let p of field.possibleValues
                                              | jsonParse
                                          ">
                                          {{ p.displayName }}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="dropdown-input" *ngIf="field.inputType == 'list'">
                                      <ng-template #tipContent>
                                        {{ field.description }}
                                      </ng-template>
                                      <div class="list_obj_dynamic_head margin-0">
                                        {{ field.displayName }}
                                        <span class="required-asterisk" *ngIf="
                                            field.isRequired ? true : false
                                          ">*</span>
                                        <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                                          src="../../../assets/icons/help-about-svgrepo-com.svg" />
                                      </div>
                                      <div>
                                        <div *ngIf="
                                            !formData[item.key][con.configName][
                                              i
                                            ][field.configName]
                                          " (click)="
                                            addListObjChildFieldEmpty(
                                              con.configName,
                                              item.key,
                                              i,
                                              field.configName
                                            )
                                          " class="btn btn-link add-plus">
                                          <i class="icon-color fa fa-plus-circle"></i>
                                        </div>
                                        <div *ngIf="
                                            formData[item.key][con.configName][
                                              i
                                            ][field.configName]
                                          " (click)="
                                            addListChildren(
                                              item.key,
                                              con.configName,
                                              i,
                                              field.configName,
                                              formData[item.key][
                                                con.configName
                                              ][i][field.configName].length
                                            )
                                          " class="btn btn-link add-plus">
                                          <i class="icon-color fa fa-plus-circle"></i>
                                        </div>
                                        <div *ngIf="
                                            formData[item.key][con.configName][
                                              i
                                            ][field.configName]
                                          ">
                                          <div class="list_obj_dynamic_inputs section1-input" *ngFor="
                                              let v of [].constructor(
                                                formData[item.key][
                                                  con.configName
                                                ][i][field.configName].length
                                              );
                                              let j = index
                                            ">
                                            <input type="text" class="section1-input" [(ngModel)]="
                                                formData[item.key][
                                                  con.configName
                                                ][i][field.configName][j]
                                              " #textField="ngModel" [ngClass]="{
                                                'is-invalid':
                                                  textField.dirty &&
                                                  textField.invalid
                                              }" name="{{ field.configName }}{{
                                                i
                                              }}{{ j }}" class="form-control" [required]="true" (input)="
                                                saveDataListChildren(
                                                  con.configName,
                                                  $event,
                                                  item.key,
                                                  i,
                                                  j,
                                                  field.configName
                                                )
                                              " [value]="
                                                formData[item.key][
                                                  con.configName
                                                ][i][field.configName][j]
                                                  ? formData[item.key][
                                                      con.configName
                                                    ][i][field.configName][j]
                                                  : ''
                                              " />
                                            <div>
                                              <img class="tag-remove tag-remove-ingress" [ngClass]="{
                                                  'disabled-click':
                                                    field.isRequired == true &&
                                                    this.formData[item.key][
                                                      con.configName
                                                    ][i][field.configName]
                                                      .length <= 1
                                                }" src="../../../assets/icons/cross.svg" (click)="
                                                  removeListChildren(
                                                    item.key,
                                                    con.configName,
                                                    i,
                                                    field.configName,
                                                    j
                                                  )
                                                " />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="dropdown-input" *ngIf="
                                        field.inputType == 'list_of_checkbox'
                                      ">
                                      <ng-template #tipContent>
                                        {{ field.description }}
                                      </ng-template>
                                      <div class="dynamic_head margin-0">
                                        {{ field.displayName }}
                                        <span class="required-asterisk" *ngIf="
                                            field.isRequired ? true : false
                                          ">*</span>
                                        <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                                          src="../../../assets/icons/help-about-svgrepo-com.svg" />
                                      </div>
                                      <div class="checkbox-new-css">
                                        <div *ngFor="
                                            let p of field.possibleValues
                                              | jsonParse
                                          ">
                                          <input class="form-check-input" id="{{ field.configName }}" type="checkbox"
                                            [value]="p.value" name="{{ field.configName }}" [required]="
                                              field.isRequired ? true : false
                                            " [checked]="
                                              formData[item.key][
                                                con.configName
                                              ][i][field.configName] &&
                                              formData[item.key][
                                                con.configName
                                              ][i][field.configName].length >=
                                                1 &&
                                              formData[item.key][
                                                con.configName
                                              ][i][field.configName].includes(
                                                p.value
                                              )
                                            " (click)="
                                              saveDataListObjectListCheckbox(
                                                con.configName,
                                                field.configName,
                                                i,
                                                $event,
                                                item.key
                                              )
                                            " />
                                          <span>{{ p.displayName }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <img class="tag-remove tag-remove-ingress" [ngClass]="{
                                      'disabled-click':
                                        con.isRequired == true &&
                                        this.formData[item.key][con.configName]
                                          .length <= 1
                                    }" src="../../../assets/icons/cross.svg" (click)="
                                      removeListExp(i, item.key, con.configName)
                                    " />
                                </td>
                              </tr>
                              <tr class="dynamic-tr" *ngIf="
                                  formData[item.key][con.configName].length != 0
                                ">
                                <td></td>
                                <td></td>
                                <td>
                                  <div (click)="
                                      addListExp(
                                        item.key,
                                        con.configName,
                                        con.children
                                      )
                                    " class="btn btn-link add-plus ingress-add">
                                    <i class="icon-color fa fa-plus-circle"></i>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <p class="configuration" *ngIf="
                          config[item.key] &&
                          config[item.key].length &&
                          config[item.key].length > 0
                        ">
                        Secrets
                      </p>
                      <div *ngFor="let con of config[item.key]">
                        <div class="dropdown-input" *ngIf="con.inputType == 'password'">
                          <ng-template #tipContent>
                            {{ con.description }}
                          </ng-template>
                          <div class="dynamic_head margin-0">
                            {{ con.displayName }}
                            <span class="required-asterisk" *ngIf="con.isRequired ? true : false">*</span>
                            <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                              src="../../../assets/icons/help-about-svgrepo-com.svg" />
                          </div>
                          <input type="password" name="{{ con.configName }}" class="form-control section1-input"
                            [(ngModel)]="Secret[item.key][con.configName]" #passwordField="ngModel" [ngClass]="{
                              'is-invalid': passwordField.invalid
                            }" [required]="con.isRequired ? true : false"
                            (input)="saveData(con.configName, $event, item.key)" [value]="
                              Secret[item.key][con.configName]
                                ? Secret[item.key][con.configName]
                                : ''
                            " />
                        </div>
                        <div class="dropdown-input" *ngIf="con.inputType == 'password_secret'">
                          <ng-template #tipContent>
                            {{ con.description }}
                          </ng-template>
                          <div class="dynamic_head margin-0">
                            {{ con.displayName }}
                            <span class="required-asterisk" *ngIf="con.isRequired ? true : false">*</span>
                            <img class="help pointer" [ngbTooltip]="tipContent" placement="top"
                              src="../../../assets/icons/help-about-svgrepo-com.svg" />
                          </div>
                          <input type="password" name="{{ con.configName }}" class="form-control section1-input" ngModel
                            #passwordSecretField="ngModel" [ngClass]="{
                              'is-invalid':
                                passwordSecretField.dirty &&
                                passwordSecretField.invalid
                            }" [required]="con.isRequired ? true : false" (input)="
                              saveDataSecret(con.configName, $event, item.key)
                            " />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button [disabled]="
                    configLoading ||
                    configForm.form.invalid ||
                    tagRequired ||
                    checkboxRequired
                  " type="submit" (click)="next()" class="btn btn-primary mt-5">
                  {{ "common.button.next" | translate
                  }}<span *ngIf="apisubmit" class="spinner-grow spinner-grow-sm" role="status"
                    aria-hidden="true"></span>
                </button>
                <!-- <button type="submit" (click)="prev()" class="btn btn-primary mt-5">
                {{ "common.button.previous" | translate }}
              </button>  -->
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</form>