<div class="sb-nav-fixed">
  <app-header [showExpandedView]="showExpandedView"></app-header>

  <div id="layoutSidenav">
    <app-side-nav-container
      (sendCollapsedStatus)="checkCollapseStatus($event)"
    ></app-side-nav-container>
    <div
      id="layoutSidenav_content"
      [ngClass]="{
        'shrinked-view': !showExpandedView,
        'expanded-view': showExpandedView
      }"
    >
      <main>
        <div class="container-fluid">
          <div class="row project-button">
            <div class="mr-auto p-2">
              <h2 class="font30">{{ "common.create_project" | translate }}</h2>
            </div>
          </div>

          <div id="stepper1" class="bs-stepper">
            <app-stepper></app-stepper>
            <!-- <div *ngIf="apiLoading" class="d-flex justify-content-center">
                          <div class="loader-create" role="status">
                             <img src="../../assets/icons/new-loader.svg" alt="Loading..."/>
                          </div>
                      </div> -->
            <div class="bs-stepper-content">
              <div id="test-l-2">
                <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
                  <p>
                    <label for="name" style="font-size: 18px">{{
                      "common.project_name" | translate
                    }}</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      formControlName="name"
                      class="form-control"
                      (input)="checkLenght($event.target.value)"
                      style="width: 93%"
                    />
                  </p>

                  <div formArrayName="env_array">
                    <div>
                      <p class="add_env">{{ "common.add_env" | translate }}</p>
                    </div>
                    <div class="env_labels">
                      <div style="width: 50%">
                        {{ "common.env" | translate }}
                      </div>
                      <div>{{ "common.code_branch" | translate }}</div>
                    </div>
                    <div
                      *ngFor="let env of env_array().controls; let i = index"
                      [formGroupName]="i"
                      class="env-div"
                    >
                      <div>
                        <input
                          type="text"
                          formControlName="env"
                          class="form-control field"
                          (input)="updateValue(i)"
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          formControlName="source_branch"
                          class="form-control field"
                          (input)="updateValue(i)"
                        />
                      </div>
                      <div>
                        <img
                          [ngClass]="{
                            'disabled-click': this.env_array().value.length <= 1
                          }"
                          class="env-remove"
                          src="../../../assets/icons/cross.svg"
                          (click)="removeEnv(i)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="nav-buttons">
                    <button
                      type="submit"
                      (click)="prev()"
                      class="btn btn-primary mr-4 mt-4"
                    >
                      {{ "common.button.previous" | translate }}
                    </button>
                    <button
                      type="submit"
                      [disabled]="submitDisabled || envDisabled"
                      class="btn btn-primary info-mobile-button default-button mt-4 float"
                    >
                      {{ "common.button.next" | translate
                      }}<span
                        *ngIf="apiLoading"
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
