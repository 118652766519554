import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { NotifierToastService } from 'src/app/shared-services/notifier-toast.service';
import { environment } from 'src/environments/environment';
import { ProjectService } from '../../project-services/project.service';
import { analyticsConst } from '../../../shared-services/analytics-constants';
import { AnalyticsService } from '../../../shared-services/analytics.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent implements OnInit {
  @Input() pro: any;
  @Output() updated = new EventEmitter<boolean>();
  @Output() activeId = new EventEmitter<string>();
  editId: string;
  name: string;
  apiLoading: boolean;
  updating: boolean;
  project: any;
  componentMap = [];
  configData: any = {};
  env: any;
  saveEnabled: boolean = true;
  projectName: string;
  templateName: string;
  deleting: boolean;
  activeAccordion: number = -1;

  constructor(
    private notifierToast: NotifierToastService,
    private httpClient: HttpClient,
    private router: Router,
    private proService: ProjectService,
    public cookieService: CookieService,
    private analytics: AnalyticsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.editConfig('general', '');
    localStorage.removeItem('activeAccord');
    this.editId = localStorage.getItem('editId');
    this.name = localStorage.getItem('editName');
    this.apiLoading = true;
    this.project = this.pro;
    this.componentMap = JSON.parse(this.pro.componentMap);
    this.projectName = this.project.name;
    this.pro.allProjectComponents.forEach((element) => {
      this.configData[element.componentId] = {};
      this.configData[element.componentId]['repo_name'] = element.repoName;
      this.configData[element.componentId]['usage'] = element.displayName;
      for (const key in element.configDetails) {
        if (Object.prototype.hasOwnProperty.call(element.configDetails, key)) {
          this.configData[element.componentId][key] =
            element.configDetails[key];
        }
      }
    });
    this.apiLoading = false;
  }

  toggleAccordion(index: number): void {
    this.activeAccordion = this.activeAccordion === index ? null : index;
  }
  
  deleteproject() {
    this.deleting = true;
    // this.deletingId = id;
    this.httpClient
      .delete(environment.DELETE_PROJECT + '/' + this.editId)
      .subscribe(
        (data) => {
          this.notifierToast.showNotification(
            'success',
            'Project Deleted Successfully'
          );
          let param = {};
          param[analyticsConst.PARAMS.TIME] = new Date();
          param[analyticsConst.PARAMS.DELETE_PROJECT_ID] = this.editId;
          this.analytics.logEvent(
            analyticsConst.EVENTS.ADMIN_DELETE_PROJECT,
            param
          );
          this.deleting = false;
          this.router.navigate(['/projects/dashboard']);
        },
        (err: Error) => {
          this.notifierToast.showNotification('error', err.message);
          let param = {};
          param[analyticsConst.PARAMS.TIME] = new Date();
          param[analyticsConst.PARAMS.DELETE_PROJECT_ID] = this.editId;
          this.analytics.logEvent(
            analyticsConst.EVENTS.ADMIN_DELETE_PROJECT_FAIL,
            param
          );
          this.deleting = false;
        }
      );
  }

  async openeditor(): Promise<void> {
    await this.cookieService.remove('projectId', {
      domain: environment.COOKIE_DOMAIN,
    });
    await this.cookieService.remove('loggedInUser', {
      domain: environment.COOKIE_DOMAIN,
    });
    localStorage.setItem('editorVisited', 'true');
    const url = environment.EDITOR_URL;
    let param = {};
    param[analyticsConst.PARAMS.TIME] = new Date();
    param[analyticsConst.PARAMS.PROJECT_ID] = this.editId;
    this.analytics.logEvent(analyticsConst.EVENTS.ADMIN_EDIT_ON_CANVAS, param);
    this.cookieService.put('projectId', this.editId, {
      domain: environment.COOKIE_DOMAIN,
    });
    window.open(url, '_self');
  }

  async updateProjectName() {
    this.updating = true;
    await this.proService
      .updateProjectName(this.projectName)
      .subscribe((data) => {
        this.updated.emit(true);
        this.updating = false;
      });
  }

  onChange(updatedValue: string): void {
    this.saveEnabled = false;
    this.projectName = updatedValue;
  }

  editConfig(id: string, accordId: string) {
    this.activeId.emit(id);
    localStorage.setItem('activeAccord', accordId);
  }
}
