import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  CognitoCallback,
  CognitoUtil,
  LoggedInCallback,
} from '../../auth/services/cognito.service';
import { Observable, of, from } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../user/user.service';
import { Router } from '@angular/router';
import { AwsSigV4Service } from './aws-sign-v4.service';
import { NotifierToastService } from '../notifier-toast.service';
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  inflightAuthRequest = null;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private awsSigV4Service: AwsSigV4Service,
    private router: Router,
    private cognitoUtil: CognitoUtil,
    private notifierToast: NotifierToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf(environment.API_HOST) > -1) {
      if (!this.inflightAuthRequest) {
        this.inflightAuthRequest = this.authService.getAWSTokens();
      }

      return this.inflightAuthRequest.pipe(
        switchMap((_) => {
          // unset request inflight
          this.inflightAuthRequest = null;

          // use the newly returned token
          let awsReq = this.awsSigV4Service.signRequest(
            request,
            this.authService.AWSCredentials
          );

          let authReq = request.clone({
            setHeaders: awsReq.headers,
          });

          return next.handle(authReq).pipe(
            tap(
              (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
              },
              (err: any) => {
                console.log('err : ', err);
                if (err instanceof HttpErrorResponse) {
                  // if (err.status === 403) {
                  if (
                    err.status === 0 &&
                    err.statusText == 'Unknown Error' &&
                    err.url.indexOf(environment.API_HOST) > -1
                  ) {
                    // logout user
                    // this.userService.logout();
                    // // check if current page is not login page
                    // if (this.router.url.indexOf('/login') === -1) {
                    //   // redirect to login screen
                    //   this.router.navigate(['/auth/login']);
                    // }
                    window.location.reload();
                  }
                  if (
                    (err.status === 401 || err.status === 403) &&
                    err.url.indexOf(environment.API_HOST) > -1
                  ) {
                    // logout user
                    // this.userService.logout();
                    // check if current page is not login page
                    // if (this.router.url.indexOf('/login') === -1) {
                    //   // redirect to login screen
                    //   this.router.navigate(['/auth/login']);
                    // }
                    this.notifierToast.showNotification(
                      'error',
                      'Session expired, Please Try Again!!'
                    );
                    // window.location.reload()
                    from(this.cognitoUtil.genrateNewToken());
                  } else {
                    // if unauthorized access
                    // logout user
                    window.location.reload();
                    //this.authService.logout();
                    // this.userService.logout();
                    // // check if current page is not login page
                    // if (this.router.url.indexOf('/login') === -1) {
                    //   // redirect to login screen
                    //   this.router.navigate(['/auth/login']);
                    // }
                  }
                }
              }
            )
          );
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
