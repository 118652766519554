import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { environment } from '../../../environments/environment';
import { UserService } from '../user/user.service';
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public AWSCredentials: AWS.CognitoIdentityCredentials;

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageService
  ) {}

  Init(idToken?: string): Promise<any> {
    return this.setCognitoCredentials(idToken);
  }
  /**
   * This function will be hit on application load & when user logs in.
   * It will fetch AWS Credentials and store them into cookie store.
   * @param userRole
   * @param idToken
   * @param session
   */
  setCognitoCredentials(idToken?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        AWS.config.region = environment.region;
        // Initiate for logged in users
        if (idToken) {
          this.getLoggedInAWSCrdentials(idToken).subscribe(
            (creds) => resolve(creds),
            (err) => reject(err)
          );
        } else {
          //Check for stored credentials
          let existingLocalCreds = this.getExistingAWSCredsFromStorage();
          if (!existingLocalCreds) {
            // Initiate for guest users
            this.getGuestAWSCrdentials().subscribe(
              (creds) => resolve(creds),
              (err) => reject(err)
            );
          } else {
            //When stored AWS Credentials are expired
            if (!this.isValidAWSCredentials(existingLocalCreds)) {
              //tokens got expired so logout and get guest scoped credentials
              this.userService.logout();
              // Initiate for guest users
              this.getGuestAWSCrdentials().subscribe(
                (creds) => resolve(creds),
                (err) => reject(err)
              );
            } else {
              //Local credentials are valid so use them
              this.AWSCredentials = existingLocalCreds;
              AWS.config.credentials = this.AWSCredentials;

              resolve(this.AWSCredentials);
            }
          }
        }
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }

  /**
   * This function will insure AWS Credentials availability before any http call passes from interceptor
   */
  getAWSTokens(): Observable<any> {
    if (!this.isValidAWSCredentials()) {
      //Logout it loggedIn user
      this.userService.logout();
      return this.getGuestAWSCrdentials();
    } else {
      return of(true).pipe(share());
    }
  }

  /**
   * This function will return default Cognito Identity parmas
   */
  getCognitoIdentityParams(): any {
    return {
      IdentityPoolId: environment.identityPoolId,
      Logins: {},
      RoleSessionName: 'web',
      RoleArn: environment.unAuthRoleARN,
      DurationSeconds: 43200,
    };
  }

  /**
   * This function will fetch stored AWS Credentials in cookie store, prepare Object from it and return them.
   */
  getExistingAWSCredsFromStorage(): any {
    const awsCreds = this.localStorageService.getItem('asc-creds');
    if (awsCreds) {
      let credentials: any = atob(awsCreds);
      try {
        credentials = JSON.parse(decodeURIComponent(credentials));
      } catch (error) {
        //Invalid Credentials
        this.userService.logout();
        return;
      }

      let params = this.getCognitoIdentityParams();

      if (credentials.ra) {
        if (credentials.ra === 'A') {
          params.RoleArn = environment.authRoleARN;
        }
      }

      const creds = new AWS.CognitoIdentityCredentials(params) as any;
      creds.expireTime = credentials.et;

      if (new Date(credentials.et).getTime() > Date.now()) {
        creds.expired = false;
      } else {
        creds.expired = true;
      }
      creds.accessKeyId = credentials.aki;
      creds.secretAccessKey = credentials.sak;
      creds.sessionToken = credentials.st;

      return creds;
    }
    return undefined;
  }

  /**
   * This function will store the AWS Credentials into the cookie store and set refresh token
   * @param roleARN
   * @param userRole
   * @param session
   */
  setAWSCredentialsToStorage(
    roleARN?: string,
    userRole?: string,
    session?: any
  ) {
    let creds = this.AWSCredentials as AWS.Credentials;
    let role = 'UA';
    if (roleARN === environment.authRoleARN) {
      role = 'A';
    }

    const awsCreds = JSON.stringify({
      aki: creds.accessKeyId,
      sak: creds.secretAccessKey,
      st: creds.sessionToken,
      et: creds.expireTime,
      ra: role,
    });

    //Encode credentials into base64
    let base64AWSCreds = btoa(awsCreds);

    //Store AWS Credentials into cookie store
    this.localStorageService.setItem('asc-creds', base64AWSCreds);
  }

  private getAscCredsCookieExpiry() {
    let expiry = new Date(Date.now() + environment.TWELVE_HOURS_MILLI);
    return expiry;
  }

  /**
   * This function will check if AWS Credentials are valid
   * @param storedCredentials
   */
  isValidAWSCredentials(storedCredentials?: AWS.Credentials): boolean {
    if (storedCredentials || this.AWSCredentials) {
      const expiryDate = new Date(
        (
          (storedCredentials
            ? storedCredentials
            : this.AWSCredentials) as AWS.Credentials
        ).expireTime
      );
      if (expiryDate.getTime() > Date.now()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * This function will fetch guest scoped AWS Credentials and store it to cookie store
   */
  getGuestAWSCrdentials(): Observable<any> {
    return new Observable<any>((observe) => {
      const cognitoParams = this.getCognitoIdentityParams();
      //Get temporary Guest Credentials
      this.AWSCredentials = new AWS.CognitoIdentityCredentials(cognitoParams);
      (this.AWSCredentials as AWS.CognitoIdentityCredentials).get((err) => {
        if (!err) {
          this.setAWSCredentialsToStorage(cognitoParams.RoleArn);
          if (this.userService.isUserLoggedIn()) {
            this.userService.logout();
          }
          AWS.config.credentials = this.AWSCredentials;
          observe.next(this.AWSCredentials);
        } else {
          observe.error(err);
        }
      });
    }).pipe(share());
  }

  /**
   * This function will fetch the logged user scoped AWS Credentials and store it to local storage
   * @param idToken
   */
  getLoggedInAWSCrdentials(idToken: string): Observable<any> {
    return new Observable<any>((observe) => {
      // Configure the credentials provider to use your identity pool
      const cognitoParams = this.getCognitoIdentityParams();
      cognitoParams.Logins[environment.getIdPoolLogins()] = idToken;
      cognitoParams.RoleArn = environment.authRoleARN;

      this.AWSCredentials = new AWS.CognitoIdentityCredentials(cognitoParams);

      // Make the call to obtain credentials
      (this.AWSCredentials as AWS.Credentials).get(() => {
        AWS.config.credentials = this.AWSCredentials;

        this.setAWSCredentialsToStorage(environment.authRoleARN);
        observe.next(AWS.config.credentials);
        observe.complete();
      });
    });
  }

  /**
   * This function will set credentials object into AWS Credentials object
   * @param credentials
   * @param roleArn
   * @param userRole
   * @param idToken
   */
  setIntoAWSCredentials(
    credentials: any,
    roleArn: string,
    userRole?: string,
    idToken?: string
  ) {
    const cognitoParams = this.getCognitoIdentityParams();
    if (userRole && idToken) {
      cognitoParams.Logins[environment.getIdPoolLogins(userRole)] = idToken;
    }
    cognitoParams.RoleArn = roleArn;

    let creds = new AWS.CognitoIdentityCredentials(cognitoParams);
    creds.identityId = credentials.identityId;
    creds.expireTime = credentials.expireTime;
    creds.expired = credentials.expired;
    creds.accessKeyId = credentials.accessKeyId;
    creds.secretAccessKey = credentials.secretAccessKey;
    creds.sessionToken = credentials.sessionToken;

    this.AWSCredentials = creds;
    AWS.config.credentials = this.AWSCredentials;
  }
}
