<div class="sb-nav-fixed">
  <app-header [showExpandedView]="showExpandedView"></app-header>

  <div id="layoutSidenav">
    <!-- <app-side-header></app-side-header> -->
    <app-side-nav-container
      (sendCollapsedStatus)="checkCollapseStatus($event)"
    ></app-side-nav-container>
    <div
      id="layoutSidenav_content"
      [ngClass]="{
        'shrinked-view': !showExpandedView,
        'expanded-view': showExpandedView
      }"
    >
      <main>
        <div class="container-fluid" *ngIf="!showEditFlow">
          <div *ngIf="!apiLoaded" class="row project-all">
            <div
              class="card button-list card-block shadow"
              *ngFor="let item of [1, 2, 3]"
            ></div>
          </div>

          <div *ngIf="projectEmpty" class="">
            <div class="blank-state blank-state-lg">
              <img
                class="noProjectImage"
                src="../../../assets/images/no-project.svg"
              />
              <p class="bold-text">
                {{ "common.message.no_project" | translate }}
              </p>
            </div>
          </div>

          <div *ngIf="projectPresent" class="row project-all">
            <div class="card card-block shadow" *ngFor="let item of allApps">
              <ng-template #tipContent>{{ item.name }}</ng-template>
              <div
                class="projectHeight"
                [ngbTooltip]="tipContent"
                placement="top"
              >
                <h3 class="bottomMargin item-card-title card-title">
                  <b>{{ item.name | slice : 0 : 30 }}</b
                  ><b *ngIf="item.name.length > 30">...</b>
                </h3>
                <span *ngIf="item.templateLabel" class="project_description">{{
                  item.templateLabel
                }}</span>
                <span class="project_description" *ngIf="!item.templateLabel"
                  >Created From Scratch</span
                >
              </div>
              <div class="align-row">
                <div *ngFor="let p of item.platform?.split(',')">
                  <div
                    *ngIf="platformObj.hasOwnProperty(p.trim().toUpperCase())"
                  >
                    <img
                      src="{{ platformObj[p.trim().toUpperCase()] }}"
                      class="cloud-provider"
                    />
                  </div>
                </div>
              </div>

              <div class="card-footer bg-transparent">
                <ng-template #icon>{{
                  "common.footer_card.download_zip" | translate
                }}</ng-template>
                <ng-template #zipIcon>{{
                  "common.footer_card.create_zip" | translate
                }}</ng-template>
                <ng-template #delete>{{
                  "common.footer_card.delete_project" | translate
                }}</ng-template>
                <ng-template #edit> Project Details </ng-template>
                <div>
                  <div class="edited-time">
                    <p *ngIf="compare(item.createdAt, item.updatedAt)">
                      {{ "common.footer_card.created_on" | translate }}
                      <br />{{ item.createdAt | date : "hh:mm a" }}
                      {{ item.createdAt | date : " MMM dd, yyyy" }}
                    </p>
                    <p *ngIf="!compare(item.createdAt, item.updatedAt)">
                      {{ "common.footer_card.updated_on" | translate }}
                      <br />{{ item.updatedAt | date : "hh:mm a" }}
                      {{ item.updatedAt | date : " MMM dd, yyyy" }}
                    </p>
                  </div>
                  <div class="d-flex project-box-bottom">
                    <!-- <img
                      class="icon pointer"
                      [ngbTooltip]="delete"
                      src="../../../assets/icons/delete.svg"
                      *ngIf="!deleting || deletingId != item.id"
                      (click)="deleteproject(item.id)"
                    /> -->
                    <div
                      *ngIf="deleting && deletingId == item.id"
                      class="icon spinner-border loader-create"
                      role="status"
                    ></div>
                    <div *ngFor="let project of projects">
                      <div
                        class="d-flex project-box-bottom"
                        *ngIf="project?.id == item?.id"
                      >
                        <div *ngIf="project.downloadJob">
                          <div
                            *ngIf="
                              project.downloadJob.jobStatus === 'Completed'
                            "
                          >
                            <a href="{{ project.source }}">
                              <img
                                class="icon pointer"
                                src="../../../assets/icons/download.svg"
                                [ngbTooltip]="icon"
                                placement="bottom-right"
                              />
                            </a>
                          </div>
                          <div
                            *ngIf="
                              project.downloadJob.jobStatus == 'InProgress'
                            "
                            class="circle"
                          >
                            <circle-progress
                              [percent]="project.downloadJob.jobProgress"
                              [radius]="10"
                              [outerStrokeWidth]="2"
                              [outerStrokeColor]="'#144083'"
                              [showSubtitle]="false"
                              [unitsFontSize]="10"
                              [unitsColor]="'#144083'"
                              [showInnerStroke]="false"
                              [titleFontSize]="10"
                              [titleColor]="'#144083'"
                              [maxPercent]="100"
                              [animation]="true"
                              [animationDuration]="300"
                            >
                            </circle-progress>
                          </div>

                          <div
                            class="text-danger small-font float-right"
                            *ngIf="project.downloadJob.jobStatus == 'Failed'"
                          >
                            <img
                              class="icon pointer"
                              src="../../../assets/icons/warning.svg"
                              [ngbTooltip]="warning"
                              placement="bottom-right"
                              (click)="
                                showErrorNotification(
                                  project.downloadJob.failedReason
                                )
                              "
                            />
                            <ng-template #warning>{{
                              project.downloadJob.failedReason
                            }}</ng-template>
                          </div>
                        </div>
                        <div
                          *ngIf="project.isDownloadStarting"
                          class="icon spinner-border loader-create"
                          role="status"
                        ></div>
                        <div *ngIf="!project.downloadJob">
                          <img
                            class="icon pointer"
                            src="../../../assets/icons/zip-file-download.svg"
                            *ngIf="!project.isDownloadStarting"
                            [ngbTooltip]="zipIcon"
                            placement="bottom-right"
                            (click)="intiateDownloadJob(project, project.id)"
                          />
                        </div>
                      </div>
                    </div>
                    <img
                      class="icon pointer"
                      src="../../../assets/icons/info.svg"
                      (click)="editProject(item.id, item.name)"
                      [ngbTooltip]="edit"
                      placement="bottom-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
