import { Component, Input, OnInit } from '@angular/core';
import { ICollapseStatus } from 'src/app/portal/side-nav-container/side-nav-container.utils';
import { ProjectService } from '../../project-services/project.service';

@Component({
  selector: 'app-env-tabs',
  templateUrl: './env-tabs.component.html',
  styleUrls: ['./env-tabs.component.scss'],
})
export class EnvTabsComponent implements OnInit {
  showExpandedView: boolean;
  apiLoading: boolean;
  project: any;
  activeString: string;
  updated: boolean;
  allEnv = [];
  env = [];
  constructor(private proService: ProjectService) {}

  async ngOnInit(): Promise<void> {
    await this.initialize();
  }

  valid(currentstate: boolean) {
    this.updated = currentstate;
    if (this.updated) {
      this.initialize();
    }
  }

  async initialize() {
    this.apiLoading = true;
    this.allEnv = [];
    this.env = [];
    await this.proService
      .fetchPro(localStorage.getItem('editId'))
      .subscribe((data: any) => {
        this.project = data.project;
        console.log(this.project);
        this.env = JSON.parse(this.project.allProjectComponents[0].env);
        this.env.forEach((element) => {
          this.allEnv.push(element.env);
        });
        this.activeString = this.allEnv[0];
        console.log(this.activeString);
        this.apiLoading = false;
      });
  }
  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }

  onTabChange(event) {
    console.log('changed');
    localStorage.removeItem('formData');
  }
}
