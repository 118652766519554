import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  firebaseConfig = {
    apiKey: environment.apiKey,
    authDomain: environment.authDomain,
    projectId: environment.projectId,
    storageBucket: environment.storageBucket,
    messagingSenderId: environment.messagingSenderId,
    appId: environment.appId,
    measurementId: environment.measurementId,
  };
  app = initializeApp(this.firebaseConfig);
  analytics = getAnalytics(this.app);
  constructor() {}

  logEvent(event: string, params: any) {
    logEvent(this.analytics, event, params);
  }
}
