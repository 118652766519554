import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Stepper from 'bs-stepper';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  private stepper: Stepper;
  constructor(private router: Router) {}
  apiloading;
  active: string;
  id: string;
  ngOnInit(): void {
    this.active = this.router.url.split('/').pop();
    this.id = localStorage.getItem('proid');
    // const parentElement = document.getElementById('stepper1');
    // if (parentElement && parentElement != null) {
    //   this.stepper = new Stepper(document.querySelector('#stepper1'), {
    //     linear: false,
    //     animation: true,
    //   });
    // }
  }
  onSubmit() {
    return false;
  }
}
