import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() platform?: string;
  @Input() searchText?: string;
  @Input() deleting?: string;
  @Input() deletingId?: string;
  @Input() receivedArray: any[] = [];
  @Input() type: string;
  @Output() tempID = new EventEmitter();

  platformObj: any = {
    AWS: '../../assets/images/aws.png',
    AZURE: '../../assets/images/azure.png',
    GCP: '../../assets/images/gcp.png',
    OCI: '../../assets/images/oci.png'
  };
  platformArray = {};

  constructor(public cookieService: CookieService) {}

  ngOnInit(): void {}

  getTemplateId(templateId: string): void {
    this.tempID.emit(templateId);
  }

  deletetemplate(templateId: string): void {
    this.tempID.emit(templateId);
  }

  compare(createdAt: any, updatedAt: any) {
    const x = new Date(createdAt);
    const y = new Date(updatedAt);
    if (
      x.getHours() == y.getHours() &&
      x.getDate() == y.getDate() &&
      x.getMonth() == y.getMonth() &&
      x.getMinutes() == y.getMinutes() &&
      x.getSeconds() == y.getSeconds()
    ) {
      return true;
    } else {
      return false;
    }
  }
}
