import { Component, OnInit } from '@angular/core';
import { ICollapseStatus } from '../portal/side-nav-container/side-nav-container.utils';

@Component({
  selector: 'app-wiki',
  templateUrl: './wiki.component.html',
  styleUrls: ['./wiki.component.scss'],
})
export class WikiComponent implements OnInit {
  showExpandedView = false;
  constructor() {}

  ngOnInit(): void {}

  viewDocument(url: string) {
    window.open(url, '_blank');
  }

  checkCollapseStatus(status: ICollapseStatus): void {
    if (status.isCollapsed) {
      this.showExpandedView = true;
    } else {
      this.showExpandedView = false;
    }
  }
}
