<div class="sb-nav-fixed">
  <app-header [showExpandedView]="showExpandedView"></app-header>

  <div id="layoutSidenav">
    <app-side-nav-container
      (sendCollapsedStatus)="checkCollapseStatus($event)"
    ></app-side-nav-container>

    <div
      id="layoutSidenav_content"
      [ngClass]="{
        'shrinked-view': !showExpandedView,
        'expanded-view': showExpandedView
      }"
    >
      <main>
        <div class="container-fluid">
          <div class="row project-button">
            <div class="mr-auto p-2">
              <h2>User Guide</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 button-list">
              <button
                type="button"
                (click)="
                  viewDocument(
                    'https://impetusinfotech-my.sharepoint.com/:v:/g/personal/tabbu_sehgal_impetus_com/EdAfd8Vvt9pFh-sH4xYGrgQBNBpILGalJZRswaDaSfnoHQ?e=wytKA5'
                  )
                "
                class="btn btn-default project-buttons-text btn-lg btn-lg-list shadow p-3 mb-5 bg-white rounded"
              >
                <b>Register & Login User</b>
              </button>
            </div>
            <div class="col-md-3 button-list">
              <button
                type="button"
                (click)="
                  viewDocument(
                    'https://impetusinfotech-my.sharepoint.com/:v:/g/personal/mrunal_vartak_impetus_com/Ebsd2hP6OJxLotGrwUMvwWMB5c6ZfvA7_l1gkPu9DdX39w'
                  )
                "
                class="btn btn-default project-buttons-text btn-lg btn-lg-list shadow p-3 mb-5 bg-white rounded"
              >
                <b>KloudJet Portal Overview</b>
              </button>
            </div>
            <div class="col-md-3 button-list">
              <button
                type="button"
                (click)="
                  viewDocument(
                    'https://impetusinfotech-my.sharepoint.com/:v:/g/personal/riddhi_goyal_impetus_com/EdS1fHNtNS1IiYCrQhEqcoMBHhoXEzesIlZG9rw2gcm7EA?e=eQs5YF'
                  )
                "
                class="btn btn-default project-buttons-text btn-lg btn-lg-list shadow p-3 mb-5 bg-white rounded"
              >
                <b>Create & Download Project</b>
              </button>
            </div>
            <div class="col-md-3 button-list">
              <button
                type="button"
                (click)="
                  viewDocument(
                    'https://impetusinfotech-my.sharepoint.com/:v:/g/personal/mrunal_vartak_impetus_com/ET984BnyTzxAnA0ujwejf4YBv-lXVQMQ1DHDNZ-xEF4glw'
                  )
                "
                class="btn btn-default project-buttons-text btn-lg btn-lg-list shadow p-3 mb-5 bg-white rounded"
              >
                <b>Edit Project</b>
              </button>
            </div>
            <!-- <div class="col-md-3 button-list">
              <button
                type="button"
                (click)="
                  viewDocument(
                    'https://impetusinfotech-my.sharepoint.com/:v:/g/personal/riddhi_goyal_impetus_com/EbsnrOTEsNxNoAp3eHT46tEBcRAeH3Isv-kkTsYg4Sq-0w'
                  )
                "
                class="btn btn-default project-buttons-text btn-lg btn-lg-list shadow p-3 mb-5 bg-white rounded"
              >
                <b>Create Template</b>
              </button>
            </div> -->
            <div class="col-md-3 button-list">
              <button
                type="button"
                (click)="
                  viewDocument(
                    'https://ee-ci-template-archive.s3.amazonaws.com/FAQ\'s+KloudJet.pdf'
                  )
                "
                class="btn btn-default project-buttons-text btn-lg btn-lg-list shadow p-3 mb-5 bg-white rounded"
              >
                <b>Frequently Asked Questions</b>
              </button>
            </div>
          </div>
          <hr />
          <div class="row custom-margin">
            <h2>Contact Us</h2>
            <p>
              If you have any questions, reach out to us on
              <a href="mailto:Kloudjet@impetus.com">kloudjet&#64;impetus.com</a>
            </p>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
