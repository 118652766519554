import { Injectable } from '@angular/core';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectDataService {
  selectedTemplate: any;
  projectEnv: any;
  project: any;
  constructor(private proService: ProjectService) {}

  saveSelectedTemp(tData) {
    this.selectedTemplate = tData;
  }

  returnSelectedTemp() {
    return this.selectedTemplate;
  }
  saveProjectEnv(tData) {
    this.projectEnv = tData;
  }

  returnProjectEnv() {
    return this.projectEnv;
  }
  saveProject(pro) {
    this.project = pro;
    localStorage.setItem(
      'projectComponent',
      JSON.stringify(pro.projectComponents)
    );
  }
  returnProject() {
    return this.project;
  }
}
