import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CognitoCallback, CognitoUtil } from './cognito.service';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import { AuthService } from '../../shared-services/auth/auth.service';
import { UserService } from '../../shared-services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserRegistrationService {
  private isUserVerified: boolean = false;

  constructor(
    @Inject(CognitoUtil) public cognitoUtil: CognitoUtil,
    private userService: UserService
  ) {}

  register(user) {
    return new Observable((observer) => {
      let attributeList = [];

      let dataEmail = { Name: 'email', Value: user.email };
      let dataFullName = { Name: 'name', Value: user.name };
      attributeList.push(new CognitoUserAttribute(dataEmail));
      attributeList.push(new CognitoUserAttribute(dataFullName));

      this.cognitoUtil
        .getUserPool()
        .signUp(
          user.email,
          user.password,
          attributeList,
          null,
          (err, result) => {
            if (err) {
              observer.error(err);
            } else {
              observer.next(result);
            }
          }
        );
    });
  }

  confirmRegistration(
    username: string,
    confirmationCode: string
  ): Observable<any> {
    return new Observable((observer) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.cognitoUtil.getUserPool(),
      });

      cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
        if (err) {
          observer.error(err.message);
        } else {
          observer.next(result);
          observer.complete();
        }
      });
    });
  }

  isUserConfirmed(): boolean {
    return this.isUserVerified;
  }

  resendCode(username: string, callback: any): void {
    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool(),
    };

    let cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        callback(err.message, null);
      } else {
        callback(null, result);
      }
    });
  }

  newPassword(newPasswordUser: any, callback: CognitoCallback): void {
    // console.log(newPasswordUser);
    // Get these details and call
    //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
    let authenticationData = {
      Username: newPasswordUser.username,
      Password: newPasswordUser.existingPassword,
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);

    let userData = {
      Username: newPasswordUser.username,
      Pool: this.cognitoUtil.getUserPool(),
    };

    // console.log('UserLoginService: Params set...Authenticating the user');
    let cognitoUser = new CognitoUser(userData);
    // console.log('UserLoginService: config is ' + AWS.config);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        // the api doesn't accept this field back
        delete userAttributes.email_verified;
        cognitoUser.completeNewPasswordChallenge(
          newPasswordUser.password,
          requiredAttributes,
          {
            onSuccess: function (result) {
              callback.cognitoCallback(null, userAttributes);
            },
            onFailure: function (err) {
              callback.cognitoCallback(err, null);
            },
          }
        );
      },
      onSuccess: function (result) {
        callback.cognitoCallback(null, result);
      },
      onFailure: function (err) {
        callback.cognitoCallback(err, null);
      },
    });
  }
}
