import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TemplateInfoService } from '../../../../shared-services/template-info.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
})
export class TemplateListComponent implements OnInit {
  @Output() childEvent = new EventEmitter<{ comp: object; svalue: boolean }>();

  tempID = {};
  isClicked = false;
  Categories: any;
  temp: any = {};
  click: boolean = false;
  title = 'bind-dropdownlist';
  ddlCategory: string;

  constructor(
    private http: HttpClient,
    private templateInfoService: TemplateInfoService
  ) {
    this.GetTemplateList();
  }

  ngOnInit(): void {}

  OnSelect(value: string) {
    let key = value;

    let component = this.Categories.find((x) => x.id === value);
    let cValue = {
      ingress: [],
      outgress: [],
      name: component.label,
    };

    // console.log(cValue);

    this.childEvent.emit(component);
  }

  onSubmit(value) {
    // console.log('submitted successfully');
    this.click = !this.click;
    let key = value;
    let component = this.Categories.find((x) => x.id === value);
    let cValue = {
      ingress: [],
      outgress: [],
      name: component.label,
    };
    // console.log(cValue);

    this.childEvent.emit({ comp: component, svalue: this.click });
  }

  GetTemplateList() {
    this.http.get(environment.TEMPLATE_LIST).subscribe((data) => {
      this.Categories = data['body'];
      // console.log('Categories data:' + data);
    });
  }
}
