import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private httpClient: HttpClient) {}

  public getAllApps(clientId) {
    return this.httpClient.get(
      environment.DASHBOARD_ALL_GET.replace('$username', clientId)
    );
  }
}
